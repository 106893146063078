export const IMPACT_LIGHT = 'impactLight';
export const IMPACT_MEDIUM = 'impactMedium';
export const IMPACT_HEAVY = 'impactHeavy';

export function vibrateLight() {
}

export function vibrateMedium() {
}

export function vibrateHeavy() {
}

export function vibrate() {
}
