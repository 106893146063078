import * as TYPES from './types';

const initialState = {
  visibleCustomAppIconModal: false,
  visibleRateUsModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RATING_SET_VISIBLE:
      return {
        ...state,
        visibleRateUsModal: action.payload,
      };

    case TYPES.CUSTOM_APP_ICON_SET_VISIBLE:
      return {
        ...state,
        visibleCustomAppIconModal: action.payload,
      };
    default:
      return state;
  }
}
