import React, { PureComponent } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import * as COLORS from '../constants/colors';
import * as ICONS from '../constants/icons';

import Button from './button';
import Text from './text';

const gradientStart = { x: 0, y: 0 };
const gradientEnd = { x: 0, y: 1 };

export default class ErrorModal extends PureComponent {

  static propTypes = {
    onPress: PropTypes.func,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonTitle: PropTypes.string,
  };

  render() {
    const { isLoading, onPress, title, subtitle, buttonTitle, } = this.props;

    return (
      <View style={styles.root}>
        <View style={styles.shadow}>
          <LinearGradient
            start={gradientStart}
            end={gradientEnd}
            colors={COLORS.GRADIENT_BG_MAIN_SCREEN}
            style={styles.modal}>
            <View style={styles.infoWrapper}>
              <Image source={ICONS.ICON_NO_CONNECTION} />
              <Text style={styles.title} fontWeight={'semibold'}>{title}</Text>
              <Text style={styles.subtitle}>{subtitle}</Text>
            </View>
            {buttonTitle ?
              <View style={styles.buttonWrapper}>
                <Button isSpinner={isLoading} onPress={onPress} title={buttonTitle} />
              </View>
              : null}
          </LinearGradient>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.GRAY_DARK_TRANSPARENT,
    paddingVertical: 80,
    paddingHorizontal: 20,
  },
  shadow: {
    borderRadius: 10,
    shadowColor: COLORS.BLACK,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  modal: {
    flexDirection: 'column',
    backgroundColor: COLORS.BLACK_OPACITY,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  infoWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: COLORS.WHITE,
    letterSpacing: -1,
    fontSize: 30,
    paddingTop: 30,
    paddingBottom: 10,
  },
  subtitle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: COLORS.WHITE,
    letterSpacing: -1,
    fontSize: 20,
  },
  buttonWrapper: {
    width: '100%',
  },
});
