import * as TYPES from './types';

const initialState = {
  inited: false,
  supported: false,
  changed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_SUPPORTED_STATUS:
      return {
        ...state,
        inited: true,
        supported: action.payload,
      };

    case TYPES.CHANGED_ICON:
      return {
        ...state,
        changed: true,
      };
    default:
      return state;
  }
}
