export const BG_ONBOARDING_CHAT = require('../../assets/images/bg-picture-2.jpg');
export const BG_ONBOARDING_NAME = require('../../assets/images/bg-picture-1.jpg');
export const BG_ONBOARDING_GENDER = require('../../assets/images/bg-picture-2.jpg');
export const BG_ONBOARDING_RELATIONSHIP = require('../../assets/images/bg-picture-3.jpg');
export const BG_ONBOARDING_DATE = require('../../assets/images/bg-picture-4.jpg');
export const BG_ONBOARDING_TIME = require('../../assets/images/bg-picture-5.jpg');
export const HEADER_ONBOARDING_CHAT = require('../../assets/images/header.png');
export const BG_ONBOARDING_SUBSCRIPTION_FIRST = require('../../assets/images/bg-picture-subscription.jpg');
export const BG_ONBOARDING_SUBSCRIPTION_SECOND = require('../../assets/images/bg-onboarding-subscription-second.jpg');
export const BG_PALM = require('../../assets/images/hand-left2x.png');
export const ONBOARDING_SUBSCRIPTION_STAR = require('../../assets/images/onboarding-subscription-star.png');
export const BG_TAKE_A_PHOTO = require('../../assets/images/bg-take-a-photo.jpg');
export const TAKE_A_PHOTO_HAND = require('../../assets/images/take-a-photo-hand.png');
export const BG_ONBOARDING_SUBSCRIPTION_EXTRA = require('../../assets/images/bg-offer.jpg');

export const TAROT_BG = require('../../assets/images/torot-screen/bg-tarot.jpg');
export const TAROT_ARROW = require('../../assets/images/torot-screen/arrow.png');
export const TAROT_BIG_CARD = require('../../assets/images/torot-screen/card-big.png');

export const BG_HEADER_MAIN = require('../../assets/images/head-pic.png');
export const PICTURE_BIORITHM = require('../../assets/images/picture-biorithm.png');

export const CARD_TAROT_BIG = require('../../assets/images/main-cards/card-tarot-big.png');
export const CARD_NUMBEROLOGY = require('../../assets/images/main-cards/card-compatibility2.png');
export const CARD_COMPATIBILITY = require('../../assets/images/main-cards/card-compatibility.png');
export const CARD_MAGIC_BALL = require('../../assets/images/main-cards/card-magic-ball.png');
export const CARD_NUMBER_OF_DAY = require('../../assets/images/main-cards/card-number-of-day.png');
export const CARD_TAROS = require('../../assets/images/main-cards/tarot.png');
export const CARD_ZODIAC = require('../../assets/images/main-cards/card-zodiac.png');
export const CARD_HOROSCOPES = require('../../assets/images/main-cards/card-horoscopes.png');
export const CARD_BIORITHM = require('../../assets/images/main-cards/card-biorhythm.png');

export const BIG_HEADER = require('../../assets/images/header-big.jpg');
export const PERIOD_STAR = require('../../assets/images/star.png');

export const CARD_ZODIAC_CAREER_INDIAN = require('../../assets/images/indian-solar-cards/card-bg-career-indian.png');
export const CARD_ZODIAC_HEALTH_INDIAN = require('../../assets/images/indian-solar-cards/card-bg-health-indian.png');
export const CARD_ZODIAC_LOVE_INDIAN = require('../../assets/images/indian-solar-cards/card-bg-love-indian.png');
export const CARD_ZODIAC_YOUR_DAY_INDIAN = require('../../assets/images/indian-solar-cards/card-your-day-indian.png');

export const CARD_ZODIAC_CAREER = require('../../assets/images/zodiac-cards/card-bg-career.png');
export const CARD_ZODIAC_HEALTH = require('../../assets/images/zodiac-cards/card-bg-health.png');
export const CARD_ZODIAC_LOVE = require('../../assets/images/zodiac-cards/card-bg-love.png');
export const CARD_ZODIAC_YOUR_DAY = require('../../assets/images/zodiac-cards/card-your-day.png');

export const CARD_DEFAULT = require('../../assets/images/horoscope-bg.png');

export const CHART_GRADIENT = require('../../assets/images/gradient.png');
export const CHART_GRADIENT_BALL = require('../../assets/images/gradient-ball.png');

export const TAROT_ARROW_FOR_FIRST_VERSION = require('../../assets/images/torot-screen/arrow-copy.png');
export const TAROT_CARD = require('../../assets/images/torot-screen/horoscope-bg-2.png');

export const TAKE_A_PHOTO_LEFT = require('../../assets/images/hand/take-a-photo-left.png');
export const TAKE_A_PHOTO_RIGHT = require('../../assets/images/hand/take-a-photo-right.png');

export const FALL_GRADIENT = require('../../assets/images/hand/gradient-for-scan.png');

export const CARD_BLUR_OVERVIEW = require('../../assets/images/hand/cards/blur/overview-bg-free.png');
export const CARD_BLUR_LOVE = require('../../assets/images/hand/cards/blur/love-blure.png');
export const CARD_BLUR_HEALTH = require('../../assets/images/hand/cards/blur/health-blure.png');
export const CARD_BLUR_WEALTH = require('../../assets/images/hand/cards/blur/wealth-blure.png');

export const CARD_PALM_OVERVIEW = require('../../assets/images/hand/cards/horoscope-bg-1.png');
export const CARD_PALM_LIFE = require('../../assets/images/hand/cards/horoscope-bg-2.png');
export const CARD_PALM_HEAD = require('../../assets/images/hand/cards/horoscope-bg-3.png');
export const CARD_PALM_HEART = require('../../assets/images/hand/cards/horoscope-bg-4.png');

export const FACE = require('../../assets/images/face/face.png');
export const CARD_FACE_OVERVIEW_BLUR = require('../../assets/images/face/overview-face-bg-free.png');

export const CARD_FACE_OVERVIEW = require('../../assets/images/hand/cards/horoscope-bg-1.png');
export const CARD_FACE_FOREHEAD = require('../../assets/images/hand/cards/horoscope-bg-2.png');
export const CARD_FACE_EYEBROWS = require('../../assets/images/hand/cards/horoscope-bg-3.png');
export const CARD_FACE_EYES = require('../../assets/images/hand/cards/horoscope-bg-4.png');
export const CARD_FACE_NOSE = require('../../assets/images/hand/cards/horoscope-bg-5.png');
export const CARD_FACE_MOUTH = require('../../assets/images/hand/cards/horoscope-bg-1.png');
export const CARD_FACE_JAW = require('../../assets/images/hand/cards/horoscope-bg-2.png');
export const BG_COMPATIBILITY_HEADER = require('../../assets/images/main-pic.png');
export const SETTINGS_SUBSCRIPTIONS_BG = require('../../assets/images/bg-subscription.jpg');

export const GENDER_MALE_UNSELECT = require('../../assets/images/gender/male-unselect.png');
export const GENDER_FEMALE_UNSELECT = require('../../assets/images/gender/female-unselect.png');
export const GENDER_MALE_DISABLED = require('../../assets/images/gender/male-disabled.png');
export const GENDER_FEMALE_DISABLED = require('../../assets/images/gender/female-disabled.png');
export const GENDER_MALE_ACTIVE = require('../../assets/images/gender/male-active.png');
export const GENDER_FEMALE_ACTIVE = require('../../assets/images/gender/female-active.png');

export const COMPATIBILITY_CARD_BG_OVERVIEW = require('../../assets/images/card-bg-overview.png');
export const COMPATIBILITY_CARD_BG_LOVE = require('../../assets/images/card-bg-love.png');
export const COMPATIBILITY_CARD_BG_MARRIAGE = require('../../assets/images/card-bg-marriage.png');
export const COMPATIBILITY_CARD_BG_CAREER = require('../../assets/images/card-bg-career.png');

export const SPLASH_BG = require('../../assets/images/splash-bg.jpg');
export const PROGRESS_MODAL_BG = require('../../assets/images/progress-modal-bg.png');

export const SUBSCRIPTION_BG = require('../../assets/images/bg.jpg');
export const LOADING_BG = require('../../assets/images/bg-picture-loading.jpg');

export const FACE_GREEN = require('../../assets/images/subscription/face.png');
export const FACE_CLASSIC = require('../../assets/images/subscription/face-pink.png');
export const HAND_GREEN = require('../../assets/images/subscription/hand.png');
export const HAND_CLASSIC = require('../../assets/images/subscription/hand-pink.png');
export const GENDER_GREEN = require('../../assets/images/subscription/icon-gender.png');
export const GENDER_CLASSIC = require('../../assets/images/subscription/icon-gender-pink.png');
export const TAROT_GREEN = require('../../assets/images/subscription/icon-tarot.png');
export const TAROT_CLASSIC = require('../../assets/images/subscription/icon-tarot-pink.png');

export const LANDING_HEADER = require('../../assets/images/subscription/main-illustration.png');
export const ILLUSTRATION_LANDING = require('../../assets/images/subscription/illustration.png');

export const BG_ADVANTAGES = require('../../assets/images/bg-advantages.jpg');
export const MORE_THEN_10_CARD = require('../../assets/images/advantages-cards/1.png');
export const ADVANCED_PALM_CARD = require('../../assets/images/advantages-cards/2.png');
export const PERSONAL_COMPATIBILITY_CARD = require('../../assets/images/advantages-cards/3.png');
export const ADS_FREE_CARD = require('../../assets/images/advantages-cards/4.png');
