import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as COLORS from '../constants/colors';

import Analytics from '../modules/analytics';
import i18n from '../localization';
import ErrorModal from '../components/error-modal';

class NotAvailableModal extends PureComponent {

  static propTypes = {
    visible: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.visible) {
      Analytics.trackEvent('app', 'not_available');
    }
  }

  render() {
    return this.props.visible ? (
      <View style={styles.root}>
        <ErrorModal
          title={i18n.t('ERROR_MODAL.NOT_AVAILABLE_TITLE')}
          subtitle={i18n.t('ERROR_MODAL.NOT_AVAILABLE_TEXT')}
        />
      </View>
    ) : null;
  }
}

export default connect(
  state => ({
    visible: state.remoteConfig.remoteConfigParams.appIsNotAvailable,
  }),
)(NotAvailableModal);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.BLURPLE,
  },
});
