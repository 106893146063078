export const GURU_ICONS = require('../../assets/icons/icon-guru.png');
export const CLOSE_ICONS = require('../../assets/icons/icon-cancel-sub.png');
export const CLOSE_ICON = require('../../assets/icons/icon-cancel-sub-bold.png');
export const CLOSE_ICON_WHITE = require('../../assets/icons/icon-cancel-white.png');
export const SEND_ICONS = require('../../assets/icons/icon-send.png');

export const FIRE = require('../../assets/icons/icon-fire.png');

export const AD_GREEN = require('../../assets/icons/icon-ad-green.png');
export const AD_CLASSIC = require('../../assets/icons/icon-ad-pink.png');

export const CHANGE_HAND = require('../../assets/icons/icon-change-hand.png');
export const BACK_ICONS = require('../../assets/icons/icon-back.png');

export const CAMERA_SHOT = require('../../assets/icons/camera-shot.png');

export const ICON_QUESTION = require('../../assets/icons/icon-question.png');
export const ICON_BIORYTHM_NO_DATA = require('../../assets/icons/icon-biorythm-circle.png');
export const ICON_NUMEROLOGY = require('../../assets/icons/icon-numerology.png');
export const ICON_GENDER = require('../../assets/icons/icon-gender.png');
export const ICON_MAGIC_BALL = require('../../assets/icons/icon-magic-ball.png');
export const ICON_PHYSIOGNOMY = require('../../assets/icons/icon-physiognomy.png');

export const MENU_ICON = require('../../assets/icons/icon-menu.png');
export const NEXT_ICON = require('../../assets/icons/icon-next.png');
export const BACK_ICON = require('../../assets/icons/icon-back.png');

export const ICON_PALMESTRY = require('../../assets/icons/icon-palmistry.png');
export const ICON_TAROT = require('../../assets/icons/icon-tarot.png');

export const ICON_PALM_LOVE = require('../../assets/icons/palm-reading/icon-love-palmreading.png');
export const ICON_PALM_HEALTH = require('../../assets/icons/palm-reading/icon-health-palmreading.png');
export const ICON_PALM_WEALTH = require('../../assets/icons/palm-reading/icon-wealth-palmreading.png');

export const ICON_HEARTLINE_LEFT = require('../../assets/icons/hand/icon-heartline-left.png');
export const ICON_LIFELINE_LEFT = require('../../assets/icons/hand/icon-lifeline-left.png');
export const ICON_HEADLINE_LEFT = require('../../assets/icons/hand/icon-headline-left.png');

export const ICON_HEARTLINE_RIGHT = require('../../assets/icons/hand/icon-heartline-right.png');
export const ICON_LIFELINE_RIGHT = require('../../assets/icons/hand/icon-lifeline-right.png');
export const ICON_HEADLINE_RIGHT = require('../../assets/icons/hand/icon-headline-right.png');

export const ICON_MAYAN_HOROSCOPES = require('../../assets/icons/icon-mayan.png');
export const ICON_INDIAN_SUN_HOROSCOPES = require('../../assets/icons/icon-indian-solar.png');
export const ICON_INDIAN_MOON_HOROSCOPES = require('../../assets/icons/icon-indian-lunar.png');
export const ICON_CHINESE_HOROSCOPES = require('../../assets/icons/icon-chinese.png');
export const ICON_DRUID_HOROSCOPES = require('../../assets/icons/icon-druid.png');

export const ICON_SLIDER_BACK = require('../../assets/icons/icon-back-copy.png');
export const ICON_SLIDER_NEXT = require('../../assets/icons/icon-next-copy.png');

export const ICON_DEFAULT_SIGN_INDIAN_MOON = require('../../assets/icons/default-horoscopes-icons/icon-indian-lunar.png');
export const ICON_DEFAULT_SIGN_INDIAN_SUN = require('../../assets/icons/default-horoscopes-icons/icon-indian-solar.png');
export const ICON_DEFAULT_SIGN_DRUID = require('../../assets/icons/default-horoscopes-icons/icon-druid.png');
export const ICON_DEFAULT_SIGN_MAYAN = require('../../assets/icons/default-horoscopes-icons/icon-mayan.png');

export const ICON_ARROW_UP = require('../../assets/icons/arrows/arrow-up-large.png');
export const ICON_ARROW_DOWN = require('../../assets/icons/arrows/arrow-down-large.png');

export const ICON_AQUARIUS = require('../../assets/icons/horoscopes-signs/icon-aquarius.png');
export const ICON_ARIES = require('../../assets/icons/horoscopes-signs/icon-aries.png');
export const ICON_CANCER = require('../../assets/icons/horoscopes-signs/icon-cancer.png');
export const ICON_CAPRICORN = require('../../assets/icons/horoscopes-signs/icon-capricorn.png');
export const ICON_GEMINI = require('../../assets/icons/horoscopes-signs/icon-gemini.png');
export const ICON_LEO = require('../../assets/icons/horoscopes-signs/icon-leo.png');
export const ICON_LIBRA = require('../../assets/icons/horoscopes-signs/icon-libra.png');
export const ICON_PISCES = require('../../assets/icons/horoscopes-signs/icon-pisces.png');
export const ICON_SAGITTARIUS = require('../../assets/icons/horoscopes-signs/icon-sagittarius.png');
export const ICON_SCORPIO = require('../../assets/icons/horoscopes-signs/icon-scorpio.png');
export const ICON_TAURUS = require('../../assets/icons/horoscopes-signs/icon-taurus.png');
export const ICON_VIRGO = require('../../assets/icons/horoscopes-signs/icon-virgo.png');

export const ICON_DOG = require('../../assets/icons/chinese-sings/icon-dog.png');
export const ICON_DRAGON = require('../../assets/icons/chinese-sings/icon-dragon.png');
export const ICON_GOAT = require('../../assets/icons/chinese-sings/icon-goat.png');
export const ICON_HORSE = require('../../assets/icons/chinese-sings/icon-horse.png');
export const ICON_MONKEY = require('../../assets/icons/chinese-sings/icon-monkey.png');
export const ICON_OX = require('../../assets/icons/chinese-sings/icon-ox.png');
export const ICON_PIG = require('../../assets/icons/chinese-sings/icon-pig.png');
export const ICON_RABBIT = require('../../assets/icons/chinese-sings/icon-rabbit.png');
export const ICON_RAT = require('../../assets/icons/chinese-sings/icon-rat.png');
export const ICON_ROOSTER = require('../../assets/icons/chinese-sings/icon-rooster.png');
export const ICON_SNAKE = require('../../assets/icons/chinese-sings/icon-snake.png');
export const ICON_TIGER = require('../../assets/icons/chinese-sings/icon-tiger.png');

export const ICON_AQUARIUS_FEATURES = require('../../assets/icons/personal-sub/aquarius-small.png');
export const ICON_ARIES_FEATURES = require('../../assets/icons/personal-sub/aries-small.png');
export const ICON_CANCER_FEATURES = require('../../assets/icons/personal-sub/cancer-small.png');
export const ICON_CAPRICORN_FEATURES = require('../../assets/icons/personal-sub/capricorn-small.png');
export const ICON_GEMINI_FEATURES = require('../../assets/icons/personal-sub/gemini-small.png');
export const ICON_LEO_FEATURES = require('../../assets/icons/personal-sub/leo-small.png');
export const ICON_LIBRA_FEATURES = require('../../assets/icons/personal-sub/libra-small.png');
export const ICON_PISCES_FEATURES = require('../../assets/icons/personal-sub/pisces-small.png');
export const ICON_SAGITTARIUS_FEATURES = require('../../assets/icons/personal-sub/sagittarius-small.png');
export const ICON_SCORPIO_FEATURES = require('../../assets/icons/personal-sub/scorpio-small.png');
export const ICON_TAURUS_FEATURES = require('../../assets/icons/personal-sub/taurus-small.png');
export const ICON_VIRGO_FEATURES = require('../../assets/icons/personal-sub/virgo-small.png');

export const ICON_DOG_FEATURES = require('../../assets/icons/personal-sub/dog.png');
export const ICON_DRAGON_FEATURES = require('../../assets/icons/personal-sub/dragon.png');
export const ICON_GOAT_FEATURES = require('../../assets/icons/personal-sub/goat.png');
export const ICON_HORSE_FEATURES = require('../../assets/icons/personal-sub/horse.png');
export const ICON_MONKEY_FEATURES = require('../../assets/icons/personal-sub/monkey.png');
export const ICON_OX_FEATURES = require('../../assets/icons/personal-sub/ox.png');
export const ICON_PIG_FEATURES = require('../../assets/icons/personal-sub/pig.png');
export const ICON_RABBIT_FEATURES = require('../../assets/icons/personal-sub/rabbit.png');
export const ICON_RAT_FEATURES = require('../../assets/icons/personal-sub/rat.png');
export const ICON_ROOSTER_FEATURES = require('../../assets/icons/personal-sub/rooster.png');
export const ICON_SNAKE_FEATURES = require('../../assets/icons/personal-sub/snake.png');
export const ICON_TIGER_FEATURES = require('../../assets/icons/personal-sub/tiger.png');

export const ICON_LUCKY_FEATURES = require('../../assets/icons/personal-sub/lucky.png');

export const ICON_NO_CONNECTION = require('../../assets/icons/icon-no-connect.png');

export const ICON_RECOMMENDATION = require('../../assets/icons/icon-recommendation.png');
export const ICON_WARNING = require('../../assets/icons/icon-warning.png');

export const ICON_FIND = require('../../assets/icons/icon-find.png');
export const ICON_SWIPER_HAND_RIGHT = require('../../assets/icons/hand/icon-right-hand.png');
export const ICON_SWIPER_HAND_RIGHT_ACTIVE = require('../../assets/icons/hand/icon-right-hand-active.png');
export const ICON_SWIPER_HAND_LEFT = require('../../assets/icons/hand/icon-left-hand.png');
export const ICON_SWIPER_HAND_LEFT_ACTIVE = require('../../assets/icons/hand/icon-left-hand-active.png');

export const ICON_FACE_EYES = require('../../assets/icons/face/icon-eyes.png');
export const ICON_FACE_EYEBROWS = require('../../assets/icons/face/icon-eyebrows.png');
export const ICON_FACE_FOREHEAD = require('../../assets/icons/face/icon-forehead.png');
export const ICON_FACE_NOSE = require('../../assets/icons/face/icon-nose.png');
export const ICON_FACE_MOUTH = require('../../assets/icons/face/icon-mouth.png');
export const ICON_FACE_JAW = require('../../assets/icons/face/icon-jaw.png');
export const HOROSCOPE_ADVANTAGE = require('../../assets/icons/icon-horoscope-advantage.png');
export const PALM_ADVANTAGE = require('../../assets/icons/icon-palm-reading-advantage.png');
export const PHYSIOGNOMY_ADVANTAGE = require('../../assets/icons/icon-physiognomy-advantage.png');
export const HOROSCOPE_ADVANTAGE_BLUE = require('../../assets/icons/icon-horoscope.png');
export const PALM_ADVANTAGE_BLUE = require('../../assets/icons/icon-palm-reading.png');
export const PHYSIOGNOMY_ADVANTAGE_BLUE= require('../../assets/icons/icon-physiognomy-blue.png');
export const ARROW_NEXT = require('../../assets/icons/arrow-next.png');
export const COMPATIBILITY_DEFAULT = require('../../assets/icons/icon-select.png');
export const PLUS = require('../../assets/icons/icon-plus.png');

export const ZODIAC_AQUARIUS = require('../../assets/icons/zodiac-signs-picker/aquarius.png');
export const ZODIAC_ARIES = require('../../assets/icons/zodiac-signs-picker/aries.png');
export const ZODIAC_CANCER = require('../../assets/icons/zodiac-signs-picker/cancer.png');
export const ZODIAC_CAPRICORN = require('../../assets/icons/zodiac-signs-picker/capricorn.png');
export const ZODIAC_GEMINI = require('../../assets/icons/zodiac-signs-picker/gemini.png');
export const ZODIAC_LEO = require('../../assets/icons/zodiac-signs-picker/leo.png');
export const ZODIAC_LIBRA = require('../../assets/icons/zodiac-signs-picker/libra.png');
export const ZODIAC_PISCES = require('../../assets/icons/zodiac-signs-picker/pisces.png');
export const ZODIAC_SAGITTARIUS = require('../../assets/icons/zodiac-signs-picker/sagittarius.png');
export const ZODIAC_SCORPIO = require('../../assets/icons/zodiac-signs-picker/scorpio.png');
export const ZODIAC_TAURUS = require('../../assets/icons/zodiac-signs-picker/taurus.png');
export const ZODIAC_VIRGO = require('../../assets/icons/zodiac-signs-picker/virgo.png');

export const SETTINGS_GENDER_FEMALE = require('../../assets/icons/icon-user-woman.png');
export const SETTINGS_GENDER_MALE = require('../../assets/icons/icon-user-man.png');
export const SETTINGS_NEXT = require('../../assets/icons/icon-settings-next.png');
export const SETTINGS_RATE_US = require('../../assets/icons/icon-rate.png');
export const SETTINGS_PRIVACY_POLICY = require('../../assets/icons/icon-termsofuse.png');
export const SETTINGS_TERMS = require('../../assets/icons/icon-policy.png');
export const SETTINGS_LANGUAGE = require('../../assets/icons/icon-language.png');
export const SETTINGS_CHECK = require('../../assets/icons/icon-check.png');
export const SETTINGS_UNCHECK = require('../../assets/icons/icon-uncheck.png');


export const REL_DIFFICULT_ACTIVE = require('../../assets/icons/relationship/difficult-active.png');
export const REL_FREE_ACTIVE= require('../../assets/icons/relationship/free-active.png');
export const REL_MARRIED_ACTIVE = require('../../assets/icons/relationship/married-active.png');
export const REL_SOULMATE_ACTIVE= require('../../assets/icons/relationship/soulmate-active.png');

export const REL_DIFFICULT_UNSELECTED = require('../../assets/icons/relationship/difficult-unselected.png');
export const REL_FREE_UNSELECTED = require('../../assets/icons/relationship/free-unselected.png');
export const REL_SOULMATE_UNSELECTED =  require('../../assets/icons/relationship/soulmate-unselected.png');
export const REL_MARRIED_UNSELECTED = require('../../assets/icons/relationship/married-unselected.png');

export const REL_DIFFICULT_DISABLED = require('../../assets/icons/relationship/difficult-disabled.png');
export const REL_FREE_DISABLED = require('../../assets/icons/relationship/free-disabled.png');
export const REL_SOULMATE_DISABLED = require('../../assets/icons/relationship/soulmate-disabled.png');
export const REL_MARRIED_DISABLED = require('../../assets/icons/relationship/married-disabled.png');


export const ICON_TERRIBLE = require('../../assets/icons/icon-terrible.png');
export const ICON_TERRIBLE_ACTIVE = require('../../assets/icons/icon-terrible-active.png');
export const ICON_BAD = require('../../assets/icons/icon-bad.png');
export const ICON_BAD_ACTIVE = require('../../assets/icons/icon-bad-active.png');
export const ICON_OKAY = require('../../assets/icons/icon-okay.png');
export const ICON_OKAY_ACTIVE = require('../../assets/icons/icon-okay-active.png');
export const ICON_GOOD = require('../../assets/icons/icon-good.png');
export const ICON_GOOD_ACTIVE = require('../../assets/icons/icon-good-active.png');
export const ICON_GRATE = require('../../assets/icons/icon-greate.png');
export const ICON_GRATE_ACTIVE = require('../../assets/icons/icon-greate-active.png');

export const HOROSCOPES = require('../../assets/icons/horoscopes.png');

export const ICON_CANCEL = require('../../assets/icons/icon-cancel.png');
export const BADGE = require('../../assets/icons/badge.png');

export const ONBOARDING_HAND = require('../../assets/icons/hand.png');

export const MARK_GREEN = require('../../assets/icons/sub-landing/check-mark.png');
export const MARK = require('../../assets/icons/sub-landing/check-mark-purple.png');
export const PADLOCK = require('../../assets/icons/sub-landing/locked-padlock.png');
export const STAR = require('../../assets/icons/sub-landing/star.png');

export const TRY_PREMIUM = require('../../assets/icons/gift-icon.png');
export const UNLIMITED = require('../../assets/icons/unlimited-icon.png');

export const MORE_THEN_10 = require('../../assets/icons/advantages/tarot-pink.png');
export const ADVANCED_PALM = require('../../assets/icons/advantages/hand-pink.png');
export const PERSONAL_COMPATIBILITY = require('../../assets/icons/advantages/gender-pink.png');
export const ADS_FREE = require('../../assets/icons/advantages/no-ads.png');

export const PREMIUM = require('../../assets/icons/premium.png');
