import firebase from 'firebase/app';
import 'firebase/remote-config';
import Mutator from '@magnus/react-native-mutator';

import { createRemoteConfigSnapshotNormalizer } from './normalizer';

export default {

  fetch(params, defaultValues, duration) {
    Mutator.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN,
    });
    if (duration) {
      firebase.remoteConfig().settings = { minimumFetchIntervalMillis: duration * 1000, };
    }
    return Promise.all([
      firebase.remoteConfig()
        .fetchAndActivate()
        .then(() => firebase.remoteConfig().getAll())
        .then(createRemoteConfigSnapshotNormalizer(defaultValues, true))
        .then(({ remoteConfig, }) => remoteConfig)
        .catch(() => ({ __use_magnus_remote_config__: defaultValues.__use_magnus_remote_config__, })),
      Mutator.fetch(duration)
        .catch(() => { }),
    ])
      .then(([fRes]) => {
        if (fRes.__use_magnus_remote_config__) {
          return Mutator.activate()
            .then(createRemoteConfigSnapshotNormalizer(defaultValues, true, true))
            .then(({ remoteConfig, }) => remoteConfig)
        }
        else {
          return fRes;
        }
      });
  },

}
