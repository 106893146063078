import { GOOGLE_CLOUD_API_KEY } from "../constants/general";
import api from "./index";


const GOOGLE_VISION_API =
  "https://vision.googleapis.com/v1/images:annotate?key=" +
  GOOGLE_CLOUD_API_KEY;

const PALM_READING = "/image-recognition/palm-reading";

export const GOOGLE_VISION_ERROR = "we didn't receive response from server";

/**
 * Google Cloud Vision Label Detection. See more: https://cloud.google.com/vision/docs/reference/rest/v1/images/annotate
 */
export function getImageLabels(base64Image) {
  return fetch(GOOGLE_VISION_API, {
    method: "POST",
    body: JSON.stringify({
      requests: [
        {
          image: {
            content: base64Image,
          },
          features: [
            {
              type: "LABEL_DETECTION",
            },
          ],
        },
      ],
    }),
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch(e => {
      console.warn(GOOGLE_VISION_API, e);
      return GOOGLE_VISION_ERROR;
      // throw e;
    });
}

export function getPalm() {
  return api.fetch(PALM_READING,
    {
      method: 'GET',
    });
}

export const postPalm = (image, hand_type) => {
  const form = new FormData();
  form.append("image_file",
    image.blob
      ? new File([image.blob], `${hand_type}.jpg`)
      : {
        uri: image.uri,
        type: "image/jpg",
        name: `${hand_type}.jpg`,
      }
  );

  return api.fetch(
    PALM_READING + '?hand_type=' + hand_type,
    {
      method: "POST",
      headers: image.blob ? {} : { "Content-Type": "multipart/form-data", },
      data: form,
    },
    true,
  );
};
