import React, { PureComponent } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import PropTypes from 'prop-types';

import Text from './text';
import Touchable from './touchable';
import * as COLORS from '../constants/colors';
import ButtonSpinner from "./button-spinner";
import LinearGradient from 'react-native-linear-gradient';
import { ARROW_NEXT } from '../constants/icons';

const ltr = {
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
};

const ttb = {
  start: { x: 0, y: 0 },
  end: { x: 0, y: 1 },
};

export default class Button extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    style: PropTypes.any,
    titleStyle: PropTypes.any,
    subTitleStyle: PropTypes.any,
    primary: PropTypes.bool,
    disabled: PropTypes.bool,
    isSpinner: PropTypes.bool,
    fontFamily: PropTypes.string,
    gradient: PropTypes.array,
    gradientDirection: PropTypes.oneOf(['ltr', 'ttb']), // left-to-right, top-to-bottom
    fontWeight: PropTypes.string,
    isTransparent: PropTypes.bool,
    vibration: PropTypes.bool,
    fontWeightImportant: PropTypes.bool,
  };

  static defaultProps = {
    primary: false,
    disabled: false,
    isSpinner: false,
    isTransparent: false,
    gradientDirection: 'ltr',
    fontWeightImportant: false,
  };

  getGradientDirection(direction) {
    switch (direction) {
      case 'ltr':
        return ltr;
      case 'ttb':
        return ttb;
    }
  }

  render() {
    const {
      title,
      subTitle,
      style,
      titleStyle,
      subTitleStyle,
      fontFamily,
      primary,
      onPress,
      disabled,
      icon,
      isSpinner,
      gradient,
      gradientDirection,
      fontWeight,
      isExtraSubscription,
      isTransparent,
      vibration,
      fontWeightImportant,
    } = this.props;

    const gradientColors = disabled || isSpinner
      ? COLORS.GRADIENT_COLORS_DISABLE_BUTTON
      : gradient || COLORS.GRADIENT_COLORS_BUTTON;

    const Component = isTransparent ? View : LinearGradient;

    const textStyle = [styles.title, (isSpinner || disabled) && styles.titleDisabled, titleStyle];
    return (
      <Touchable
        style={styles.buttonContainer}
        vibration={vibration}
        onPress={isSpinner || disabled ? undefined : onPress}>
        <Component
          {...isTransparent ? null : this.getGradientDirection(gradientDirection)}
          colors={gradientColors}
          style={[styles.button,
          primary && styles.primary,
          subTitle && styles.withSubtitle,
          style]}
        >
          <View style={styles.titleCt}>
            {icon ? <Image source={icon} style={styles.icon} /> : null}
            <Text
              fontWeightImportant={fontWeightImportant}
              fontFamily={fontFamily}
              fontWeight={fontWeight || (subTitle ? 'bold' : 'semibold')}
              style={[
                textStyle,
                primary && styles.primaryText,
                isExtraSubscription && styles.extraSubscriptionText,
              ]}>
              {title}
            </Text>
          </View>
          {subTitle && (
            <Text
              fontWeightImportant={fontWeightImportant}
              fontFamily={fontFamily}
              style={[
                styles.subTitle,
                primary && styles.primarySubText,
                subTitleStyle,
              ]}>
              {subTitle}
            </Text>
          )}
          {isSpinner ? <ButtonSpinner/> : null}
          {
            isExtraSubscription
              ?
                <Image
                  style={styles.arrowIcon}
                  source={ARROW_NEXT}
                />
              : null
          }
        </Component>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'relative',
    width: '100%',
    shadowColor: COLORS.BLACK_OPACITY,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    borderRadius: 30,
  },
  button: {
    padding: 13,
    borderRadius: 30,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primary: {
    borderColor: COLORS.BLACK,
    borderWidth: 1.5,
  },
  primaryText: {
    color: COLORS.BLACK,
  },
  primarySubText: {
    color: COLORS.WARM_GREY,
  },
  withSubtitle: {
    padding: 7,
  },
  titleCt: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 8,
  },
  title: {
    color: COLORS.WHITE,
    fontSize: 26,
    lineHeight: 32,
    textAlign: 'center',
  },
  subTitle: {
    color: COLORS.WHITE,
    fontSize: 15,
    lineHeight: 18,
    textAlign: 'center',
  },
  titleDisabled: {
    color: COLORS.WHITE,
  },
  extraSubscriptionText: {
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2, },
    textShadowRadius: 2,
  },
  arrowIcon: {
    position: 'absolute',
    right: 15,
    width: 22,
    height: 15,
  },
});
