import {
  SET_COMPATIBILITY_CHOICE_PICK,
  SET_COMPATIBILITY_RESULT,
  SET_COMPATIBILITY_PENDING,
  SET_COMPATIBILITY_ERROR,
} from './types';

const initialState = {
  pick: null,
  pending: false,
  result: null,
  error: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_COMPATIBILITY_CHOICE_PICK:
      return {
        ...state,
        pick: action.payload.pick,
      }
    case SET_COMPATIBILITY_PENDING:
      return {
        ...state,
        pending: action.payload.pending,
      }
    case SET_COMPATIBILITY_RESULT:
      return {
        ...state,
        pending: false,
        result: action.payload.result,
      }
    case SET_COMPATIBILITY_ERROR: 
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      }
    default:
      return state;
  }
}
