import _ from 'lodash';
import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';

import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/hi';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';

import locales from './locales.json';
import en from './translations/en.json';
import ru from './translations/ru.json';
import es from './translations/es.json';
import ar from './translations/ar.json';
import hi from './translations/hi.json';
import pt from './translations/pt.json';
import zh from './translations/zh.json';
import ko from './translations/ko.json';
import moment from 'moment';

export const DEFAULT_CONTENT_LOCALES = ['en', 'ru'];

export const Localization = {
  init() {
    i18n.defaultLocale = 'en';
    i18n.fallbacks = true;
    i18n.missingTranslation = key => key;
    i18n.locale = RNLocalize.getLocales()[0].languageTag;

    i18n.translations = {
      en,
      ru,
      es,
      ar,
      hi,
      pt,
      zh,
      ko,
    };

    _.merge(i18n.locales, locales);
    moment.locale(i18n.locale);

    i18n.getActiveLocale = function() {
      let locale;
      const locales = this.locales.get().slice();

      while (locales.length) {
        locale = locales.shift();

        if (this.translations[locale]) {
          break;
        }
      }

      return locale;
    };
  },
};

export default i18n;
