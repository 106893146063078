import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as COLORS from '../../../constants/colors';
import i18n from '../../../localization';
import PulseAnimationButtonWrapper from '../../../components/pulse-animation-button-wrapper';
import { incrementTrialsLeft } from '../../../store/app/actions';

const animationDuration = 1000;
const animationDelay = 500;
const trialsMax = 14;

function getSpeedForChangeTrialCounter(trials) { //for change trials this different times to show "in-real-time" changes for live effect
  if (trials < 5) {
    return 0;
  }
  if (trials < 8) {
    return 4000 * (8 - trials);
  }
  if (trials < 12) {
    return _.random(1000, 3000);
  }

  return 1000;
}

class BadgeWithCounter extends Component {
  static propTypes = {
    trials: PropTypes.number.isRequired,
    incrementTrialsLeft: PropTypes.func.isRequired,
  };

  static defaultProps = {
    trials: 0,
  };

  beginTimeout = null;
  animationTimeout = null;

  state = {
    isAnimation: true,
  };

  componentWillUnmount() {
    clearTimeout(this.beginTimeout);
    clearTimeout(this.animationTimeout);
  }

  onAnimationBegin = () => {
    const { incrementTrialsLeft, trialsCounter,  } = this.props;
    const trials = trialsMax - trialsCounter;

    if (trials === 4) {
      return;
    }

    this.beginTimeout = setTimeout(incrementTrialsLeft, (animationDuration + animationDelay) / 2);
  };

  onAnimationEnd = () => {
    const { trialsCounter } = this.props;
    const trials = trialsMax - trialsCounter;
    const time = getSpeedForChangeTrialCounter(trials);

    if (time) {
      this.setState({ isAnimation: false }, () => {
        this.animationTimeout = setTimeout(() => {
          this.setState({ isAnimation: true });
        }, time);
      });
    }
  };

  renderBadge = () => {
    const { trialsCounter } = this.props;
    let trials = trialsMax - (trialsCounter || 0);


    return (
      <View style={styles.root}>
        <Text style={styles.text}>
          {i18n.t('SUBSCRIPTION.TRIALS_LEFT', {trials})}
        </Text>
      </View>
    );
  };

  render() {
    const { isAnimation } = this.state;

    const Badge = this.renderBadge();
    if (isAnimation) {
      return (
        <PulseAnimationButtonWrapper
          iterationCount={1}
          onAnimationBegin={this.onAnimationBegin}
          onAnimationEnd={this.onAnimationEnd}
        >
          {Badge}
        </PulseAnimationButtonWrapper>
      );
    }
    return Badge;
  }
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 12.5,
    height: 28,
    borderWidth: 2,
    borderColor: COLORS.AQUA_MARINE,
    paddingHorizontal: 25,
    backgroundColor: COLORS.BLUE_WITH_A_HINT_OF_PURPLE,
    alignItems: 'center',
    justifyContent: 'center',

    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowColor: COLORS.BLACK,
    shadowOffset: { height: 1, width: 1 },
    elevation: 1,
    width: 'auto',
    transform: [{ rotate: '8deg' }],
  },
  text: {
    fontSize: 15,
    fontFamily: 'SFProDisplay-Bold',
    color: COLORS.WHITE,
    textShadowColor: COLORS.SHADOW,
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 4,
  },
});

export default connect(state => ({
    trialsCounter: state.app.trialsCounter,
  }),
  {
    incrementTrialsLeft,
  },
)(BadgeWithCounter);
