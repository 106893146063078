export default {

  init() {
  },

  loadRewarded() {
    return Promise.reject(Error('no web ads'));
  },

  isLoadedInterstitial() {
    return false;
  },

  isLoadedRewarded() {
    return false;
  },

  loadInterstitial() {
    return Promise.reject(Error('no web ads'));
  },

  showRewarded() {
    return Promise.reject(Error('no web ads'));
  },

  showInterstitial() {
    return Promise.reject(Error('no web ads'));
  },

};


