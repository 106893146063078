import _ from 'lodash';

function splitParams(paramsAsString) {
  if (!paramsAsString || typeof paramsAsString !== 'string') {
    return [];
  }
  return paramsAsString.split(',').map(param => param.toUpperCase().trim());
}

function parseJSONParams(paramsAsString, defaultValue) {
  try {
    return JSON.parse(paramsAsString);
  } catch (err) {
    return defaultValue;
  }
}

function convertType(value, defaultValue) {
  if (_.isString(defaultValue)) {
    return value.asString() || defaultValue;
  }
  if (_.isPlainObject(defaultValue)) {
    return parseJSONParams(value.asString(), defaultValue);
  }
  if (_.isArray(defaultValue)) {
    return splitParams(value.asString());
  }
  if (_.isBoolean(defaultValue)) {
    return !!value.asBoolean();
  }
  if (_.isNumber(defaultValue)) {
    return value.asNumber();
  }
  return value.asString();
}

function convertMutatorType(value, defaultValue) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (_.isString(defaultValue)) {
    return value || defaultValue;
  }
  if (_.isPlainObject(defaultValue)) {
    return parseJSONParams(value, defaultValue);
  }
  if (_.isArray(defaultValue)) {
    return splitParams(value);
  }
  if (_.isBoolean(defaultValue)) {
    return !!value;
  }
  if (_.isNumber(defaultValue)) {
    return value;
  }
  return value;
}

export function createRemoteConfigSnapshotNormalizer(defaultValues, enableLogger = false, mutator = false) {
  return function (snapshot) {
    if (enableLogger && __DEV__) {
      console.log('Normalizer: param names', Object.keys(defaultValues));
      console.log('Normalizer: snapshot', snapshot);
    }

    const remoteConfig = Object.keys(defaultValues).reduce(
      (convertedData, paramName) => {
        let paramValue;
        if (mutator) {
          convertedData[paramName] = convertMutatorType(
            snapshot[paramName],
            defaultValues[paramName]
          );
        }
        else if (_.get(snapshot[paramName], '_source') === 'remote') {
          convertedData[paramName] = convertType(
            snapshot[paramName],
            defaultValues[paramName]
          );
        } else {
          paramValue = defaultValues[paramName];
        }
        return convertedData;
      },
      {}
    );

    if (enableLogger && __DEV__) {
      console.log('Normalizer: normalized remoteConfig', remoteConfig);
    }

    return { remoteConfig, snapshot };
  };
}
