import { createAction } from 'redux-actions';

import Analytics from '../../modules/analytics';
import * as TYPES from './types';

const setVisibleCustomAppIconStatus = createAction(TYPES.CUSTOM_APP_ICON_SET_VISIBLE);
export const setVisibleRateUsStatus = createAction(TYPES.RATING_SET_VISIBLE);

export function closeCustomAppIconModal() {
  return function(dispatch) {
    return dispatch(setVisibleCustomAppIconStatus(false));
  };
}

export function openCustomAppIconModal() {
  return function(dispatch) {
    Analytics.trackEvent('pro_icon', 'open');
    return dispatch(setVisibleCustomAppIconStatus(true));
  };
}
