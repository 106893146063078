import { NavigationActions, StackActions } from 'react-navigation';
import _ from 'lodash';

import processRoute from './routing';
import * as ROUTES from '../../constants/routes';
import { ONBOARDING_IDS, ONBOARDING_SCREENS } from '../../constants/onboarding-types';
import * as VARIATOR_STATUSES from '../../constants/variator-statuses';
import { setVariatorStatus } from '../app/actions';
import { FINISHED_HAND_ANALYZE_NOTIFICATION_ID } from '../../constants/messaging';
import Analytics from '../../modules/analytics';
import { onboardingCompleted } from '../onboarding/actions';
import { Platform } from 'react-native';

const actions = {
  navigate: 'navigate',
  replace: 'replace',
};

export function navigate(routeName, params, usePush = false) {
  return usePush
    ? StackActions.push({ routeName, params })
    : NavigationActions.navigate({ routeName, params });
}

export function back() {
  return function (dispatch) {
    return dispatch(NavigationActions.back());
  };
}

export function reset(routeName, params) {
  return function (dispatch) {
    return dispatch(StackActions.reset({
      index: 0,
      actions: [NavigationActions.navigate({ routeName, params })],
    }));
  };
}

export function replace(routeName, params, route) {
  return function (dispatch) {
    return dispatch(processRoute({ routeName, params, route })).then(updatedRouteInfo => {
      return dispatch(
        StackActions.replace(createRoute(updatedRouteInfo, 'replace')),
      );
    });
  };
}

function createRoute(routeInfo, action) {
  let routes = null;

  routeInfo.forEach(item => {
    const { routeName, params } = item;

    if (routes) {
      switch (action) {
        case actions.navigate:
          routes.action = NavigationActions.navigate({ routeName, params });
          break;

        case actions.replace:
          routes.action = StackActions.replace({ routeName, params });
          break;
      }
    } else {
      routes = {
        routeName,
        params,
      };
    }
  });

  return routes;
}

export function fillInProfile() {
  return function (dispatch, getState) {
    const profile = getState().onboarding.onboardingProfile;

    if (!profile.name) return dispatch(navigate(ROUTES.ONBOARDING_NAME));
    if (!profile.gender) return dispatch(navigate(ROUTES.ONBOARDING_GENDER));
    if (!profile.relationship) return dispatch(navigate(ROUTES.ONBOARDING_RELATIONSHIP));
    if (!profile.date) return dispatch(navigate(ROUTES.ONBOARDING_DATE));
  };
}


export function checkFillInProfile(route) {
  return function (dispatch, getState) {
    const { isFullProfile } = getState().app;

    if (isFullProfile) {
      dispatch(navigate(route));
    } else {
      dispatch(fillInProfile());
    }
  };
}

export function onboardingStepNavigate() {
  return function (dispatch, getState) {
    const { onboardingFlow } = getState().remoteConfig.remoteConfigParams;
    const { onboardingStep } = getState().onboarding;

    if (!onboardingFlow.includes(ONBOARDING_IDS.PROFILE)) {
      onboardingFlow.push(ONBOARDING_IDS.PROFILE);
    }
    const currentOnboardingStep = onboardingFlow[onboardingStep];
    if (onboardingFlow.length) {
      if (Object.keys(ONBOARDING_SCREENS).includes(currentOnboardingStep)) {
        dispatch(reset(ONBOARDING_SCREENS[currentOnboardingStep]));
      }
      else {
        dispatch(reset(ONBOARDING_SCREENS[onboardingFlow[0]]));
      }
    }
    else {
      dispatch(reset(ROUTES.MAIN));
    }
  };
}

export function authNavigate() {
  return function (dispatch, getState) {
    const { purchased } = getState().purchases;
    const { email } = getState().profile.profileData;

    if (Platform.OS === 'web' && purchased && !email) {
      dispatch(reset(ROUTES.AUTH, { linking: true, }));
      return true;
    }
    else if (Platform.OS === 'web' && window.location.pathname === '/cancel-subscription') {
      dispatch(reset(ROUTES.SETTINGS_CANCEL_SUBSCRIPTION));
      return true;
    }
  };
}

export function onNotificationOpen(notificationOpen) {
  return function (dispatch) {
    if (!notificationOpen) {
      return false;
    }

    const notificationId = _.get(notificationOpen, 'notification.data.id', null);
    if (notificationId !== FINISHED_HAND_ANALYZE_NOTIFICATION_ID) {
      return false;
    }
    dispatch(navigate(ROUTES.MAIN));
    dispatch(navigate(ROUTES.DIVINATIONS));
    return true;
  };
}

export function backTermsAndPrivacy() {
  return function (dispatch, getState) {
    const { variatorStatus } = getState().app;
    if (variatorStatus === VARIATOR_STATUSES.PRIVACY || variatorStatus === VARIATOR_STATUSES.TERMS) {
      dispatch(setVariatorStatus(VARIATOR_STATUSES.OPENED));
    }
    dispatch(back());
  }
}

export function navigateAfterPurchase() {
  return function (dispatch, getState) {
    const { onboarding: { isOnboardingCompleted }, app: { isFullProfile } } = getState();
    if (!isOnboardingCompleted) {
      dispatch(onboardingCompleted());
      if (!isFullProfile) {
        return dispatch(reset(ROUTES.ONBOARDING_NAME));
      }
    }
    if (!dispatch(authNavigate())) {
      dispatch(reset(ROUTES.MAIN));
    }
  }
}
