import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from "prop-types";
import LottieView from "lottie-react-native";

const STARS_LOADING_PURPLE = require('../../assets/animations/stars_loading_purple');
const STARS_LOADING_WHITE = require('../../assets/animations/stars_loading_white');

class LoadingComponent extends PureComponent {
  static propTypes = {
    isWhite: PropTypes.bool,
    style: PropTypes.any,
    rootStyle: PropTypes.any,
    size: PropTypes.oneOf(["small", "large"]),
  };

  render() {
    const { isWhite, style, size, rootStyle } = this.props;
    return (
      <View style={[styles.root, rootStyle]}>
        <LottieView
          style={[styles.loading, size === "large" ? styles.sizeLarge : styles.sizeSmall, style]}
          autoPlay={true}
          source={!!isWhite ? STARS_LOADING_WHITE : STARS_LOADING_PURPLE} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sizeSmall: {
    width: 20,
    height: 20,
  },
  sizeLarge: {
    width: 36,
    height: 36,
  },
  loading: {
    flexGrow: 100,
  },
});

export default LoadingComponent;
