import React, { PureComponent } from 'react';
import { createAnimatableComponent } from 'react-native-animatable';
import { View } from 'react-native';

const ButtonAnimatedWrapper = createAnimatableComponent(View);

export default class ZoomInWrapper extends PureComponent {
  render() {
    const { children, style } = this.props;

    return (
      <ButtonAnimatedWrapper
        style={style}
        duration={0}
        easing={'ease-in'}
        animation={'zoomIn'}
      >
        {children}
      </ButtonAnimatedWrapper>
    )
  }
}
