import React, { PureComponent } from 'react';
import {
  StyleSheet,
  View,
  Platform,
  StatusBar,
  Image,
} from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ifIphoneX } from 'react-native-iphone-x-helper';

import CloseButtonWithTimeout from '../components/close-button-with-timeout';
import { CLOSE_ICON } from '../../../constants/icons';
import * as IMAGES from '../../../constants/images';
import * as COLORS from '../../../constants/colors';
import i18n from '../../../localization';
import { verticalScale, horizontalScale, textSubscription } from '../../../utils/scale';
import Text from '../../../components/text';
import FullScrollView from '../../../components/full-scroll-view';
import { getButtonDefaultInfoFromProduct } from '../../../modules/billing/conveter';
import SubBottomWrapper from '../components/subscription-bottom-wrapper';
import { INSIDE, NONE } from '../../../constants/button-price-place';
import Analytics from '../../../modules/analytics';
import CheckButton from './check-button';
import AnimatedBG from '../../tarot/components/animated-bg';
import { goToNextOnboardingStep } from '../../../store/onboarding/actions';
import * as ROUTES from '../../../constants/routes';

const availableRoutes = [ROUTES.ONBOARDING_SUBSCRIPTION_SECOND, ROUTES.ONBOARDING_SUBSCRIPTION_FIRST];


class OnboardingSubscriptionThree extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    remoteConfig: PropTypes.object,
    product: PropTypes.string,
    onClosePress: PropTypes.func.isRequired,
    analyticsType: PropTypes.string.isRequired,
    activeRoute: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const defaultTitles = props.remoteConfig.subscriptionThreeTitles.split(';'),
      titles = [],
      productsId = props.remoteConfig.subscriptionThreeProducts.split(';')
        .filter((product, index) => {
          const isCheckedProduct = !!product && Object.keys(getButtonDefaultInfoFromProduct(product)).length;
          if (isCheckedProduct) {
            titles.push(defaultTitles[index]);
          }
          return isCheckedProduct;
        }),
      products = productsId.map((product, index) => {
        const data = getButtonDefaultInfoFromProduct(product);
        return { ...data, title: titles[index] || data.title, id: productsId[index] };
      });

    let productsDetails = {};

    productsId.forEach((productId) => {
      productsDetails[`${productId}`] = { subTitle: getButtonDefaultInfoFromProduct(productId)?.subTitle };
    },
    );

    this.state = {
      products: products,
      productsDetails: productsDetails,
      activeId: productsId[0],
    };
  }

  componentDidMount() {
    const { analyticsType } = this.props;
    Analytics.trackEvent(analyticsType, 'Open');
  }

  render() {
    const { analyticsType, remoteConfig } = this.props;
    const {
      isSubscriptionThreeTryLimited,
      subscriptionThreeBottomButtonTitle,
      subscriptionThreeTimeout,
      colorSubscriptionButtons,
      popularProductFromThree,
      isSubscriptionThreeButtonSubtitle,
    } = remoteConfig,
      { products, activeId, productsDetails } = this.state;

    return (
      <View style={styles.root}>
        <AnimatedBG visible={this.props.activeRoute}>
          <FullScrollView>
            <View style={styles.container}>
              <StatusBar barStyle='light-content' />
              <CloseButtonWithTimeout
                closePress={this.onCloseButtonPress}
                source={CLOSE_ICON}
                closeButtonTimeout={subscriptionThreeTimeout}
              />
              <Text fontWeightImportant style={styles.title} fontWeight={'bold'}>
                {i18n.t('ONBOARDING.SUBSCRIPTION_FIRST.TITLE')}
              </Text>

              <View style={styles.checkButtons}>
                {
                  products.map((el, index) => {
                    const isActive = activeId === el.id;
                    return <View key={el.id} style={[styles.checkButton, !isActive && styles.isNotActiveCheckButton]}>
                      <CheckButton
                        colorSubscriptionButtons={colorSubscriptionButtons}
                        isMostPopular={index === (popularProductFromThree - 1)}
                        isActive={isActive}
                        isSubtitle={!isSubscriptionThreeButtonSubtitle}
                        onPress={this.onCheckProduct}
                        {...el}
                      />
                    </View>
                  })
                }
              </View>

              {
                products.length > 1
                  ? null
                  : <View style={styles.list}>
                    {
                      i18n.t('ONBOARDING.SUBSCRIPTION_THREE.ADVANTAGES').map(item => (
                        <View style={styles.listItem}>
                          <Image
                            style={styles.listItemImage}
                            source={IMAGES.ONBOARDING_SUBSCRIPTION_STAR}
                            resizeMode={'contain'}
                          />
                          <Text fontWeightImportant style={styles.listItemText}>{item}</Text>
                        </View>
                      ))
                    }
                  </View>
              }

            </View>
            <SubBottomWrapper
              isRelative
              isExtraSubscription
              isTryLimited={isSubscriptionThreeTryLimited}
              onTryLimitedPress={this.onTryLimitedPress}
              productDetails={productsDetails[`${activeId}`]}
              products={products}
              product={activeId}
              title={subscriptionThreeBottomButtonTitle || i18n.t('ONBOARDING.SUBSCRIPTION_THREE.DEFAULT_BOTTOM_BUTTON')}
              pricePlace={isSubscriptionThreeButtonSubtitle ? INSIDE : NONE}
              analyticsType={analyticsType}
              trialBadge={false}
            />
          </FullScrollView>
        </AnimatedBG>
      </View>
    );
  }

  onCheckProduct = (id) => {
    this.setState({ activeId: id });
  };

  onCloseButtonPress = () => {
    const { analyticsType } = this.props;

    Analytics.trackEvent(analyticsType, 'Close');
    this.onClosePress();
  };

  onTryLimitedPress = () => {
    const { analyticsType } = this.props;

    Analytics.trackEvent(analyticsType, 'TryLimited');
    this.onClosePress();
  };

  onClosePress = () => {
    const { onClosePress, goToNextOnboardingStep } = this.props;
    if (onClosePress) {
      onClosePress();
    } else {
      goToNextOnboardingStep();
    }
  };
}

export default connect(
  (state, ownProps) => ({
    remoteConfig: state.remoteConfig.remoteConfigParams,
    product: state.remoteConfig.remoteConfigParams.onboardingSubscriptionFirstProduct,
    activeRoute: availableRoutes.includes(state.navigation.activeRouteName),
    analyticsType: ownProps.analyticsType || state.app.paywall ? 'paywall_three' : 'Onboarding_SubscriptionThree',
  }), {
  goToNextOnboardingStep,
}
)(OnboardingSubscriptionThree);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    ...(Platform.OS === 'ios'
      ? {
        ...ifIphoneX({
          paddingTop: verticalScale(65),
        }, {
          paddingTop: verticalScale(40),
        })
      }
      : { paddingTop: verticalScale(40), }
    ),
  },
  bgImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  checkButton: {
    width: '100%',
    marginVertical: verticalScale(5),
  },
  isNotActiveCheckButton: {
    paddingHorizontal: horizontalScale(25),
  },
  checkButtons: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingHorizontal: horizontalScale(25),
  },
  title: {
    color: COLORS.WHITE,
    fontSize: textSubscription(36),
    paddingHorizontal: horizontalScale(25),
    letterSpacing: textSubscription(-1.16),
    lineHeight: textSubscription(42),
    textAlign: 'center',
  },
  subTitle: {
    width: horizontalScale(240),
    marginTop: verticalScale(5),
    color: COLORS.WHITE,
    textAlign: 'center',
    fontSize: horizontalScale(14),
  },
  animationWrapper: {
    width: horizontalScale(320),
    height: verticalScale(155),
    marginTop: verticalScale(10),
  },
  list: {
    justifyContent: 'center',
    width: '100%',
    paddingHorizontal: horizontalScale(50),
    marginTop: verticalScale(6),
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: verticalScale(5),
  },
  listItemImage: {
    marginTop: verticalScale(-1),
    marginRight: horizontalScale(5),
    height: verticalScale(20),
    width: verticalScale(20),
  },
  listItemText: {
    fontSize: horizontalScale(14),
    lineHeight: verticalScale(18),
    color: COLORS.WHITE,
  },
  listItemTouchable: {
    textDecorationLine: 'underline',
  },
});
