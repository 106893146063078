import Tts from 'react-native-tts';
import {createAction} from "redux-actions";
import _ from "lodash";

import {
  SET_ERROR,
  SET_HOROSCOPE,
  SET_ALL_SIGNS,
  SET_PENDING,
  SET_HOROSCOPE_INFO, SET_ZODIAC_ANIMATION_COUNT,
} from './types';
import {getHoroscope, getAllSigns} from "../../api/horoscope";
import {CHINESE, ZODIAC} from "../../constants/horoscopes-names";
import {CURRENT_YEAR, START_YEAR} from "../../constants/date-time-arrays";
import { formatTextWithoutTags } from '../../utils/text-format-replace-tags';
import {SIGNS_ZODIAC} from "../../constants/sections";

export const setHoroscope = createAction(SET_HOROSCOPE);
export const setErrorHoroscope = createAction(SET_ERROR);
export const setAllSigns = createAction(SET_ALL_SIGNS);
export const setPendingHoroscope = createAction(SET_PENDING);
export const setHoroscopeInfo = createAction(SET_HOROSCOPE_INFO);
export const setZodiacAnimationCount = createAction(SET_ZODIAC_ANIMATION_COUNT);

export const initAllSigns = () => {
  return (dispatch) => {
    return getAllSigns().then(response => {

      const keys = Object.keys(response);
      let obj = {};
      keys.forEach(key => {
        switch (key.toUpperCase()) {
          case CHINESE:
            obj[`${key}`] = response[`${key}`].map(el => ({
              name: el.name,
              date: createArrayYearsByReminder(el.reminder),
            }));
            break;
          case ZODIAC:
            obj[`${key}`] = SIGNS_ZODIAC.map(el => {
              return _.find(response[`${key}`], {'name': el.toLocaleLowerCase()});
            });
            break;
          default:
            obj[`${key}`] = response[`${key}`];
        }
      });

      dispatch(setAllSigns(obj));
    });
  };
};

export const loadHoroscope = (horoscope_type, sign) => {
  return (dispatch, getState) => {
    dispatch(setHoroscopeInfo({sign, horoscope_type}));
    dispatch(setPendingHoroscope());

    return getHoroscope(horoscope_type, sign).then(data => {
      const {horoscopeInfo} = getState().horoscope,
        {horoscope_info} = data;
      if (horoscope_info.horoscope_type === horoscopeInfo.horoscope_type && horoscope_info.sign === horoscopeInfo.sign) {
        dispatch(setHoroscope(data));
      }
    }).catch((err) => {
      console.warn(err);
      dispatch(setErrorHoroscope());
    });
  };
};

function createArrayYearsByReminder(reminder) {
  const countsOfSings = 12,
    array = [];

  let startYear = START_YEAR;

  while (startYear % countsOfSings !== reminder) {
    startYear++;
  }

  while (startYear <= CURRENT_YEAR) {
    array.push(startYear);
    startYear += countsOfSings;
  }

  return array;
}

export const narrateHoroscopePrediction = (text = '') => {
  return () => {
    Tts.getInitStatus().then(() => {
      Tts.stop();
      Tts.speak(formatTextWithoutTags(text));
    });
  };
};

export const incrementZodiacAnimationCount = () => {
  return (dispatch, getState) => {
    const { horoscope: { zodiacAnimationCount } } = getState();
    const nextZodiacAnimationCount = zodiacAnimationCount + 1;
    dispatch(setZodiacAnimationCount(nextZodiacAnimationCount));
  };
};

