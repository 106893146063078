import { createAction } from 'redux-actions';
import _ from 'lodash';

import Auth from '../../modules/auth';
import api from '../../api';
import { auth as authApi } from '../../api/auth';


import { SET_USER_CREDENTIALS } from './types';
import { setProfile } from "../profile/actions";

const setUserCredentials = createAction(SET_USER_CREDENTIALS);

export function initAuth() {
  return function (dispatch, getState) {
    const { accessToken } = getState().auth;

    if (accessToken) {
      api.setToken(accessToken);
    }
    return dispatch(auth());
  };
}

function auth() {
  return function (dispatch) {
    return Auth.getCreds()
      .then(creds => {
        return authApi(creds);
      })
      .then(response => {
        if (response) {
          dispatch(setProfile(response));
          api.setToken(_.get(response, 'profile.access_token', null));
          dispatch(setUserCredentials(response));
        }
      })
      .catch(console.warn);
  };
}
