

import React from 'react';
import moment from 'moment';
import { PaymentRequestButtonElement, } from '@stripe/react-stripe-js';
import DeviceProps from '@magnus/react-native-device-props';
import UserProps from '@magnus/react-native-user-props';

import { initSetupIntent, createCustomer, } from '../../../src/api/purchases';

import applePayMark from './apple-pay-payment-mark.png';


const INTERVAL_LABELS = {
  'P1D': 'DAILY',
  'P1W': 'WEEKLY',
  'P1M': 'MONTHLY',
  'P1Y': 'YEARLY',
}

export class PRBE extends React.Component {

  state = {
    applePay: false,
    paymentRequest: null,
    paymentMethod: null,
  }

  componentDidUpdate(prev) {
    const {
      stripe,
      productDetails,
    } = this.props;

    let amount = productDetails.priceValue * 100;
    let ht = productDetails.haveTrialPeriod;
    let trial = productDetails.trial;
    let currency = productDetails.currency.toLowerCase();
    let interval = {
      label: (INTERVAL_LABELS[productDetails.subscriptionPeriod] || 'PERIODICAL') + ' SERVICE',
      amount,
      currency,
    };

    if (stripe && !prev.stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency,
        total: {
          label: ht ? 'TRIAL' : 'PREMIUM',
          amount: ht ? 0 : amount,
        },
        displayItems: this.props.displayDetails
          ? ht
            ? [
              {
                label: trial + ' DAYS FOR FREE',
                amount: 0,
                currency,
              },
              interval,
              {
                label: '1ST PAYMENT ON ' + moment().add(trial, 'days').format('YYYY-MM-DD'),
                amount: -amount,
                currency,
              },
            ]
            : [
              interval,
            ]
          : undefined,
        requestPayerName: false,
        requestPayerEmail: false,
      });

      paymentRequest.canMakePayment()
        .then(result => {
          this.props.onCheck(result);
          if (result) {
            this.setState({
              paymentRequest,
              applePay: !!result.applePay
            })
          }
        });

      paymentRequest.on('paymentmethod', this.handlePaymentMethod);
      paymentRequest.on('cancel', this.handleCancel);
    }
  }

  handleClick = () => {
    const {
      applePay,
    } = this.state;

    const {
      onStart,
    } = this.props;

    this.setState({
      paymentMethod: null,
    });

    onStart(applePay ? 'prb_apple_pay' : 'prb_not_apple_pay');
  }

  handleCancel = () => {
    if (!this.state.paymentMethod) {
      this.props.onError(Error('Cancel'));
    }
  }

  handlePaymentMethod = async (ev) => {
    const {
      productDetails,
      stripe,
      onSuccess,
      onError,
    } = this.props;

    this.setState({
      paymentMethod: ev.paymentMethod,
    });

    try {
      const si = await initSetupIntent();

      const cs = await stripe.confirmCardSetup(
        si.client_secret,
        {
          payment_method: ev.paymentMethod.id,
        },
      );

      if (cs.error) {
        ev.complete('fail');

        onError(cs.error);
      } else {
        ev.complete('success');

        let idfm = await DeviceProps.getIDFM();
        let dp = await DeviceProps.fetch();
        let up = await UserProps.fetch();

        let res = await createCustomer({
          paymentMethodId: ev.paymentMethod.id,
          plan: productDetails.productId,
          metadata: {
            idfm,
            ...up,
            ...dp,
          },
        });

        onSuccess(res);
      }
    }
    catch (ex) {
      onError(ex);
    }
  }

  handleCustomButtonClick = () => {
    const {
      paymentRequest,
    } = this.state;

    this.handleClick();

    paymentRequest.show();
  }

  render() {
    const {
      button,
      showBrandMark,
    } = this.props;

    const {
      applePay,
      paymentRequest,
    } = this.state;

    if (paymentRequest) {
      if (button) {
        return (
          <div
            style={{
              position: 'relative',
            }}
          >
            {applePay && showBrandMark ?
              <img
                src={applePayMark}
                alt={''}
                style={{
                  height: 18,
                }}
              />
              : null}
            <button
              style={{
                backgroundColor: 'transparent',
                border: 0,
                width: '100%',
                padding: 0,
              }}
              onClick={this.handleCustomButtonClick}
            >
              {button}
            </button>
          </div>
        );
      }
      else {
        return (
          <div>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    theme: 'light-outline',
                  },
                }
              }}
              onClick={this.handleClick}
            />
            <div style={{ marginTop: 8, marginBottom: 8, display: 'flex', color: '#fffd', }}>
              <hr style={{ borderColor: '#fff8', flexBasis: '50%', height: 0, }} />
              <nobr style={{ margin: '0 4px', fontFamily: "Open Sans", fontSize: 12, }}> Or proceed with card </nobr>
              <hr style={{ borderColor: '#fff8', flexBasis: '50%', height: 0, }} />
            </div>
          </div>
        );
      }
    }

    return null;
  }

}
