import React, { PureComponent } from 'react';
import { StyleSheet, Image, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import { headerMarginHorizontal, headerMarginVertical } from '../utils/sizes';

export default class CloseButton extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.any,
  };

  render() {
    const { onPress, source, style } = this.props;
    return (
      <TouchableOpacity style={[styles.close, style]} onPress={onPress}>
        <View style={styles.icon}>
          <Image
            source={source}
            resizeMode={'contain'}
            style={styles.img}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  close: {
    zIndex: 100,
    position: 'absolute',
    top: headerMarginVertical + 10,
    left: headerMarginHorizontal + 5,
    marginTop: - (headerMarginVertical + 10),
    paddingTop: headerMarginVertical + 10,
  },
  icon: {
    width: 36,
    height: 36,
  },
  img: {
    width: 20,
    height: 20,
  },
});
