export const DEFAULT_CHANNEL_ID = 'astro_notification_channel_id';

export const EVENING_NOTIFICATION_ID = 'EVENING';
export const MORNING_NOTIFICATION_ID = 'MORNING';
export const FIRST_SESSION_FIRST_NOTIFICATION_ID = 'FIRST_SESSION_FIRST_NOTIFICATION_ID';
export const FIRST_SESSION_SECOND_NOTIFICATION_ID = 'FIRST_SESSION_SECOND_NOTIFICATION_ID';
export const FIRST_SESSION_THIRD_NOTIFICATION_ID = 'FIRST_SESSION_THIRD_NOTIFICATION_ID';
export const ROLL_UP_ONBOARDING_PROFILE_NOTIFICATION_ID = 'ROLL_UP_ONBOARDING_PROFILE_NOTIFICATION_ID';
export const ROLL_UP_ONBOARDING_SUBSCRIPTION_NOTIFICATION_ID = 'ROLL_UP_ONBOARDING_SUBSCRIPTION_NOTIFICATION_ID';
export const ROLL_UP_DASHBOARD_AFTER_ONBOARDING_NOTIFICATION_ID = 'ROLL_UP_DASHBOARD_AFTER_ONBOARDING_NOTIFICATION_ID';
export const FINISHED_FACE_ANALYZE_NOTIFICATION_ID = 'FINISHED_FACE_ANALYZE_NOTIFICATION_ID';
export const FINISHED_HAND_ANALYZE_NOTIFICATION_ID = 'FINISHED_HAND_ANALYZE_NOTIFICATION_ID';

export const ANDROID_DEFAULT_CHANNEL_NAME = 'Default Channel';
export const ANDROID_DEFAULT_CHANNEL_DESC = 'App default channel';

//statuses for pushesBehavior
export const BEHAVIOR_FOR_PUSHES = {
  THREE_DAYS_AGO_PURCHASE: "THREE_DAYS_AGO_PURCHASE",
  ONLY_FREE_USER: "ONLY_FREE_USER",
  ALL: "ALL"
};
