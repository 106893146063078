

import React, { useState, } from 'react';
import { Elements, ElementsConsumer, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PRBE } from './prb';
import { CE } from './card';


const MODE_ONLY_PRB = 'ONLY_PRB';
const MODE_ONLY_CARD = 'ONLY_CARD';

const PRB_MODE_CUSTOM_WITH_MARK = 'CUSTOM_WITH_MARK';
const PRB_MODE_CUSTOM_WO_MARK = 'CUSTOM_WO_MARK'; // eslint-disable-line

let sp = loadStripe(process.env.REACT_APP_BILLING_KEY);


export default function Checkout({
  productDetails,
  mode,
  prbMode,
  showPRBDetails,
  showCardSecurIcons,
  prbButton,
  cardButton,
  onChange,
  onStart,
  onSuccess,
  onError,
}) {
  const [prbCheck, setPrbCheck] = useState();

  const handlePrbCheck = (res) => {
    setPrbCheck(res);
  }

  const handleError = (err) => {
    if (err && err.message && err.message !== 'Cancel') {
      window.alert(err.message);
    }
    onError(err);
  }

  return (
    <Elements stripe={sp}>

      {mode !== MODE_ONLY_CARD ?
        <ElementsConsumer>
          {({ stripe, elements, }) => (
            <PRBE
              productDetails={productDetails}
              button={mode === MODE_ONLY_PRB && prbCheck && prbMode ? prbButton : null}
              showBrandMark={prbMode === PRB_MODE_CUSTOM_WITH_MARK}
              displayDetails={showPRBDetails}
              stripe={stripe}
              elements={elements}
              onCheck={handlePrbCheck}
              onStart={onStart}
              onSuccess={onSuccess}
              onError={handleError}
            />
          )}
        </ElementsConsumer>
        : null}

      {mode !== MODE_ONLY_PRB || prbCheck === null ?
        <CE
          productDetails={productDetails}
          showCardSecurIcons={showCardSecurIcons}
          button={cardButton}
          onChange={onChange}
          onStart={onStart}
          onSuccess={onSuccess}
          onError={handleError}
        />
        : null}

    </Elements>
  );
};
