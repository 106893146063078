import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Checkout from '../../../../web/src/checkout';

import Analytics from '../../../modules/analytics';
import Attribution from '../../../modules/attribution';
import Billing from '../../../modules/billing';

import Button from '../../../components/button';

import { setPending, setPurchased, initPurchase, } from '../../../store/purchases/actions';


class SubButton extends PureComponent {

  static getDerivedStateFromProps(props) {
    return {
      productDetails: Billing.getProductDetails(props.product),
    };
  }

  state = {
    cardComplete: false,
  };

  handleChange = (cardComplete) => {
    this.setState({
      cardComplete,
    });
  }

  handleStart = (type) => {
    const { onBillingStart, setPending, } = this.props;

    setPending(true);

    Analytics.trackEvent('stripe', 'pay_click');
    Analytics.trackEvent('stripe', 'start_' + type);

    onBillingStart();
  }

  handleSuccess = (res) => {
    const { onBillingSuccess, setPending, setPurchased, initPurchase, } = this.props;
    const { productDetails, } = this.state;

    Analytics.trackPurchaseEvent({
      customerId: _.get(res, 'customer.id'),
      subscriptionId: _.get(res, 'subscription.id'),
      productId: productDetails.productId,
      revenue: productDetails.priceValue,
      currency: productDetails.currency,
    });

    setPurchased(true);
    setPending(false);
    initPurchase();
    setTimeout(() => {
      initPurchase();
    }, 8000);

    onBillingSuccess(res);
  }

  handleError = (err) => {
    const { onBillingFail, setPending, } = this.props;
    const { productDetails, } = this.state;

    setPending(false);

    if (typeof err !== 'object') {
      err = new Error(err);
    }

    Analytics.trackEvent('ecommerce', 'error', {
      productId: productDetails.productId,
      label: err.message || '',
      ..._.mapValues(err, v => typeof v === 'object' ? undefined : String(v)),
    });

    onBillingFail(err);
  }

  render() {
    const { mode, prbMode, showPRBDetails, showCardSecurIcons, } = this.props;
    const { productDetails, } = this.state;
    return (
      <Checkout
        productDetails={productDetails}
        mode={mode}
        prbMode={prbMode}
        showPRBDetails={showPRBDetails}
        showCardSecurIcons={showCardSecurIcons}
        prbButton={(
          <Button
            style={{ padding: 4, borderRadius: 2, }}
            {...this.props}
          />
        )}
        cardButton={(
          <Button
            style={{ padding: 4, borderRadius: 2, }}
            disabled={!this.state.cardComplete}
            {...this.props}
          />
        )}
        onChange={this.handleChange}
        onStart={this.handleStart}
        onSuccess={this.handleSuccess}
        onError={this.handleError}
      />
    );
  }

}

export default connect(
  state => ({
    mode: state.remoteConfig.remoteConfigParams.webCheckoutMode,
    prbMode: state.remoteConfig.remoteConfigParams.webCheckoutPRBMode,
    showPRBDetails: state.remoteConfig.remoteConfigParams.webCheckoutShowPRBDetails,
    showCardSecurIcons: state.remoteConfig.remoteConfigParams.webCheckoutShowCardSecurIcons,
  }),
  {
    setPending,
    setPurchased,
    initPurchase,
  },
)(SubButton);
