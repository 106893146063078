import { createAction } from "redux-actions";
import _ from 'lodash';

import { getPhysiognomy, postPhysiognomy, } from "../../api/physiognomy";
import { SET_DATA, SET_LOADING } from "./types";
import { getImageLabels, GOOGLE_VISION_ERROR } from "../../api/palm-reading";
import { getObjectsFromLabels } from "../palm-reading/actions";
import { REQUIRED_KEYWORDS_FACE } from "../../constants/palm-reading";

const setPhysiognomyResult = createAction(SET_DATA);
const setLoading = createAction(SET_LOADING);

export function loadPhysiognomyData() {
  return dispatch => {
    dispatch(setLoading(true));
    return getPhysiognomy()
      .then(({ user_physiognomy }) => {
        if (_.get(user_physiognomy, 'face_path', null)) {
          dispatch(setPhysiognomyResult(user_physiognomy));
        }
      })
      .catch(console.warn)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export const loadFaceImage = (image) => {
  return (dispatch, getState) => {

    const { ignoreObjectDetection } = getState().remoteConfig.remoteConfigParams;
    if (ignoreObjectDetection) {
      return postPhysiognomy(image)
        .then(({ user_physiognomy }) => {
          if (!user_physiognomy) throw new Error();
          dispatch(setPhysiognomyResult(user_physiognomy));
        });
    } else {
      return getImageLabels(image.base64)
        .then(result => {
          if (result === GOOGLE_VISION_ERROR) {
            //show user, that he found item when we didn't receive response from server
            return;
          }

          const labels = result.responses[0].labelAnnotations,
            requiredKeywords = getObjectsFromLabels(REQUIRED_KEYWORDS_FACE, labels);

          if (requiredKeywords.length !== REQUIRED_KEYWORDS_FACE.length) {
            throw new Error();
          }

          return postPhysiognomy(image);
        })
        .then(({ user_physiognomy }) => {
          if (!user_physiognomy) throw new Error();
          dispatch(setPhysiognomyResult(user_physiognomy));
        });
    }
  };
};
