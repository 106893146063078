import {
  Dimensions,
  StatusBar,
  Platform,
} from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';

var height, width;

if (Platform.OS === 'android') {
  height = Dimensions.get('screen').height;
  width = Dimensions.get('screen').width;
} else {
  height = Dimensions.get('window').height;
  width = Dimensions.get('window').width;
}
const originalHeight = 568;
const originalWidth = 320;
const verticalRatio = height / originalHeight;
const horizontalRatio = width / originalWidth;

export const verticalScale = amount => verticalRatio * amount;
export const horizontalScale = amount => horizontalRatio * amount;

const newOriginalHeight = 667;
const newOriginalWidth = 375;
const newVerticalRatio = height / newOriginalHeight;
const newHorizontalRatio = width / newOriginalWidth;

const originalHeightX = 1792;
const originalWidthX = 828;
const verticalRatioX = height / originalHeightX;
const horizontalRatioX = width / originalWidthX;

export const newVerticalScale = (amount, amountX, amountA) => {
  if (Platform.OS === 'ios') {
    return (isIphoneX() && amountX)
      ? verticalRatioX * amountX
      : newVerticalRatio * amount
  } else {
    return (width >= 320)
      ? amountA || newHorizontalRatio * amount
      : (amountA || newHorizontalRatio * amount) * 0.80
  }
}
export const newHorizontalScale = (amount, amountX, amountA) => {
  if (Platform.OS === 'ios') {
    return (isIphoneX() && amountX)
      ? horizontalRatioX * amountX
      : newHorizontalRatio * amount
  } else {
    return (width >= 320)
      ? amountA || newHorizontalRatio * amount
      : (amountA || newHorizontalRatio * amount) * 0.80
  }
}

export const textSubscription = (amount) => {
  return Math.min(newVerticalScale(amount), newHorizontalScale(amount));
};

export const newTextScale = (amount, maxCountLetters, countLetters, letterSpacing) => {
  const size = Math.min(amount, ((maxCountLetters * amount) - (letterSpacing * countLetters)) / countLetters);
  return textSubscription(size);
};
