import React, { PureComponent } from 'react';
import { createAnimatableComponent } from 'react-native-animatable';
import { View } from 'react-native';
import PropTypes from "prop-types";

const ButtonAnimatedWrapper = createAnimatableComponent(View);
const buttonAnimationSchema = {
  0: {
    scale: 1,
  },
  0.1: {
    scale: 0.9,
  },
  0.2: {
    scale: 1,
  },
  0.3: {
    scale: 0.9,
  },
  0.4: {
    scale: 1,
  },
  1: {
    scale: 1,
  }
};

export default class PulseAnimationButtonWrapper extends PureComponent {
  static propTypes = {
    style: PropTypes.any,
    children: PropTypes.node.isRequired,
    onAnimationEnd: PropTypes.func,
    onAnimationBegin: PropTypes.func,
    iterationCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    duration: PropTypes.number.isRequired,
  };

  static defaultProps = {
    duration: 3500,
    iterationCount: 'infinite',
  };

  handleAnimationEnd = () => {
    if (typeof this.props.onAnimationEnd === 'function') {
      this.props.onAnimationEnd();
    }
  };

  handleAnimationBegin = () => {
    if (typeof this.props.onAnimationBegin === 'function') {
      this.props.onAnimationBegin();
    }
  };

  render() {
    const { children, style, iterationCount, duration } = this.props;

    return (
      <ButtonAnimatedWrapper
        style={style}
        iterationCount={iterationCount}
        animation={buttonAnimationSchema}
        duration={duration}
        easing={'ease-out'}
        onAnimationEnd={this.handleAnimationEnd}
        onAnimationBegin={this.handleAnimationBegin}
      >
        {children}
      </ButtonAnimatedWrapper>
    )
  }
}
