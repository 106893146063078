import React, { PureComponent } from 'react';
import { StyleSheet, View, StatusBar, ImageBackground, Platform } from 'react-native';
import { ifIphoneX, isIphoneX } from 'react-native-iphone-x-helper';

import KeyboardShift from '../../../utils/keyboard-shift';
import Text from '../../../components/text';
import * as COLORS from '../../../constants/colors';
import { verticalScale, horizontalScale } from '../../../utils/scale';

const progressBarArray = Array.apply(null, { length: 4 });

export default class OnboardingScreenLayout extends PureComponent {
  render() {
    const {
      bgImage,
      sectionNumber,
      title,
      text,
      interactiveElement,
      isKeyboard,
      specialPadding
    } = this.props;

    return (
      <KeyboardShift>
        <View style={styles.container}>
          <ImageBackground source={bgImage} style={styles.bgImage} />
          <StatusBar barStyle='light-content' />
          {this.getProgressBarr(sectionNumber)}
          <View style={styles.contentContainer}>
            <View style={isKeyboard
              ? styles.titleWrapperWithKeyboard
              : styles.titleWrapper
            }>
              <Text style={styles.title}>{title}</Text>
            </View>
            {
              text
                ? <Text style={styles.text}>{text}</Text>
                : null
            }
          </View>
          <View style={specialPadding
            ? styles.interactiveElementContainerSpecialPadding
            : styles.interactiveElementContainer
          }>
            {interactiveElement}
          </View>
        </View>
      </KeyboardShift>
    )
  }

  getProgressBarr(currentSection) {
    return (
      <View style={styles.progressBar}>
        {
          progressBarArray.map((item, index) => (
            <View
              key={'index/' + index}
              style={[
                styles.progressBarSection,
                index < currentSection
                  ? styles.progressBarSectionCompleted
                  : styles.progressBarSectionUncompleted
              ]}></View>
          ))
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  bgImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  progressBar: {
    ...ifIphoneX({
      marginTop: verticalScale(38),
    }, {
      marginTop: verticalScale(Platform.OS === 'android' ? 5 : 25),
    }),
    width: '100%',
    height: 2,
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  progressBarSection: {
    width: '24%',
    height: '100%',
  },
  progressBarSectionCompleted: {
    backgroundColor: COLORS.SELECTION_COMPLETED,
  },
  progressBarSectionUncompleted: {
    backgroundColor: COLORS.SELECTION_UNCOMPLETED,
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    paddingHorizontal: horizontalScale(20),
  },
  titleWrapper: {
    position: 'relative',
    height: verticalScale(145),
  },
  titleWrapperWithKeyboard: {
    position: 'relative',
    height: verticalScale(80),
  },
  title: {
    position: 'absolute',
    bottom: 0,
    color: COLORS.WHITE,
    fontSize: horizontalScale(28),
    letterSpacing: horizontalScale(-0.97),
    fontWeight: '600',
  },
  text: {
    marginTop: verticalScale(15),
    color: COLORS.WHITE,
    fontSize: horizontalScale(16),
  },
  interactiveElementContainer: {
    marginBottom: 0,
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingHorizontal: horizontalScale(20),
  },
  interactiveElementContainerSpecialPadding: {
    marginBottom: 0,
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  }
});
