import queryString from 'query-string';
import DeviceProps from '@magnus/react-native-device-props';

import { version } from '../../../package.json';

import Analytics from '../analytics';

import Adjust from './adjust';


const STORAGE_KEY_SOURCE = 'ATTRIBUTION_SOURCE';
let IS_ORGANIC = null;


export default {

  init() {
    let p = queryString.parse(window.location.search);

    if (p.utm_source) {
      Analytics.setUserProperty('utm_source', p.utm_source);
      Analytics.setUserProperty('utm_medium', p.utm_medium);
      Analytics.setUserProperty('utm_campaign', p.utm_campaign);
      Analytics.setUserProperty('utm_adset', p.utm_adset);
      Analytics.setUserProperty('utm_creative', p.utm_creative);
      Analytics.setUserProperty('utm_content', p.utm_content);
      Analytics.setUserProperty('utm_term', p.utm_term);

      IS_ORGANIC = false;

      localStorage.setItem(STORAGE_KEY_SOURCE, p.utm_source);
    }
    else {
      IS_ORGANIC = !localStorage.getItem(STORAGE_KEY_SOURCE);
    }

    if (IS_ORGANIC) {
      Analytics.setUserProperty('utm_source', '');
    }


    return Promise.all([
      DeviceProps.getIDFM(),
      DeviceProps.fetch(),
    ])
      .then(([idfm, dp]) => {
        var _adjust = new Adjust({
          app_token: process.env.REACT_APP_ADJUST_APP_TOKEN,
          app_version: version,
          environment: process.env.REACT_APP_ADJUST_ENV,
          os_name: (dp.os_name || '').toLowerCase(),
          device_ids: {
            idfa: idfm,
            idfm,
          }
        });

        _adjust.trackSession();
      });
  },

  isOrganic() {
    return IS_ORGANIC;
  },

}
