import { Platform } from 'react-native';

export const DO_NOT_REMEMBER = 2400;

export const BUNDLE_ID =
  Platform.OS === 'web'
    ? 'astroline.today'
    : 'com.horoastroline';

export const IOS_APP_ID = '1448364002';

export const ADJUST_KEY = 'ofxzosfje51c';
export const ADJUST_EVENTS = {
  in_app_success: '7y9j87',
  subscription_success: 'to2h8r',
  view_product: 'j3laof',
};
export const ADJUST_APP_SECRET = [1, 1907290710, 1589806559, 160085813, 1885519117];

export const MAGNUS_TOKEN = Platform.select({
  android: '2eISKSqhy7O0plu2uRULnF9OJkKvVRHD',
  ios: 'hUfy4xNqbXL82C9No8viz1FzgYoJyxx9',
});

export const GOOGLE_CLOUD_API_KEY = 'AIzaSyBUsLvdrbGeXYZRysM_AAlLK-c0R4-oVts';

export const TITLE = 'Astroline';

export const SUPPORT_EMAIL =
  Platform.OS === 'web'
    ? 'support@astroline.today'
    : 'support@adwebmart.com';
export const SUPPORT_TOPIC = '[Astroline] Support';

export const POLICY_LINK = 'https://wmgroup.b-cdn.net/WMGroup%20Policy.html';
export const TERMS_LINK = 'https://wmgroup.b-cdn.net/Astroline%20Terms.html';
