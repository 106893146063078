import {
  SET_REWARDED_LOADED,
  SET_REWARDED_ACCESSIBLE,
  SET_REWARDED_PENDING,
  SET_INTERSTITIAL_LOADED,
  SET_INTERSTITIAL_PENDING,
  SET_INTERSTITIAL_TIMEOUT,
  SET_LAST_TYPE_ADS,
} from './types';

import Ads from '../../modules/ads';
import { INTER, REWARDED } from '../../constants/ads';
import { setUnlockBlock } from '../unlock-content/actions';

export function initAds() {
  return function (dispatch) {
    dispatch(changeAdsType());
    dispatch(loadAds());
  };
}

export function changeAdsType() {
  return function (dispatch, getState) {
    const {
      ads: { lastTypeAds },
      remoteConfig: { remoteConfigParams },
    } = getState(),
      { typesOfAds, adUnlockContent, isAds } = remoteConfigParams,
      indexOfRewarded = ~typesOfAds.indexOf(REWARDED) ? adUnlockContent.indexOf(REWARDED) : -1,
      indexOfInter = ~typesOfAds.indexOf(INTER) ? adUnlockContent.indexOf(INTER) : -1;

    let type = '';

    if (isAds) {
      if (lastTypeAds) {
        if (
          (lastTypeAds === INTER && indexOfRewarded) ||
          (lastTypeAds === REWARDED && !~indexOfInter)
        ) {
          type = REWARDED;
        } else {
          type = INTER;
        }
      } else if (adUnlockContent.length && typesOfAds.length) {
        if (~indexOfRewarded || ~indexOfInter) {
          type = [REWARDED, INTER].reduce((acc, cur) => {
            let curIndex = adUnlockContent.indexOf(cur),
              accIndex = adUnlockContent.indexOf(acc);
            return (~curIndex && !acc) ||
              (~curIndex && acc && curIndex < accIndex)
              ? cur
              : acc;
          }, '');
        }
      }
    }

    dispatch(setLastTypeAds(type));
  };
}

export function loadAds() {
  return function (dispatch, getState) {
    const {
      remoteConfig: { remoteConfigParams: { isAds, typesOfAds } },
      purchases: { purchased },
    } = getState();
    if (!purchased && isAds) {
      if (typesOfAds.includes(REWARDED)) {
        dispatch(loadRewarded());
      }
      if (typesOfAds.includes(INTER)) {
        dispatch(loadInterstitial());
      }
    }
  };
}

export function loadRewarded() {
  return function (dispatch, getState) {
    const {
      ads: { rewardedLoaded, rewardedAccessible, rewardedPending },
    } = getState();
    if (!rewardedLoaded && rewardedAccessible && !rewardedPending) {
      dispatch(setRewardedPending(true));
      return Ads.loadRewarded()
        .then(() => {
          dispatch(setRewardedLoaded(true));
          dispatch(setRewardedPending(false));
        })
        .catch(noFill => {
          if (noFill) {
            dispatch(setRewardedAccessible(false));
            dispatch(setRewardedPending(false));
          } else {
            setTimeout(() => {
              dispatch(setRewardedPending(false));
              dispatch(loadRewarded());
            }, 3000);
          }
        });
    } else {
      return Promise.reject();
    }
  };
}

export function showRewarded() {
  return function (dispatch, getState) {
    const {
      ads: { rewardedLoaded, rewardedPending },
    } = getState();

    function showAd() {
      return Ads.showRewarded()
        .then(() => {
          dispatch(setRewardedLoaded(false));
          dispatch(loadRewarded());
        })
        .catch(err => {
          dispatch(setRewardedLoaded(false));
          dispatch(loadRewarded());
          throw err;
        });
    }

    if (Ads.isLoadedRewarded() && rewardedLoaded && !rewardedPending) {
      return showAd();
    } else {
      return dispatch(loadRewarded()).finally(() => showAd());
    }
  };
}

export function loadInterstitial() {
  return function (dispatch, getState) {
    const {
      ads: { interstitialLoaded, interstitialPending },
    } = getState();
    if (!interstitialLoaded && !interstitialPending) {
      dispatch(setInterstitialPending(true));
      return Ads.loadInterstitial()
        .then(() => {
          dispatch(setInterstitialLoaded(true));
          dispatch(setInterstitialPending(false));
        })
        .catch(err => {
          dispatch(setInterstitialPending(false));
          throw err;
        });
    } else {
      return Promise.reject();
    }
  };
}

export function showInterstitial() {
  return function (dispatch, getState) {
    const {
      ads: { interstitialLoaded, interstitialPending },
    } = getState();

    function showAd() {
      return Ads.showInterstitial()
        .then(() => {
          dispatch(setInterstitialLoaded(false));
          dispatch(loadInterstitial());
        })
        .catch(err => {
          dispatch(setInterstitialLoaded(false));
          dispatch(loadInterstitial());
          throw err;
        });
    }

    if (Ads.isLoadedInterstitial() && interstitialLoaded && !interstitialPending) {
      return showAd();
    } else {
      return dispatch(loadInterstitial()).finally(() => showAd());
    }
  };
}

export function showUnlockAd(onSuccess, type, period) {
  return function (dispatch, getState) {
    const { ads } = getState(),
      { lastTypeAds } = ads;

    if (lastTypeAds) {
      const showFunc = lastTypeAds === INTER ? showInterstitial : showRewarded;
      dispatch(showFunc())
        .then(() => {
          dispatch(setUnlockBlock(type, period));
          if (onSuccess) onSuccess();
        })
        .finally(() => {
          dispatch(changeAdsType());
        });
    }
  };
}

export function showInterAfterNav(onSuccess) {
  return function (dispatch, getState) {
    const { ads, purchases, remoteConfig } = getState();
    const { interTimeout, typesOfAds, isAds } = remoteConfig.remoteConfigParams;

    if (
      isAds &&
      ~typesOfAds.indexOf(INTER) &&
      !purchases.purchased &&
      ads.interstitialTimeoutUntil <= Date.now()
    ) {
      dispatch(showInterstitial()).finally(() => {
        dispatch(setInterstitialTimeout(Date.now() + (interTimeout || 0) * 1000));
        return onSuccess();
      });
    } else {
      return onSuccess();
    }
  };
}

function setRewardedLoaded(status) {
  return {
    type: SET_REWARDED_LOADED,
    status,
  };
}

function setRewardedAccessible(status) {
  return {
    type: SET_REWARDED_ACCESSIBLE,
    status,
  };
}

function setRewardedPending(pending) {
  return {
    type: SET_REWARDED_PENDING,
    pending,
  };
}

function setInterstitialLoaded(status) {
  return {
    type: SET_INTERSTITIAL_LOADED,
    status,
  };
}

function setInterstitialPending(pending) {
  return {
    type: SET_INTERSTITIAL_PENDING,
    pending,
  };
}

export function setLastTypeAds(typeAds) {
  return {
    type: SET_LAST_TYPE_ADS,
    typeAds,
  };
}

function setInterstitialTimeout(interTimeout) {
  return {
    type: SET_INTERSTITIAL_TIMEOUT,
    interTimeout,
  };
}
