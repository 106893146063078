import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, StyleSheet, Platform } from 'react-native';

import Keyboard from '../utils/keyboard';

const keyboardLifecycle = Platform.select({
  android: 'Did',
  ios: 'Will',
  web: 'Will',
});

export default class FullScrollView extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isScrollToEnd: PropTypes.bool,
    scrollToObject: PropTypes.object,
    onScrollBeginDrag: PropTypes.func,
    isKeyboard: PropTypes.bool,
  };

  static defaultProps = {
    isScrollToEnd: false,
    scrollToObject: null,
  };

  constructor(props) {
    super(props);

    this.listRef = React.createRef();

    this.state = {
      height: 0,
      heightScrollView: 0,
      isOpenKeyboard: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.listRef.current) {
      if (this.props.scrollToObject && prevProps.scrollToObject !== this.props.scrollToObject) {
        this.listRef.current?.scrollTo(this.props.scrollToObject);
      } else if (
        (this.state.heightScrollView && this.props.isScrollToEnd && prevState.heightScrollView !== this.state.heightScrollView)
        || prevState.isOpenKeyboard === !this.state.isOpenKeyboard) {
        this.listRef.current?.scrollToEnd({ animated: true });
      }
    }
  }

  componentDidMount() {
    if (this.props.isKeyboard) {
      this.keyboardDidShowSub = Keyboard.addListener(`keyboard${keyboardLifecycle}Show`, this.openKeyboard);
      this.keyboardDidHideSub = Keyboard.addListener(`keyboard${keyboardLifecycle}Hide`, this.closeKeyboard);
    }
  }

  componentWillUnmount() {
    if (this.props.isKeyboard) {
      this.keyboardDidShowSub.remove();
      this.keyboardDidHideSub.remove();
    }
  }

  openKeyboard = () => {
    this.setState({ isOpenKeyboard: true })
  }

  closeKeyboard = () => {
    this.setState({ isOpenKeyboard: false })
  }

  onContentSizeChange = (contentWidth, contentHeight) => {
    this.setState({ heightScrollView: contentHeight })
  }

  handleScrollBeginDrag = (params) => {
    const { onScrollBeginDrag } = this.props;

    if (typeof onScrollBeginDrag === 'function') {
      onScrollBeginDrag(params);
    }
  };

  render() {
    return (
      <View style={styles.root} onLayout={this.handleLayout} shouldRasterizeIOS={true}>
        <ScrollView
          ref={this.listRef}
          keyboardShouldPersistTaps="always"
          onContentSizeChange={this.onContentSizeChange}
          onScrollBeginDrag={this.handleScrollBeginDrag}
          contentContainerStyle={{ minHeight: this.state.height || '100%' }}>
          {this.props.children}
        </ScrollView>
      </View>
    );
  }

  handleLayout = e => {
    if (e.nativeEvent && e.nativeEvent.layout && e.nativeEvent.layout.width) {
      this.setState({
        height: e.nativeEvent.layout.height,
      });
    }
  };
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
