import {
  DISABLE_FOR_SESSION_BACK_CONTENT,
  DISABLE_FOR_SESSION_ON_DASHBOARD,
  DISABLE_FOREVER,
  DISABLE_ON_DASHBOARD_LEAVE,
} from './types';

const initialState = {
  disabledForSessionBackContent: false,
  disabledForSessionAfterDashboard: false,
  disabledForever: false,
  disabledOnDashboardLeave: false,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case DISABLE_FOR_SESSION_BACK_CONTENT:
      state = {
        ...state,
        disabledForSessionBackContent: true,
      };
      break;

    case DISABLE_FOR_SESSION_ON_DASHBOARD:
      state = {
        ...state,
        disabledForSessionOnDashboard: true,
      };
      break;

    case DISABLE_ON_DASHBOARD_LEAVE:
      state = {
        ...state,
        disabledOnDashboardLeave: true,
      };
      break;

    case DISABLE_FOREVER:
      state = {
        ...state,
        disabledForever: true,
      };
      break;
  }

  return state;
}
