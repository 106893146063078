import React, { Component } from 'react';
import { Dimensions, StyleSheet, View, Platform, } from 'react-native';
import PropTypes from 'prop-types';

import Keyboard from './keyboard';

export default class KeyboardShift extends Component {
  static propTypes = {
    listenerWhen: PropTypes.oneOf(['Did', 'Will',]),
  };

  static defaultProps = {
    listenerWhen: 'Did'
  };

  state = {
    h: '100%',
  };

  componentDidMount() {
    const { listenerWhen } = this.props;

    if (listenerWhen === 'Did') {
      this.keyboardDidShowSub = Keyboard.addListener('keyboardDidShow', this.handleKeyboardDidShow);
      this.keyboardDidHideSub = Keyboard.addListener('keyboardDidHide', this.handleKeyboardDidHide);
    }
    else {
      this.keyboardDidShowSub = Keyboard.addListener('keyboardWillShow', this.handleKeyboardDidShow);
      this.keyboardDidHideSub = Keyboard.addListener('keyboardWillHide', this.handleKeyboardDidHide);
    }
  }

  componentWillUnmount() {
    if (Platform.OS === 'web') {
      let inputs = document.querySelectorAll('input');
      for (let i of inputs) {
        i.removeEventListener('focus', this.handleKeyboardDidShow);
        i.removeEventListener('blur', this.handleKeyboardDidHide);
      }
    }
    else {
      this.keyboardDidShowSub.remove();
      this.keyboardDidHideSub.remove();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <View style={[styles.container, { height: this.state.h }]}>
        {children}
      </View>
    );
  }

  handleKeyboardDidShow = (event) => {
    if (Platform.OS === 'web') {
      setTimeout(() => {
        this.setState({ h: window.innerHeight, });
        window.scrollTo(0, 0)
      }, 256);
    }
    else {
      const { height: windowHeight } = Dimensions.get('window');
      const keyboardHeight = event.endCoordinates.height;
      const gap = windowHeight - keyboardHeight;
      this.setState({ h: gap });
    }
  };

  handleKeyboardDidHide = () => {
    this.setState({ h: '100%' });
  };
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  }
});
