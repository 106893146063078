export default function process({ routeName, params, route }) {
  return function() {
    const stack = [];
    const resultStack = [];

    function iterateRoutes(routes) {
      if (routes.routeName) {
        stack.push({
          routeName: routes.routeName,
          params: routes.params,
        });
      }

      if (routes.hasOwnProperty('route') && typeof routes.route === 'object') {
        iterateRoutes(routes.route);
      }
    }

    iterateRoutes({ routeName, params, route });

    for (let i = 0; i < stack.length; i++) {

        resultStack.push(stack[i]);

    }

    return Promise.resolve(resultStack);
  };
}
