import api from "./index";

const FACE_READING = "/image-recognition/physiognomy";

export function getPhysiognomy() {
  return api.fetch(FACE_READING,
    {
      method: 'GET',
    });
}

export const postPhysiognomy = (image) => {
  const form = new FormData();
  form.append("image_file",
    image.blob
      ? new File([image.blob], `face-gg.jpg`)
      : {
        uri: image.uri,
        type: "image/jpg",
        name: `face-gg.jpg`,
      }
  );

  return api.fetch(
    FACE_READING,
    {
      method: "POST",
      headers: image.blob ? {} : { "Content-Type": "multipart/form-data", },
      data: form,
    },
    true,
  );
};
