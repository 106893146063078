import * as COLORS from './colors';
import * as ROUTES from './routes';
import * as ICONS from './icons';

export const TRY_PREMIUM_BANNER = 'try_premium_banner';
export const UNLIMITED_BANNER = 'unlimited_banner';

export const PREMIUM_BANNERS = {
  [TRY_PREMIUM_BANNER]: {
    id: TRY_PREMIUM_BANNER,
    colorsGradient: COLORS.PREMIUM_BANNER_GRADIENT_PINK,
    icon: ICONS.TRY_PREMIUM,
    text: 'PREMIUM_BANNERS.TRY_PREMIUM',
    routeName: ROUTES.ONBOARDING_SUB_LANDING,
  },
  [UNLIMITED_BANNER]: {
    id: UNLIMITED_BANNER,
    colorsGradient: COLORS.PREMIUM_BANNER_GRADIENT_GREEN,
    icon: ICONS.UNLIMITED,
    text: 'PREMIUM_BANNERS.UNLIMITED',
    routeName: ROUTES.ADVANTAGES,
  },
};
