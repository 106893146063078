import React, { PureComponent } from 'react';
import DateTimePicker from "./custom-datetime-picker";
import PropTypes from "prop-types";
import { StyleSheet, View, Platform } from "react-native";
import * as COLORS from "../../constants/colors";


export default class MyDatePicker extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    mode: PropTypes.string,
    theme: PropTypes.oneOf(['default', 'chat']),
    setValue: PropTypes.func,
    visibleValues: PropTypes.number,
    setWasDrag: PropTypes.func,
  };

  static defaultProps = {
    mode: 'date',
    theme: 'default',
    visibleValues: 3,
  };

  render() {
    const { height, mode, theme, setValue, visibleValues, selectDate, selectTime, setWasDrag } = this.props;
    const unactivePartHeight = height * (Math.ceil(visibleValues / 2)) / visibleValues;
    return (
      <View style={[
        styles.rootAndroid,
        {
          height: height + 5,
          elevation: (theme === 'default' && Platform.OS === "android") ? 0 : 5,
        }
      ]}>
        <View style={[styles.root, { height: height }]}>
          <View style={styles.container}>
            <DateTimePicker
              setWasDrag={setWasDrag}
              mode={mode}
              theme={theme}
              setValue={setValue}
              visibleValues={visibleValues}
              height={height} 
              selectDate={selectDate}
              selectTime={selectTime}/>
            <View style={[styles.unactiveRow, { bottom: unactivePartHeight, },]} pointerEvents="none" />
            <View style={[styles.unactiveRow, { top: unactivePartHeight, }]} pointerEvents="none" />
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  rootAndroid: {
    borderRadius: 18,
    marginHorizontal: 45,
  },
  root: {
    margin: 5,
    borderRadius: 18,
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowColor: COLORS.BLACK,
    shadowOffset: { height: 2, width: 0, },
  },
  container: {
    marginBottom: 20,
    borderRadius: 18,
    overflow: 'hidden',
    position: 'relative',
  },
  unactiveRow: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: COLORS.UNACTIVE_ROW,
  },
});
