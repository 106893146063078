import React, { Suspense, } from 'react';
import { StyleSheet, } from 'react-native';

import LoadingComponent from '../../components/loading-component';

function hoc(AsyncCpm) {
  let LF = React.lazy(AsyncCpm);
  let F = ({ ...props }) => (
    <Suspense fallback={
      <LoadingComponent style={styles.spinner} size={'large'} />
    }>
      <LF {...props} />
    </Suspense>
  );
  return F;
}

const styles = StyleSheet.create({
  spinner: {
    height: '100%',
  },
});


export { default as Loading } from '../loading';

export { default as OnboardingSubscriptionFirst } from '../onboarding/onboarding-classic/subscription-first';
export { default as OnboardingSubscriptionSecond } from '../onboarding/onboarding-classic/subscription-second';
export { default as OnboardingPersonalSubscription } from '../onboarding/onboarding-classic/personal-subscription';

export { default as OnboardingName } from '../onboarding/onboarding-classic/name';
export { default as OnboardingGender } from '../onboarding/onboarding-classic/gender';
export { default as OnboardingRelationship } from '../onboarding/onboarding-classic/relationship';
export { default as OnboardingDate } from '../onboarding/onboarding-classic/date';

export const OnboardingLoader = hoc(() => import('../onboarding/onboarding-classic/loading'));
export const OnboardingTakeAPhoto = hoc(() => import('../onboarding/onboarding-classic/take-a-photo'));
export const OnboardingTime = hoc(() => import('../onboarding/onboarding-classic/time'));
export const OnboardingExtraSubscription = hoc(() => import('../onboarding/onboarding-classic/extra-subscription'));
export const OnboardingLandingSubscription = hoc(() => import('../onboarding/onboarding-classic/subscription-landing'));

export const Auth = hoc(() => import('../auth'));

export const PalmPhoto = hoc(() => import('../camera-screen'));
export const Dashboard = hoc(() => import('../dashboard'));
export const Horoscopes = hoc(() => import('../horoscopes'));
export const MagicBall = hoc(() => import('../magic-ball'));
export const Biorithm = hoc(() => import('../biorithm'));
export const PalmReadingScreen = hoc(() => import('../palm-reading'));
export const ErrorPhotoScreen = hoc(() => import('../camera-screen/error-page'));
export const Tarot = hoc(() => import('../tarot'));
export const Physiognomy = hoc(() => import('../physiognomy'));
export const Compatibility = hoc(() => import('../compatibility'));
export const CompatibilityResult = hoc(() => import('../compatibility/result'));

export const Advantages = hoc(() => import('../advantages'));
export const HoroscopeView = hoc(() => import('../horoscope-view'));
export const Settings = hoc(() => import('../settings'));
export const ProfileSettings = hoc(() => import('../settings/profile-settings'));
export const NameSettings = hoc(() => import('../settings/name'));
export const GenderSettings = hoc(() => import('../settings/gender'));
export const RelationshipSettings = hoc(() => import('../settings/relationship'));
export const DateSettings = hoc(() => import('../settings/date'));
export const TimeSettings = hoc(() => import('../settings/time'));
export const PrivacyPolicy = hoc(() => import('../settings/privacy-policy'));
export const Terms = hoc(() => import('../settings/terms'));
export const LanguageSettings = hoc(() => import('../settings/languages'));
export const CancelSubscription = hoc(() => import('../settings/cancel-subscription'));
export const SubscriptionAd = hoc(() => import('../subcsription/subscription-ad'));
export const SubscriptionThird = hoc(() => import('../subcsription/subscription-third'));
export const PurchaseSuccess = hoc(() => import('../subcsription/purchase-success'));
export const Divinations = hoc(() => import('../divinations'));

export const Dev = hoc(() => import('../dev'));
