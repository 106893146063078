import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View, } from 'react-native';

import Touchable from '../../../../components/touchable';
import LinearGradient from 'react-native-linear-gradient';
import * as COLORS from '../../../../constants/colors';
import Text from '../../../../components/text';
import AnimationWrapForButton from '../../../../components/animation-wrap-for-button';
import ButtonSpinner from '../../../../components/button-spinner';
import * as ICONS from '../../../../constants/icons';

const gradientStart = { x: 0, y: 0 },
  gradientEnd = { x: 1, y: 0 };

export default class Button extends PureComponent {

  static propTypes = {
    onPress: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
  };

  handlePress = () => {
    const { onPress, relationship } = this.props;
    onPress(relationship);
  };

  render() {
    const {
      relationship,
      title,
      isLoading,
      isUnselect
    } = this.props;

    return <AnimationWrapForButton>
      <Touchable
        style={styles.buttonContainer}
        onPress={isLoading ? undefined : this.handlePress}
        vibration
      >
        <LinearGradient
          start={gradientStart}
          end={gradientEnd}
          colors={isLoading
            ? COLORS.GRADIENT_COLORS_DISABLE_SELECT
            : isUnselect
              ? COLORS.GRADIENT_COLORS_UNSELECT
              : COLORS.GRADIENT_COLORS_BUTTON_ONB_SEND}
          style={styles.gradient}
        >
          <View style={styles.titleCt}>
            <Image
              source={
                isLoading
                  ? ICONS[`REL_${relationship}_DISABLED`]
                  : isUnselect
                    ? ICONS[`REL_${relationship}_UNSELECTED`]
                    : ICONS[`REL_${relationship}_ACTIVE`]
              }
              style={{ width: 50, height: 45, }}
            />
            <Text
              fontWeight={'semibold'}
              style={[
                styles.textStyle,
                {
                  color: isLoading
                    ? COLORS.PURPLE_GREY
                    : isUnselect
                      ? COLORS.VERY_LIGHT_PURPLE
                      : COLORS.WHITE
                }
              ]}>
              {title}
            </Text>
          </View>
        </LinearGradient>
        {isLoading ? <ButtonSpinner /> : null}
      </Touchable>
    </AnimationWrapForButton>;
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowOpacity: 0.3,
    shadowRadius: 5,
    shadowColor: COLORS.BLACK,
    shadowOffset: { height: 2, width: 0, },
    backgroundColor: COLORS.BLACK,
    elevation: 5,
    borderRadius: 5,
  },
  gradient: {
    borderRadius: 5,
    width: '100%',
  },
  titleCt: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 65,
  },
  textStyle: {
    fontSize: 16,
    marginLeft: 5,
    flexBasis: '50%',
  },
});
