import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import { navigate } from '../../../store/navigation/actions';
import { addToOnboardingProfile } from "../../../store/onboarding/actions";
import OnboardingScreenLayout from './screen-layout';
import LoveSelect from '../components/love-select';
import * as IMAGES from '../../../constants/images';
import * as ROUTES from '../../../constants/routes';
import i18n from '../../../localization';
import { verticalScale } from '../../../utils/scale';
import Analytics from '../../../modules/analytics';

class OnboardingRelationship extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    navigate: PropTypes.func,
    addToOnboardingProfile: PropTypes.func,
  };

  componentDidMount() {
    Analytics.trackEvent('Onboarding_Relationships', 'Open');
  }

  render() {
    return (
      <OnboardingScreenLayout
        bgImage={IMAGES.BG_ONBOARDING_RELATIONSHIP}
        sectionNumber={3}
        title={i18n.t('ONBOARDING.RELATIONSHIP.TITLE')}
        text={i18n.t('ONBOARDING.RELATIONSHIP.TEXT')}
        interactiveElement={this.renderLoveSelect()}
        specialPadding
      />
    );
  }

  renderLoveSelect = () => {
    return (
      <View style={styles.loveSelectWrapper}>
        <LoveSelect onPress={this.onButtonPress} />
      </View>
    );
  };

  onButtonPress = (relationshipUpdate) => {
    Analytics.trackEvent('Onboarding_Relationships', 'Click', { 'RelationType': relationshipUpdate });
    this.props.addToOnboardingProfile({ relationship: relationshipUpdate });
    this.props.navigate(ROUTES.ONBOARDING_DATE);
  };
}

export default connect(
  state => ({
  }), {
  navigate,
  addToOnboardingProfile,
}
)(OnboardingRelationship);

const styles = StyleSheet.create({
  loveSelectWrapper: {
    marginBottom: verticalScale(35),
  }
});
