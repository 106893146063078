export const YOUR_DAY = 'YOUR_DAY';
export const LOVE = 'LOVE';
export const HEALTH = 'HEALTH';
export const CAREER = 'CAREER';
export const MARRIAGE = 'MARRIAGE';

export const [MONEY, NEAR_FUTURE] = ["MONEY", "NEAR_FUTURE"];

export const [OVERVIEW, RECOMMENDATION, WARNING] = ['OVERVIEW', 'ADVICE', 'WARNING'];

export const [FOREHEAD, EYEBROWS, EYES, NOSE, MOUTH, JAW] = ["FOREHEAD", "EYEBROWS", "EYES", "NOSE", "MOUTH", "JAW"];

export const [HOROSCOPES, INDIAN_MOON, INDIAN_SUN, DRUID, MAYAN, CHINESE, ZODIAC] = ["horoscopes", "indian_moon", "indian_sun", "druid", "mayan", "chinese", "zodiac"];

export const [
  TAROT,
  PALMISTRY,
  PHYSIOGNOMY,
  MAGIC_BALL,
  BIORITHM,
  COMPATIBILITY
] = [
  "tarot",
  "palmistry",
  "physiognomy",
  "magic_ball",
  "biorithm",
  "compatibility",
];

export const SIGNS_ZODIAC = [
  'ARIES', 'TAURUS', 'GEMINI', 'CANCER',
  'LEO', 'VIRGO', 'LIBRA', 'SCORPIO',
  'SAGITTARIUS', 'CAPRICORN', 'AQUARIUS', 'PISCES',
];

export const [FREE, PAY, ADS] = [`FREE_SELECTION`, `PAY_SELECTION`, `ADS_SELECTION`];
