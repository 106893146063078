import React, {PureComponent,} from 'react';
import PropTypes from 'prop-types';
import {Text as RNText, Platform, StyleSheet,} from 'react-native';

const isAndroid = Platform.OS === 'android';

export default class Text extends PureComponent {

    static propTypes = {
        font: PropTypes.oneOf(['openSans', 'SFProDisplay']),
        fontWeight: PropTypes.oneOf(['regular', 'bold', 'light', 'semibold']),
        fontWeightImportant: PropTypes.bool,
    };

    static defaultProps = {
        font: 'openSans',
        fontWeight: 'regular',
        fontWeightImportant: false,
    };

    render() {
        const {style, color, font, fontWeight, fontWeightImportant, ...others} = this.props;
        let fontOptions = {};

        switch (fontWeight) {
            case 'bold':
                fontOptions.fontWeight = !isAndroid || fontWeightImportant ? '700' : null;
                fontOptions.fontFamily = font === 'SFProDisplay'
                  ? 'SFProDisplay-Bold'
                  : (isAndroid ? 'OpenSans-Bold' : 'Open Sans');
                break;

            case 'semibold':
                fontOptions.fontWeight = !isAndroid || fontWeightImportant ? '600' : null;
                fontOptions.fontFamily = font === 'SFProDisplay'
                  ? 'SFProDisplay-Semibold'
                  : (isAndroid ? 'OpenSans-SemiBold' : 'Open Sans');
                break;

            case 'regular':
                fontOptions.fontWeight = !isAndroid || fontWeightImportant ? '400' : null;
                fontOptions.fontFamily = font === 'SFProDisplay'
                  ? 'SFProDisplay-Regular'
                  : 'Open Sans';
                break;

            case 'light':
                fontOptions.fontFamily = (isAndroid ? 'OpenSans-Light' : 'Open Sans');
                break;
        }

        const s = StyleSheet.flatten([{
            backgroundColor: 'transparent',
            color,
        }, fontOptions, style,]);


        return (
            <RNText style={s} {...others} />
        );
    }
}
