

import React, { useState, } from 'react';
import { CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import DeviceProps from '@magnus/react-native-device-props';
import UserProps from '@magnus/react-native-user-props';

import { initSetupIntent, createCustomer, } from '../../../src/api/purchases';

import securedPayments from './secured-payments.png';


export const CE = ({ productDetails, showCardSecurIcons, button, onChange, onStart, onSuccess, onError, }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [complete, setComplete] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    onStart('card');

    try {
      const pm = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (pm.error) {
        onError(pm.error);
      }
      else {
        const si = await initSetupIntent();

        const cs = await stripe.confirmCardSetup(
          si.client_secret,
          {
            payment_method: pm.paymentMethod.id,
          }
        );

        if (cs.error) {
          onError(cs.error);
        }
        else {
          let idfm = await DeviceProps.getIDFM();
          let dp = await DeviceProps.fetch();
          let up = await UserProps.fetch();

          let res = await createCustomer({
            paymentMethodId: pm.paymentMethod.id,
            plan: productDetails.productId,
            metadata: {
              idfm,
              ...up,
              ...dp,
            },
          });

          onSuccess(res);
        }
      }
    }
    catch (ex) {
      onError(ex);
    }
  };

  const handleChange = (e) => {
    setComplete(e.complete);
    onChange(e.complete);
  }

  return (
    <form onSubmit={handleSubmit}>
      {showCardSecurIcons ?
        <img
          src={securedPayments}
          alt={''}
          style={{
            height: 32,
          }}
        />
        : null}
      <div style={{ backgroundColor: '#fff', padding: 10, borderRadius: 2, marginBottom: 8, }}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
              },
            },
            hidePostalCode: true,
          }}
          onChange={handleChange}
        />
      </div>
      <button
        type="submit"
        disabled={!complete}
        style={{
          backgroundColor: 'transparent',
          border: 0,
          width: '100%',
          padding: 0,
        }}
      >
        {button}
      </button>
    </form>
  );
}
