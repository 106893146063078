import { SET_IS_ONBOARDING_COMPLETED, SET_ONBOARDING_PROFILE, SET_ONBOARDING_STEP } from './types';

const initialState = {
  isOnboardingCompleted: false,
  onboardingProfile: {},
  onboardingStep: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ONBOARDING_PROFILE:
      return {
        ...state,
        onboardingProfile: action.payload,
      };

    case SET_IS_ONBOARDING_COMPLETED:
      return {
        ...state,
        isOnboardingCompleted: true,
      };

    case SET_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStep: action.payload,
      };

    default:
      return state;
  }
}
