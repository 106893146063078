import _ from 'lodash';
import { SET_USER_CREDENTIALS } from './types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_CREDENTIALS:
      return {
        ...state,
        id: _.get(action.payload, 'profile.id', null),
        accessToken: _.get(action.payload, 'profile.access_token', null),
      };
  }

  return state;
}
