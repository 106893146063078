import api from './index';

const AUTH = '/auth';

export function auth(creds) {
  return api.fetch(AUTH, {
    method: 'POST',
    data: creds,
  });
}
