export const LOADING = 'loading';
export const PALM_PHOTO = 'camera-screen';
export const ONBOARDING_DATE = 'onboarding-date';
export const ONBOARDING_LOADER = 'onboarding-loader';
export const ONBOARDING_GENDER = 'onboarding-gender';
export const ONBOARDING_NAME = 'onboarding-name';
export const ONBOARDING_RELATIONSHIP = 'onboarding-relationship';
export const ONBOARDING_SUBSCRIPTION_FIRST = 'onboarding-subscription-first';
export const ONBOARDING_SUBSCRIPTION_SECOND = 'onboarding-subscription-second';
export const ONBOARDING_TAKE_A_PHOTO = 'onboarding-take-a-photo';
export const ONBOARDING_TIME = 'onboarding-time';
export const ONBOARDING_EXTRA_SUB = 'onboarding-extra-subscription';
export const ONBOARDING_PERSONAL_SUB = 'onboarding-personal-subscription';
export const ONBOARDING_SUB_LANDING = 'onboarding-sub-landing';
export const SUBSCRIPTION_THREE_PRODUCTS = 'subscription-three-products';
export const PURCHASE_SUCCESS = 'purchase-success';
export const DASHBOARD = 'dashboard';
export const HOROSCOPES = 'horoscopes';
export const HOROSCOPE = 'horoscope';
export const MAGIC_BALL = 'magic-ball';
export const BIORITHM = 'biorithm';
export const TAROT = 'tarot-screen';
export const ERROR_TAKE_PHOTO = 'error-take-photo';
export const PALM_READING = 'palm-reading';
export const PHYSIOGNOMY = 'physiognomy';
export const COMPATIBILITY = 'compatibility';
export const COMPATIBILITY_RESULT = 'compatibility-result';
export const SETTINGS = 'settings';
export const PROFILE_SETTINGS = 'profile-settings';
export const NAME_SETTINGS = 'name-settings';
export const GENDER_SETTINGS = 'gender-settings';
export const RELATIONSHIP_SETTINGS = 'relationship-settings';
export const DATE_SETTINGS = 'date-settings';
export const TIME_SETTINGS = 'time-settings';
export const PRIVACY_POLICY = 'privacy-policy';
export const TERMS = 'terms';
export const LANGUAGE_SETTINGS = 'language-settings';
export const WATCH_ADD_SCREEN = 'watch-add-screen';
export const DAILY = 'daily';
export const MAIN = 'main';
export const DIVINATIONS = 'divinations';
export const ADVANTAGES = 'advantages';
export const DEV = 'dev';
export const AUTH = 'auth';
export const SETTINGS_CANCEL_SUBSCRIPTION = 'settings-cancel-subscription';

export const SUB_ROUTES = [
  ONBOARDING_SUBSCRIPTION_FIRST,
  ONBOARDING_SUBSCRIPTION_SECOND,
  ONBOARDING_EXTRA_SUB,
];

export const ONBOARDING_PROFILE_ROUTES = [
  ONBOARDING_DATE,
  ONBOARDING_GENDER,
  ONBOARDING_NAME,
  ONBOARDING_RELATIONSHIP,
];

export const PAYWALL_SUBS_IDS = {
  SUB_FIRST: 'sub_first',
  SUB_SECOND: 'sub_second',
  SUB_EXTRA: 'sub_extra',
  SUB_THREE_PRODUCTS: 'sub_three_products',
  SUB_PERSONAL: 'sub_personal',
  SUB_LANDING: 'sub_landing',
};
export const PAYWALL_SUBS_ROUTES = {
  [PAYWALL_SUBS_IDS.SUB_FIRST]: ONBOARDING_SUBSCRIPTION_FIRST,
  [PAYWALL_SUBS_IDS.SUB_SECOND]: ONBOARDING_SUBSCRIPTION_SECOND,
  [PAYWALL_SUBS_IDS.SUB_EXTRA]: ONBOARDING_EXTRA_SUB,
  [PAYWALL_SUBS_IDS.SUB_THREE_PRODUCTS]: SUBSCRIPTION_THREE_PRODUCTS,
  [PAYWALL_SUBS_IDS.SUB_PERSONAL]: ONBOARDING_PERSONAL_SUB,
  [PAYWALL_SUBS_IDS.SUB_LANDING]: ONBOARDING_SUB_LANDING,
};
