import {createAction} from "redux-actions";
import _ from 'lodash';

import {
  SET_UNLOCK,
} from './types';

import {fetchItem, pushItem} from '../../utils/storage';
import {
  CHINESE,
  DRUID,
  INDIAN_MOON,
  INDIAN_SUN,
  MAYAN,
  ZODIAC,
  PALMISTRY,
  PHYSIOGNOMY,
  MAGIC_BALL,
  BIORITHM,
  COMPATIBILITY,
  FREE,
  PAY,
  ADS,
} from "../../constants/sections";
import { navigate } from '../navigation/actions';
import Analytics from '../../modules/analytics';
import { ACTIONS, CATEGORIES } from '../../constants/triggers';
import * as VARIATOR_STATUSES from '../../constants/variator-statuses';

export const setUnlockData = createAction(SET_UNLOCK);

const foreverBlocks = [PALMISTRY, PHYSIOGNOMY, MAGIC_BALL, BIORITHM, COMPATIBILITY],
  horoscopeBlocks = [INDIAN_MOON, INDIAN_SUN, DRUID, MAYAN, CHINESE, ZODIAC],
  periodBlocks = [INDIAN_MOON, INDIAN_SUN, ZODIAC];

const STORAGE_KEY_UNLOCK = '@ASTROLINE/UNLOCK_CONTENT';

function cacheAppState(getState) {
  pushItem(
    STORAGE_KEY_UNLOCK,
    getState().unlockContent
  ).catch(console.warn);
}

export function initUnlockContent() {
  return (dispatch, getState) => {

    return fetchItem(STORAGE_KEY_UNLOCK)
      .then(data => {
        if (data) {
          dispatch(setUnlockData(data));
          return data;
        } else {
          return getState().unlockContent;
        }
      });
  };
}

export function checkAvailableBlock(type, period) {
  return (dispatch, getState) => {
    const state = getState(),
      {unlockContent} = state,
      {freeSelections} = state.remoteConfig.remoteConfigParams,
      isAds = !!state.ads.lastTypeAds && state.remoteConfig.remoteConfigParams.isAds,
      {purchased} = state.purchases;

    if (purchased) return FREE;

    if (horoscopeBlocks.includes(type)) {
      const horoscope = freeSelections.horoscopes[`${type}`];

      if (!horoscope) return PAY;
      if (!isAds) return FREE;

      return (
        periodBlocks.includes(type)
          ? _.get(unlockContent, `${type}.${period}`, 0) >= Date.now()
          : _.get(unlockContent, `${type}`, 0) >= Date.now()
      )
        ? FREE
        : ADS;
    } else {
      if (!freeSelections[`${type}`]) return PAY;
      if (!isAds) return FREE;

      if (foreverBlocks.includes(type)) {
        return  _.get(unlockContent, `${type}`, false)
          ? FREE
          : ADS;
      } else {
        return (_.get(unlockContent, `${type}`, 0) >= Date.now())
          ? FREE
          : ADS;
      }
    }
  };
}

export function goToSubOnLockedSection(route, params) {
  return (dispatch, getState) => {
    const { remoteConfig: { remoteConfigParams: { appSubsVariator } } } = getState();

    if(appSubsVariator) {
      Analytics.trackEvent(CATEGORIES.LOCKED_SECTION, ACTIONS.CLICK);
      setTimeout(() => {
        if(getState().app.variatorStatus === VARIATOR_STATUSES.CLOSED) {
          dispatch(navigate(route, params));
        }
      }, 1000);
    } else {
      dispatch(navigate(route, params));
    }
  };
}

export function setUnlockBlock(type, period) {
  return (dispatch, getState) => {
    const data = getState().unlockContent,
      dayTime = (Date.now() + 24 * 60 * 60 * 1000);
    if (~foreverBlocks.indexOf(type)) {
      dispatch(setUnlockData({...data, [`${type}`]: true}));
    } else if (~periodBlocks.indexOf(type)) {
      dispatch(setUnlockData({
        ...data,
        [`${type}`]: {
          ..._.get(data, `${type}`, {}),
          [`${period}`]: dayTime
        }
      }));
    } else {
      dispatch(setUnlockData({
        ...data,
        [`${type}`]: dayTime,
      }));
    }
    cacheAppState(getState);
  };
}
