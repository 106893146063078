import React from 'react';
import { Provider } from 'react-redux';
import 'react-native-gesture-handler';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import App from './screens';
import configureStore from './store';

import Messaging from './modules/messaging';
import Billing from './modules/billing';
import Auth from './modules/auth';
import { Localization } from './localization';
import Variator from './modules/variator';

const store = configureStore();

Localization.init();
Messaging.init();

Billing.putStore(store);
Auth.putStore(store);
Variator.init();

const persistor = persistStore(store);

export default class Application extends React.PureComponent {

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    );
  }
}
