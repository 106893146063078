import { createAction } from "redux-actions";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/hi';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';

import {
  SET_PROFILE,
  SET_CUSTOMER,
  SET_PENDING,
  INIT_PROFILE_FAIL
} from './types';
import { getProfile, update } from "../../api/profile";
import { GENDERS_IDS, MARITAL_STATUS_IDS } from "../../constants/profile";
import { checkAndSetFullProfile } from "../app/actions";


export const setErrorProfile = createAction(INIT_PROFILE_FAIL);
export const setPendingProfile = createAction(SET_PENDING);

export function updateProfile(profile) {
  return (dispatch, getState) => {
    dispatch(setPendingProfile());
    return update({ ...getState().profile.profileData, ...profile }).then(data => {
      dispatch(setProfile(data));
    }).catch(() => {
      dispatch(setErrorProfile());
    });
  };
}

export function loadProfile() {
  return (dispatch) => {
    dispatch(setPendingProfile());
    return getProfile()
      .then(profile => {
        dispatch(setProfile(profile));
      })
      .catch(() => {
        dispatch(setErrorProfile());
      });
  };
}

export function setProfile(data) {
  return (dispatch) => {
    const { profile, signs, customer, } = data,
      { id, name, device_id, gender, birthdate, marital_status, utc_offset, lang, email, } = profile;

    dispatch({
      type: SET_CUSTOMER,
      payload: {
        customer: customer?.data || null,
      }
    });

    dispatch({
      type: SET_PROFILE,
      payload: {
        signs,
        id,
        name,
        lang,
        device_id,
        gender: GENDERS_IDS[gender],
        date: birthdate,
        relationship: MARITAL_STATUS_IDS[marital_status],
        utc_offset,
        email,
      }
    });

    dispatch(checkAndSetFullProfile());
  };
}
