export const BLACK = '#000000';
export const BLACK_OPACITY = BLACK + '50';
export const WHITE = '#ffffff';
export const GREY = '#999';
export const TRANSPARENT = 'transparent';
export const UNACTIVE_ROW = 'rgba(208, 182, 255, 0.50)';
export const SHADOW = 'rgba(0, 0, 0, 0.2)';
export const ROZE = '#fd6a61';
export const RED = '#ff3738';
export const LIGHT_GREY = '#b6b8be';
export const WARM_GREY = '#949494';
export const GRAY_DARK = '#818080';
export const BLUEY_GREY = '#9697b9';
export const PASTEL_PURPLE = '#cea3ff';
export const BLUE_PURPLE = '#652fcb';
export const BLUISH_PURPLE_TWO = '#7c34ec';
export const VERY_LIGHT_PURPLE = '#e2d1fb';
export const ELECTRIC_PURPLE = '#9043ff';
export const LIGHTER_PURPLE = '#884ef8';
export const EASTER_PURPLE_TWO = '#a466ff';
export const LIGHTER_PURPLE_SIX = '#915cf6';
export const DISABLE_BUTTON = '#6f6499';
export const SELECTION_COMPLETED = '#e44bd3';
export const PALM_NOTIFICATION = '#F037BA';
export const PURPLE_PINK = '#e42cd0';
export const SALMON = '#ff7e76';
export const SELECTION_UNCOMPLETED = '#dac2ff';
export const LILIAC = '#be91ff';
export const PASTEL_PURPLE_TWO = '#d1b2ff';
export const PALE_LAVENDER = '#f2ebff';
export const LIGHT_LAVENDER = '#dcc3ff';
export const PALE_LILAC_TWO = '#f0e7ff';
export const PALE_LILAC_THREE = '#ece1ff';
export const PALE_LILAC_FIVE = '#e5d4ff';
export const PALE_PURPLE = '#ac8ddd';
export const PALM_BG = 'rgba(145, 92, 246, 0.50)';
export const UNDER_BUTTON_TEXT = '#dfc8ff';
export const PALE_LILAC = '#dfc8ff';
export const PERIWINLE = '#a170ff';
export const BLURPLE = '#6331c0';
export const BLUE_WITH_A_HINT_OF_PURPLE = '#592f9e';
export const DARKISH_PURPLE = '#3d1c72';
export const DARKISH_PURPLE_TRANSPARENT = 'rgba(61, 28, 114, 0.6)';
export const GRAPE_PURPLE = '#33185f';
export const GRAPE_PURPLE_TRANSPARENT = 'rgba(51, 24, 95, 0.9)';
export const PALE_VIOLET = '#c7a5f9';
export const PALE_VIOLET_TWO = '#d5bcfa';
export const AQUA_MARINE = '#46e8e1';
export const CARNATION_PINK = '#ff79a6';
export const WHEAT = '#fcc678';
export const WARM_PINK = '#fd5b88';
export const GRAY_DARK_TRANSPARENT = 'rgba(0, 0, 0, 0.50)';
export const PURPLE_GREY = '#CCC1DE';
export const EMOTIONAL_COLOR = AQUA_MARINE;
export const PHYSICAL_COLOR = WHEAT;
export const INTELLECTUAL_COLOR = WARM_PINK;
export const BG = DARKISH_PURPLE;
export const CHART_GRID_COLOR = PALE_VIOLET;
export const EMPTY_PLACE_FOR_TAROT = BLURPLE;
export const CHART_BAR_LINE = 'rgba(134, 65, 244, 0.8)';
export const DARK_PURPLE = '#6c32d8';
export const PINK = '#ff89e0';
export const BLUEY_PURPLE = '#6431b5';
export const LIGHTER_PURPLE_FIVE = '#894ff7';
export const PURPLEY = '#8749e8';
export const TURQUOISE_BLUE = '#02aab0';
export const AQUAMARINE = '#00cdac';
export const ROBIN_EGG_BLUE = '#82fffa';
export const TEALISH = '#42d1d2';
export const PIG_PINK = '#e86d9e';
export const ROWDER_PINK = '#ffa1e5';
export const SCARLET_GUM = '#2d1455';
export const LAVENDER = '#ac8ddd';
export const MEDIUM_PURPLE = '#9659ee';
export const RADICAL_RED = '#ff3970';

export const GRADIENT_COLORS_BUTTON = [
    '#fd5b86',
    '#eb48d5',
];

export const GRADIENT_COLORS_BUTTON_ALT = [
    TURQUOISE_BLUE,
    AQUAMARINE,
];

export const GRADIENT_COLORS_DISABLE_BUTTON = [
    '#DAC8DF',
    '#D3C5DC',
];

export const GRADIENT_COLORS_DISABLE_SELECT = [
    '#9D8FB7',
    '#A192BA',
];

export const GRADIENT_COLORS_UNSELECT = [
    '#884cfa',
    '#9f67f7',
];

export const GRADIENT_COLORS_MY_MSG = [
    '#9e66f6',
    '#ea5df8',
];

export const GRADIENT_COLORS_BUTTON_ONB_SEND = [
    '#fd5b86',
    '#e845e2',
];

export const GRADIENT_COLORS_PICKERS = [
    "#b283f5",
    "#ec2df8"
];

export const GRADIENT_CAMERA_SHOT = [
    "#7a42e4",
    "#9f67f7"
];

export const GRADIENT_BG_MAIN_SCREEN = [
    "#6a34d2",
    "#8b4cee"
];

export const GRADIENT_CARDS = [
    BLUE_PURPLE,
    BLUISH_PURPLE_TWO,
];

export const GRADIENT_EXTRA = [
    'rgba(136, 76, 250, 0.7)',
    'rgba(159, 103, 247, 0.7)',
];

export const GRADIENT_CHART_LATERAL = [
    "#6024cb",
    "rgba(106, 49, 209, 0.65)",
    "rgba(143, 84, 249, 0)",
];

export const GRADIENT_WHITE = [
    WHITE,
    WHITE
];

export const GRADIENT_WHITE_TRANSPERENT = [
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.5)',
    WHITE
];

export const PREMIUM_BANNER_GRADIENT_PINK = [
    '#ef70c1',
    '#f38bb6',
    '#fdb99b',
];

export const PREMIUM_BANNER_GRADIENT_GREEN = [
    '#46e8e1',
    '#30dfb1',
];
