import React, { PureComponent } from 'react';
import { StyleSheet, View, TextInput, Platform, } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import * as IMAGES from '../../../constants/images';
import * as COLORS from '../../../constants/colors';
import * as ROUTES from '../../../constants/routes';

import { navigate } from '../../../store/navigation/actions';
import { addToOnboardingProfile } from '../../../store/onboarding/actions';

import Button from '../../../components/button';
import Touchable from '../../../components/touchable';
import Text from '../../../components/text';

import Analytics from '../../../modules/analytics';
import i18n from '../../../localization';

import { verticalScale, horizontalScale } from '../../../utils/scale';
import Keyboard from '../../../utils/keyboard';

import OnboardingScreenLayout from './screen-layout';


const showAuthButton = Platform.OS === 'web';


class OnboardingName extends PureComponent {

  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    navigate: PropTypes.func,
    addToOnboardingProfile: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isOpenKeyboard: false,
    };
  }

  componentDidMount() {
    Analytics.trackEvent('Onboarding_Name', 'Open');
    this.keyboardDidShowSub = Keyboard.addListener(`keyboard${Platform.OS === 'android' ? 'Did' : 'Will'}Show`, this.openKeyboard);
    this.keyboardDidHideSub = Keyboard.addListener(`keyboard${Platform.OS === 'android' ? 'Did' : 'Will'}Hide`, this.closeKeyboard);
  }

  componentWillUnmount() {
    this.keyboardDidShowSub.remove();
    this.keyboardDidHideSub.remove();
  }

  openKeyboard = () => {
    this.setState({ isOpenKeyboard: true })
  };

  closeKeyboard = () => {
    this.setState({ isOpenKeyboard: false })
  };

  render() {
    return (
      <OnboardingScreenLayout
        bgImage={IMAGES.BG_ONBOARDING_NAME}
        sectionNumber={1}
        title={i18n.t('ONBOARDING.NAME.TITLE')}
        text={this.state.isOpenKeyboard ? undefined : i18n.t('ONBOARDING.NAME.TEXT')}
        interactiveElement={this.renderInputNameForm()}
        isKeyboard
      />
    )
  }

  renderInputNameForm() {
    return (
      <View style={styles.container}>
        <TextInput
          style={styles.input}
          autoFocus={Platform.OS !== 'web'}
          autoComplete="name"
          selectionColor={COLORS.SELECTION_COMPLETED}
          maxLength={10}
          placeholder={i18n.t('ONBOARDING.NAME.PLACEHOLDER')}
          placeholderTextColor={COLORS.SELECTION_UNCOMPLETED}
          onChangeText={this.onChangeTextInput}
        />
        <Button
          vibration
          title={i18n.t('ONBOARDING.NEXT')}
          style={styles.button}
          onPress={this.onButtonPress}
        />
        {
          showAuthButton
            ? <Touchable onPress={this.onAuthPress}>
              <Text fontWeightImportant style={styles.underButtonText}>
                {i18n.t('ONBOARDING.ALREADY_HAVE_ACCOUNT')}
              </Text>
            </Touchable>
            : null
        }
      </View>
    )
  }

  onChangeTextInput = (text) => {
    text = text.trim();
    this.setState({
      name: text,
    });
  };

  onButtonPress = () => {
    Analytics.trackEvent('Onboarding_Name', 'Click');
    this.props.addToOnboardingProfile({ name: this.state.name.trim() || 'Dear' });
    this.props.navigate(ROUTES.ONBOARDING_GENDER);
  }

  onAuthPress = () => {
    Analytics.trackEvent('Onboarding_Name', 'Auth_Click');
    this.props.navigate(ROUTES.AUTH);
  }
}

export default connect(
  state => ({
    isProfileFullData: state.profile.profileData.name && state.profile.profileData.name
  }), {
  navigate,
  addToOnboardingProfile,
}
)(OnboardingName);

const styles = StyleSheet.create({
  container: {
    marginBottom: Platform.OS === 'ios' ? verticalScale(15) : verticalScale(35),
    alignItems: 'center',
  },
  input: {
    width: horizontalScale(200),
    color: COLORS.WHITE,
    fontSize: verticalScale(24),
    letterSpacing: horizontalScale(-0.58),
    borderWidth: Platform.OS === 'web' ? 1 : undefined,
    borderBottomWidth: 1,
    borderColor: COLORS.SELECTION_UNCOMPLETED,
    borderRadius: 2,
    marginBottom: verticalScale(10),
  },
  button: {
  },
  underButtonText: {
    marginTop: verticalScale(7.5),
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(10),
  },
});
