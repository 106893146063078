import React from 'react';
import { createBottomTabNavigator } from 'react-navigation';
import { ImageBackground, StyleSheet, Text, View } from 'react-native';

import * as colors from '../../constants/colors';
import daily from '../../../assets/icons/tab-bar/inactive/daily-icon.png';
import dailyActive from '../../../assets/icons/tab-bar/active/daily-icon.png';
import horoscopes from '../../../assets/icons/tab-bar/inactive/horoscopes-icon.png';
import horoscopesActive from '../../../assets/icons/tab-bar/active/horoscopes-icon-filled.png';
import divinations from '../../../assets/icons/tab-bar/inactive/divinations-icon.png';
import divinationsActive from '../../../assets/icons/tab-bar/active/divinations-icon-copy-2.png';
import compatibility from '../../../assets/icons/tab-bar/inactive/compatibility-icon.png';
import compatibilityActive from '../../../assets/icons/tab-bar/active/compatibility-icon-filled.png';

import { COMPATIBILITY, DAILY, HOROSCOPES, DIVINATIONS, } from '../../constants/routes';
import { ACTIONS, CATEGORIES } from '../../constants/triggers';

import i18n from '../../localization';
import Analytics from '../../modules/analytics';

import {
  Horoscopes,
  Compatibility,
  Divinations,
  Dashboard,
} from './index-screens';

import Badge from './badge';

const TabBarIcon = ({ focused, source, section }) => {
  return (
    <ImageBackground style={styles.icon} source={focused ? source.active : source.inactive}>
      <Badge section={section} />
    </ImageBackground>
  );
};

const TabBarLabel = ({ focused, text }) => {
  return <Text style={focused ? styles.textActive : styles.text}>{i18n.t(text)}</Text>
};

const handleTabBarPress = (defaultHandler, tabName) => {
  Analytics.trackEvent(CATEGORIES.TAB_BAR, ACTIONS.CLICK);
  Analytics.trackEvent(`${CATEGORIES.TAB_BAR}_${tabName}`, ACTIONS.CLICK);
  defaultHandler();
};

const BottomTabBar = createBottomTabNavigator(
  {
    [DAILY]: {
      screen: Dashboard,
      navigationOptions: {
        tabBarLabel: (props) => <TabBarLabel text={'DASHBOARD.DAILY_TEXTS.TITLE'} {...props} />,
        tabBarIcon: (props) => <TabBarIcon section={DAILY} source={{ active: dailyActive, inactive: daily }} {...props} />,
        tabBarOnPress: ({ defaultHandler }) => { handleTabBarPress(defaultHandler, DAILY); },
      },
    },
    [HOROSCOPES]: {
      screen: Horoscopes,
      navigationOptions: {
        tabBarLabel: (props) => <TabBarLabel text={'DASHBOARD.HOROSCOPES_TEXTS.TITLE'} {...props} />,
        tabBarIcon: (props) => <TabBarIcon section={HOROSCOPES} source={{ active: horoscopesActive, inactive: horoscopes }} {...props} />,
        tabBarOnPress: ({ defaultHandler }) => { handleTabBarPress(defaultHandler, HOROSCOPES); },
      },
    },
    [DIVINATIONS]: {
      screen: Divinations,
      navigationOptions: {
        tabBarLabel: (props) => <TabBarLabel text={'DASHBOARD.DIVINATIONS_TEXTS.TITLE'} {...props} />,
        tabBarIcon: (props) => <TabBarIcon section={DIVINATIONS} source={{ active: divinationsActive, inactive: divinations }} {...props} />,
        tabBarOnPress: ({ defaultHandler }) => { handleTabBarPress(defaultHandler, DIVINATIONS); },
      },
    },
    [COMPATIBILITY]: {
      screen: Compatibility,
      navigationOptions: {
        tabBarLabel: (props) => <TabBarLabel text={'DASHBOARD.COMPATIBILITY_TEXTS.TITLE'} {...props} />,
        tabBarIcon: (props) => <TabBarIcon section={COMPATIBILITY} source={{ active: compatibilityActive, inactive: compatibility }} {...props} />,
        tabBarOnPress: ({ defaultHandler }) => { handleTabBarPress(defaultHandler, COMPATIBILITY); },
      },
    },
  },
  {
    initialRouteName: DAILY,
    tabBarOptions: {
      showLabel: true,
      showIcon: true,
      activeBackgroundColor: colors.SCARLET_GUM,
      inactiveTintColor: colors.GREY,
      inactiveBackgroundColor: colors.SCARLET_GUM,
      upperCaseLabel: false,
      style: {
        height: 55,
        borderTopWidth: 0,
        backgroundColor: colors.SCARLET_GUM,
      },
      tabStyle: {
        paddingVertical: 8,
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconStyle: {
        height: 24,
        marginBottom: 2,
        alignSelf: 'center',
      },
    },
  },
);

BottomTabBar.navigationOptions = {
  header: null,
};

const styles = StyleSheet.create({
  text: {
    fontSize: 11,
    color: colors.LAVENDER,
    textAlign: 'center',
  },
  textActive: {
    fontSize: 11,
    fontWeight: '600',
    color: colors.WHITE,
    textAlign: 'center',
  },
  icon: {
    width: 34,
    height: 24,
  },
});

export default BottomTabBar;
