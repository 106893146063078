import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import {createAnimatableComponent} from "react-native-animatable";

const AnimatedView = createAnimatableComponent(View);

export default class AnimationWrapForButton extends PureComponent {

    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        return <AnimatedView
            animation={'bounceIn'}
            duration={1000}
            easing={'ease-out'}
        >
            {this.props.children}
        </AnimatedView>;
    }
}
