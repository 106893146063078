import { Platform } from "react-native";

const DOMAIN = Platform.OS === 'web'
  ? process.env.REACT_APP_API
  : 'https://astroline.adwebmart.com/api/v1';

// const DOMAIN = 'http://astroline-dev.adwebmart.com/api/v1'; //dev api

const api = {
  token: null,

  //TODO: change the way of setting token
  setToken(newToken) {
    this.token = newToken;
  },
  fetch(url, options, pureBodyData = false) {
    const body = options.data
      ? pureBodyData
        ? options.data
        : JSON.stringify(options.data)
      : undefined;

    return fetch(DOMAIN + url, {
      ...options,
      body,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${this.token}` || undefined,
        ...(pureBodyData ? {} : { 'Content-Type': 'application/json; charset=UTF-8', }),
        ...options.headers,
      },
    }).then(response => {
      if (response.ok) {
        return response.json();
      }

      return response.json()
        .then(r => {
          throw r;
        });
    });
  },
};

export default api;
