import { createAction } from 'redux-actions';
import { AppState, BackHandler } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import _ from 'lodash';
import * as RNLocalize from 'react-native-localize';
import { SiriShortcutsEvent } from 'react-native-siri-shortcut';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/hi';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';
import * as VARIATOR_STATUSES from '../../constants/variator-statuses';

import {
  SET_APP_STATE,
  INIT_APP_STATE_FAIL,
  SET_IS_NOT_CONNECTED,
  SET_IS_LOADED,
  SET_IS_FULL_PROFILE,
  SET_PROGRESS_END_TIME_FACE,
  SET_PROGRESS_END_TIME_PALM,
  SET_LEFT_PALM_ANALYZING_FINISHED,
  SET_RIGHT_PALM_ANALYZING_FINISHED,
  SET_WAS_PALM_ANALYZING_FINISHED,
  SET_FACE_ANALYZING_FINISHED,
  SET_APP_STATUS,
  SET_WAS_ON_CONTENT_PAGES,
  SET_COUNT_OF_RATE_US_POPUP,
  SET_DEEP_SESSION_NUMBER,
  SET_PAYWALL,
  SET_VARIATOR_STATUS,
  SET_TRIALS_COUNTER,
  SET_TRIALS_COUNTER_INIT_SESSION,
  SET_SETUP_DATE,
} from './types';
import { fetchItem, pushItem } from '../../utils/storage';
import { loadPhysiognomyData } from '../physiognomy/actions';
import { updateProfile } from '../profile/actions';
import i18n from '../../localization';
import SiriShortcuts, { EVENT_NAME as SIRI_EVENT_NAME } from '../../utils/siri-shortcuts';
import { back, reset } from '../navigation/actions';
import { receiveNsUserActivityType } from '../siri-shorcuts/actions';
import Messaging from '../../modules/messaging';
import * as ROUTES from '../../constants/routes';
import { showNotification } from '../notification/actions';
import { PAYWALL_SUBS_ROUTES } from '../../constants/routes';
import { checkTrialState, hasActiveSubscription, setPurchased } from '../purchases/actions';
import { PREMIUM_BANNERS, TRY_PREMIUM_BANNER, UNLIMITED_BANNER } from '../../constants/premium-banners';
import Analytics from '../../modules/analytics';
import { ACTIONS, CATEGORIES } from '../../constants/triggers';
import { BLACK_LIST } from './index';

export const setIsNotConnected = createAction(SET_IS_NOT_CONNECTED);
export const setInitSessionForTrial = createAction(SET_TRIALS_COUNTER_INIT_SESSION);
export const setFaceAnalyzingFinished = createAction(SET_FACE_ANALYZING_FINISHED);
export const setDeepSessionNumber = createAction(SET_DEEP_SESSION_NUMBER);
export const setAppStatus = createAction(SET_APP_STATUS);
export const setPaywall = createAction(SET_PAYWALL);
export const setVariatorStatus = createAction(SET_VARIATOR_STATUS);
export const setSetupDate = createAction(SET_SETUP_DATE);

const requiredFields = ['name', 'date', 'gender', 'relationship'];

let backHandlerListener;
let appStateListener;
let netInfoListener;
let siriListener;

const STORAGE_KEY_APP_STATE = 'APP_STATE';

function cacheAppState(getState) {
  pushItem(
    STORAGE_KEY_APP_STATE,
    getState().app,
  ).catch(console.warn);
}

export function saveSetupDate(date) {
  return function (dispatch, getState) {
    dispatch(setSetupDate(date));
    cacheAppState(getState);
  }
}

export function initAppState() {
  return (dispatch, getState) => {
    netInfoListener = NetInfo.addEventListener(state => {
      dispatch(setIsNotConnected(!state.isConnected));
    },
    );

    return fetchItem(STORAGE_KEY_APP_STATE)
      .then(appState => {

        if (appState) {
          BLACK_LIST.forEach(el => {
            delete appState[el];
          });
        }

        if (appState) {
          dispatch({
            type: SET_APP_STATE,
            payload: {
              appState,
            },
          });
          return getState().app;
        } else {
          dispatch({
            type: INIT_APP_STATE_FAIL,
          });
          return getState().app;
        }
      });
  };
}

const _onLanguagesChange = () => {
  const deviceLang = RNLocalize.getLocales()[0].languageTag;
  i18n.locale = deviceLang;
  moment.locale(deviceLang);
};

export function onBackButtonClick() {
  return (dispatch, getState) => {
    const { index, activeRouteName } = getState().navigation;
    const { variatorStatus } = getState().app;

    if (
      variatorStatus === VARIATOR_STATUSES.TERMS && activeRouteName === ROUTES.TERMS
      || variatorStatus === VARIATOR_STATUSES.PRIVACY && activeRouteName === ROUTES.PRIVACY_POLICY) {
      return true;
    }

    if (ROUTES.SUB_ROUTES.includes(activeRouteName)) {
      return true;
    }

    if (index !== 0) {
      dispatch(back());
      return true;
    }

    return false;
  };
}

export function onAppStateChanged(nextAppState) {
  return (dispatch, getState) => {
    const {
      app: { wasOnContentPages, appStatus },
      purchases: { purchased },
      navigation: { activeRouteName },
      onboarding: { isOnboardingCompleted },
    } = getState();

    if (appStatus === 'background' && nextAppState !== 'background') {
      dispatch(checkAndActivatePaywall());
    }

    if (appStatus !== 'background' && nextAppState === 'background') {
      if (!purchased && ROUTES.ONBOARDING_PROFILE_ROUTES.includes(activeRouteName)) {
        Messaging.scheduleRollUpProfileNotification();
      }
      if (!purchased && !isOnboardingCompleted && !ROUTES.ONBOARDING_PROFILE_ROUTES.includes(activeRouteName)) {
        Messaging.scheduleRollUpOnboardingSubscriptionNotification();
      }
      if (!wasOnContentPages && activeRouteName === ROUTES.MAIN && dispatch(showNotification())) {
        Messaging.scheduleRollUpDashboardNotification();
      }
    }

    dispatch(setAppStatus(nextAppState));
  };
}

export function onMountApp() {
  return (dispatch) => {
    RNLocalize.addEventListener('change', _onLanguagesChange);
    appStateListener = (nextAppState) => dispatch(onAppStateChanged(nextAppState));
    backHandlerListener = () => dispatch(onBackButtonClick());
    AppState.addEventListener('change', appStateListener);
    BackHandler.addEventListener('hardwareBackPress', backHandlerListener);
    if (SiriShortcuts.supported) {
      siriListener = ({ activityType }) => { dispatch(receiveNsUserActivityType(activityType)); };
      SiriShortcutsEvent.addListener(SIRI_EVENT_NAME, siriListener);
    }
  };
}

export function onWillUnmountApp() {
  return (dispatch) => {
    RNLocalize.removeEventListener('change', _onLanguagesChange);
    AppState.removeEventListener('change', appStateListener);
    BackHandler.removeEventListener('hardwareBackPress', backHandlerListener);
    if (SiriShortcuts.supported) {
      SiriShortcutsEvent.removeListener(SIRI_EVENT_NAME, siriListener);
    }
    if (netInfoListener) {
      netInfoListener();
    }
  };
}

export function checkInternetConnection() {
  return dispatch => {
    return NetInfo.fetch().then(state => {
      dispatch(setIsNotConnected(!state.isConnected));
      return state.isConnected;
    },
    );
  };
}

export function setCountOfRateUs() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_COUNT_OF_RATE_US_POPUP,
      payload: +getState().app.countOfRateUsPopup + 1,
    });

    cacheAppState(getState);
  };
}

export function checkAndSetFullProfile() {
  return (dispatch, getState) => {
    const { app, profile } = getState(),
      { profileData } = profile,
      { isFullProfile } = app;

    if (!isFullProfile && !requiredFields.some(el => !profileData[el])) {
      dispatch({
        type: SET_IS_FULL_PROFILE,
      });

      cacheAppState(getState);
    }
  };
}

export function setWasOnContentPages() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_WAS_ON_CONTENT_PAGES,
    });
    cacheAppState(getState);
  };
}

export function setIsLoaded() {
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOADED,
    });
  };
}

export function setProgressEndTimeFace(time) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROGRESS_END_TIME_FACE,
      payload: {
        time,
      },
    });
    if (dispatch(showNotification())) {
      Messaging.scheduleFinishedAnalizedFaceNotification();
    }
    cacheAppState(getState);
  };
}

export function setProgressEndTimePalm(time, hand) {
  return (dispatch, getState) => {
    const now = Date.now();

    setTimeout(() => {
      dispatch(setWasPalmAnalyzingFinished());
    }, (time - now));

    dispatch({
      type: SET_PROGRESS_END_TIME_PALM,
      payload: {
        time,
        hand: hand || getState().app.analyzingActiveHand,
      },
    });

    cacheAppState(getState);
  };
}

export function checkHasBeenSetPhoto() {
  return (dispatch, getState) => {
    const { palmReading: { data }, app, profile } = getState();
    const sessionNumber = Analytics.getSessionNumber();
    if (sessionNumber === 0) { //for check for people who deleted the app and install it again
      if (_.get(data, 'left_hand_path', null)) {
        dispatch(setWasPalmAnalyzingFinished());
        dispatch(setLeftPalmAnalyzingFinished());
      }
      if (_.get(data, 'right_hand_path', null)) {
        dispatch(setWasPalmAnalyzingFinished());
        dispatch(setRightPalmAnalyzingFinished());
      }
      dispatch(loadPhysiognomyData())
        .finally(() => {
          if (_.get(getState().physiognomy.data)) {
            dispatch(setFaceAnalyzingFinished());
          }
        });
    } else {
      const now = Date.now();
      if (app.cameraModalEndTimePalm !== null && !app.isPalmAnalyzingFinished) {//for check for notification
        if (now <= app.cameraModalEndTimePalm) {
          dispatch(setWasPalmAnalyzingFinished());
        } else {
          setTimeout(() => {
            dispatch(setWasPalmAnalyzingFinished());
          }, (app.cameraModalEndTimePalm - now));
        }
      }
    }
    if (!profile.profileData.lang && !app.isFullProfile) {
      dispatch(updateProfile({}));
    }
  };
}

export function setWasPalmAnalyzingFinished() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_WAS_PALM_ANALYZING_FINISHED,
    });
    if (dispatch(showNotification())) {
      Messaging.scheduleFinishedAnalizedHandNotification();
    }
    cacheAppState(getState);
  };
}

export function setLeftPalmAnalyzingFinished() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LEFT_PALM_ANALYZING_FINISHED,
    });
    cacheAppState(getState);
  };
}

export function setRightPalmAnalyzingFinished() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RIGHT_PALM_ANALYZING_FINISHED,
    });
    cacheAppState(getState);
  };
}

export function checkAndActivatePaywall() {
  return (dispatch, getState) => {
    const {
      remoteConfig: {
        remoteConfigParams: {
          paywall,
          paywallSession,
          paywallScreen,
        },
      }, app: { paywallSessionNumber, setupDate }, onboarding: { isOnboardingCompleted },
    } = getState();
    const nextDeepSessionNumber = paywallSessionNumber + 1;
    const daysPassed = moment.duration(Date.now() - setupDate).days();

    if (isOnboardingCompleted) {
      dispatch(setDeepSessionNumber(nextDeepSessionNumber));
      Analytics.trackEvent(CATEGORIES.DEEP_SESSION, ACTIONS.START);
      Analytics.trackEvent(CATEGORIES.DEEP_SESSION, `${ACTIONS.START}_${daysPassed}_day`);
      cacheAppState(getState);

      if (paywall && !dispatch(hasActiveSubscription())) {
        if (nextDeepSessionNumber >= paywallSession
          && Object.keys(PAYWALL_SUBS_ROUTES).includes(paywallScreen)) {
          dispatch(setPaywall(true));
          dispatch(setPurchased(false));
          dispatch(reset(PAYWALL_SUBS_ROUTES[paywallScreen]));
          return true;
        }
        dispatch(setPurchased(true));
        return false;
      }
    }
  };
}

export function getPremiumBannerParams() {
  return (dispatch, getState) => {
    const {
      purchases: { purchased },
      remoteConfig: {
        remoteConfigParams: {
          tryPremiumBannerEverySession,
          unlimitedBannerEverySession,
        },
      },
    } = getState();
    const sessionNumber = Analytics.getSessionNumber();
    const trial = dispatch(checkTrialState());
    if (!purchased && sessionNumber % tryPremiumBannerEverySession === 0) {
      return PREMIUM_BANNERS[TRY_PREMIUM_BANNER];
    }
    if (trial && sessionNumber % unlimitedBannerEverySession === 0) {
      return PREMIUM_BANNERS[UNLIMITED_BANNER];
    }
    return false;
  };
}

export function incrementTrialsLeft() {
  return (dispatch, getState) => {
    const { trialsCounter, initSessionTrialsCounter } = getState().app;
    const sessionNumber = Analytics.getSessionNumber();

    if (!initSessionTrialsCounter) {
      dispatch(setInitSessionForTrial(sessionNumber));
    }
    dispatch({
      type: SET_TRIALS_COUNTER,
      trialsCounter: (trialsCounter || 0) + 1,
    });
    cacheAppState(getState);
  };
}


