import { createAction } from "redux-actions";
import _ from 'lodash';

import { REQUIRED_KEYWORDS, BAD_KEYWORDS } from "../../constants/palm-reading";

import {
  getPalm,
  postPalm,
  getImageLabels,
  GOOGLE_VISION_ERROR,
} from "../../api/palm-reading";

import {
  SET_DATA,
  SET_OPENED_TABS,
} from "./types";
import { fetchItem, pushItem } from "../../utils/storage";

const setPalmResult = createAction(SET_DATA);
const setOpenedTabs = createAction(SET_OPENED_TABS);

const STORAGE_KEY_PALM_READING = '@ASTROLINE/PALM_READING';

function cachePalmReading(getState) {
  pushItem(
    STORAGE_KEY_PALM_READING,
    getState().palmReading.openedTabs
  ).catch(console.warn);
}

export function initPalmReading() {
  return (dispatch) => {
    return getPalm()
      .then(({ palm_reading }) => {
        dispatch(setPalmResult(palm_reading));
        return fetchItem(STORAGE_KEY_PALM_READING);
      })
      .then((openedTabs) => {
        if (openedTabs) {
          dispatch(setOpenedTabs(openedTabs));
        }
      })
      .catch(console.warn);

  };
}

export function setOpenedTab(tab) {
  return (dispatch, getState) => {
    const { palmReading } = getState();

    if (palmReading.openedTabs.indexOf(tab) === -1 && _.get(palmReading.data, `${tab.toLowerCase()}_hand_path`, null)) {
      dispatch(setOpenedTabs([...palmReading.openedTabs, tab]));
      cachePalmReading(getState);
    }
  };
}

export const getObjectsFromLabels = (array, labels) => array.map(item => {
  return labels.find(element => {
    return (
      element.description.toLowerCase() === item.description.toLowerCase() &&
      element.score >= item.score
    );
  });
}).filter(Boolean);

export const loadPalmImage = (image, hand) => {
  return (dispatch, getState) => {
    const { ignoreObjectDetection } = getState().remoteConfig.remoteConfigParams;
    if (ignoreObjectDetection) {
      return postPalm(image, hand)
        .then(({ palm_reading }) => {
          if (!palm_reading) throw new Error();
          dispatch(setPalmResult(palm_reading));
        });
    } else {

      return getImageLabels(image.base64)
        .then(result => {
          if (result === GOOGLE_VISION_ERROR) {
            //show user, that he found item when we didn't receive response from server
            return;
          }

          const labels = result.responses[0].labelAnnotations,
            requiredKeywords = getObjectsFromLabels(REQUIRED_KEYWORDS, labels),
            badKeywords = getObjectsFromLabels(BAD_KEYWORDS, labels),
            isHand = requiredKeywords.length === REQUIRED_KEYWORDS.length;

          if (!isHand || badKeywords.length) {
            throw new Error();
          }
          return postPalm(image, hand);
        })
        .then(({ palm_reading }) => {
          if (!palm_reading) throw new Error();
          dispatch(setPalmResult(palm_reading));
        });
    }

  };
};
