import { VOICE_COMMAND_ADDED, SHOW_SUGGESTION, HIDE_SUGGESTION, RECEIVE_NS_USER_ACTIVITY_TYPE } from './types';

const initialState = {
  voiceCommandAdded: false,
  suggestionVisible: false,
  seenSuggestionTimes: 0,
  nsUserActivityType: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VOICE_COMMAND_ADDED:
      return {
        ...state,
        voiceCommandAdded: true,
        suggestionVisible: false,
      };

    case SHOW_SUGGESTION:
      return {
        ...state,
        suggestionVisible: true,
        seenSuggestionTimes: state.seenSuggestionTimes + 1,
      };

    case HIDE_SUGGESTION:
      return {
        ...state,
        suggestionVisible: false,
      };

    case RECEIVE_NS_USER_ACTIVITY_TYPE:
      return {
        ...state,
        nsUserActivityType: action.payload,
      };
  }

  return state;
}
