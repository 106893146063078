import { Dimensions, Platform } from 'react-native';

const { width, height } = Dimensions.get('window');

const koef = Platform.select({
    ios: 5,
    android: 2,
    web: 2,
});

export const headerIconSize = (width / 100) * 7.5;
export const headerMarginHorizontal = (width / 100) * 2.5;
export const headerMarginVertical = (height / 100) * koef ;
