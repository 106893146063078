import React, { PureComponent } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { connect } from 'react-redux';

import * as COLORS from '../../constants/colors';
import i18n from '../../localization';
import * as TAB_BAR_BADGES from '../../constants/tab-bar-badges';
import { isToday } from '../../utils/format-dates-in-horoscopes';
import { DAILY } from '../../constants/routes';

class Badge extends PureComponent {
  render() {
    const BADGE_TYPES = {
      new: {
        text: i18n.t('NEW'),
        style: styles.badgeContainer,
      },
      point: {
        text: '•',
        style: [styles.badgeContainer, styles.dot],
      },
    };
    const { section, badges, badgesShowed, lastVisitedDailyDate } = this.props;
    const badge = badges[section];

    if(badge === TAB_BAR_BADGES.NONE || badgesShowed.includes(section)) {
      return null;
    }

    if(isToday(lastVisitedDailyDate) && section === DAILY) {
      return null;
    }

    return (
      <View style={BADGE_TYPES[badge].style}>
        <Text style={styles.badgeText}>{BADGE_TYPES[badge].text}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  badgeContainer: {
    backgroundColor: COLORS.RADICAL_RED,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingVertical: 0.75,
    position: 'absolute',
    top: -2,
    right: -10,
  },
  dot: {
    right: 0,
  },
  badgeText: {
    fontSize: 8,
    color: COLORS.WHITE,
    fontWeight: '600',
  },
});

export default connect(state => ({
  badgesShowed: state.badges.badgesShowed,
  lastVisitedDailyDate: state.badges.lastVisitedDailyDate,
  badges: state.remoteConfig.remoteConfigParams.tabBarBadges,
}), () => ({}))(Badge);
