import React, { PureComponent } from 'react';
import {
  StyleSheet,
  View,
  StatusBar,
} from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ifIphoneX } from 'react-native-iphone-x-helper';

import * as COLORS from '../../../../constants/colors';
import * as ICONS from '../../../../constants/icons';
import CloseButtonWithTimeout from '../../../subcsription/components/close-button-with-timeout';
import { newVerticalScale, newHorizontalScale, textSubscription } from '../../../../utils/scale';
import i18n from '../../../../localization';
import SubBottomWrapper from '../../../subcsription/components/subscription-bottom-wrapper';
import Text from '../../../../components/text';
import { getButtonDefaultInfoFromProduct } from '../../../../modules/billing/conveter';
import Analytics from '../../../../modules/analytics';
import FeaturesWithIcons from './features';
import AnimatedBG from '../../../tarot/components/animated-bg';
import { goToNextOnboardingStep } from '../../../../store/onboarding/actions';
import * as ROUTES from '../../../../constants/routes';


class SubscriptionPersonal extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    closeButtonTimeout: PropTypes.number.isRequired,
    isTryLimited: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    pricePlace: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    goToNextOnboardingStep: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    activeRoute: PropTypes.bool.isRequired,
    analyticsType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const productDetails = getButtonDefaultInfoFromProduct(props.product);

    this.state = {
      productDetails,
      products: [productDetails],
    };
  }

  componentDidMount() {
    Analytics.trackEvent(this.props.analyticsType, 'Open');
  }

  render() {
    const {
      name,
      closeButtonTimeout,
      isTryLimited,
      buttonText,
      pricePlace,
      product,
      activeRoute,
      analyticsType,
    } = this.props;
    const { productDetails, products } = this.state;

    return (
      <View style={styles.root}>
        <StatusBar barStyle='light-content' />
        <AnimatedBG visible={activeRoute}>
          <View style={styles.container}>
            <StatusBar barStyle='light-content' />
            <CloseButtonWithTimeout
              closePress={this.onCloseButtonPress}
              source={ICONS.CLOSE_ICON}
              closeButtonTimeout={closeButtonTimeout}
            />
            <View style={styles.titleContainer}>
              <Text fontWeightImportant fontWeight={'bold'} style={styles.title}>
                {i18n.t('ONBOARDING.PERS_SUBSCRIPTION.TITLE', { name })}
              </Text>
            </View>
            <FeaturesWithIcons />
            <SubBottomWrapper
              isRelative
              isTryLimited={isTryLimited}
              onTryLimitedPress={this.onTryLimitedPress}
              productDetails={productDetails}
              products={products}
              product={product}
              title={buttonText || i18n.t('ONBOARDING.SUBSCRIPTION_FIRST.BUTTON_TEXT')}
              pricePlace={pricePlace}
              analyticsType={analyticsType}
            />
          </View>
        </AnimatedBG>
      </View>
    );
  }

  onCloseButtonPress = () => {
    const { goToNextOnboardingStep, analyticsType } = this.props;
    Analytics.trackEvent(analyticsType, 'Close');

    goToNextOnboardingStep();
  };

  onTryLimitedPress = () => {
    const { goToNextOnboardingStep, analyticsType } = this.props;
    Analytics.trackEvent(analyticsType, 'TryLimited');

    goToNextOnboardingStep();
  };
}

export default connect(
  state => {
    const {
      onboardingPersSubscriptionCloseButtonTimeout: closeButtonTimeout,
      isOnboardingPersSubscriptionTryLimited: isTryLimited,
      onboardingPersSubscriptionButtonText: buttonText,
      pricePosition: pricePlace,
    } = state.remoteConfig.remoteConfigParams;
    return {
      closeButtonTimeout,
      isTryLimited,
      buttonText,
      pricePlace,
      product: state.remoteConfig.remoteConfigParams.onboardingPersSubscriptionProduct,
      name: state.onboarding?.onboardingProfile?.name || state.profile.profileData.name,
      activeRoute: state.navigation.activeRouteName === ROUTES.ONBOARDING_PERSONAL_SUB,
      analyticsType: state.app.paywall ? 'paywall_pers' : 'Onboarding_SubscriptionPers',
    };
  },
  {
    goToNextOnboardingStep,
  },
)(SubscriptionPersonal);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
  },
  root: {
    flex: 1,
    position: 'relative',
  },
  titleContainer: {
    ...ifIphoneX({
      marginTop: newVerticalScale(65, null, 60),
    }, {
      marginTop: newVerticalScale(25),
    }),
    width: newHorizontalScale(375),
    paddingVertical: newVerticalScale(15),
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: COLORS.WHITE,
    fontSize: textSubscription(36),
    letterSpacing: textSubscription(-1.16),
    lineHeight: textSubscription(42),
    textAlign: 'center',
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  list: {
    flex: 1,
    justifyContent: 'center',
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: newVerticalScale(10),
  },
  listLastItemContainer: {
    marginBottom: 0,
  },
  listItemIcon: {
    width: newHorizontalScale(35),
    height: newVerticalScale(35),
    overflow: 'visible',
    marginRight: newHorizontalScale(16),
  },
  listItemText: {
    color: COLORS.WHITE,
    fontSize: newVerticalScale(18, null, 16),
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  listItemTouchable: {
    textDecorationLine: 'underline',
    marginLeft: newHorizontalScale(14),
  },
});
