export const SET_APP_STATE = 'SET_APP_STATE';
export const INIT_APP_STATE_FAIL = 'INIT_APP_STATE_FAIL';

export const SET_IS_FULL_PROFILE = 'SET_IS_FULL_PROFILE';

export const SET_IS_NOT_CONNECTED = 'SET_IS_NOT_CONNECTED';
export const SET_DEEP_SESSION_NUMBER = 'SET_DEEP_SESSION_NUMBER';
export const SET_IS_LOADED = 'SET_IS_LOADED';

export const SET_PROGRESS_END_TIME_FACE = 'SET_PROGRESS_END_TIME_FACE';
export const SET_PROGRESS_END_TIME_PALM = 'SET_PROGRESS_END_TIME_PALM';

export const SET_LEFT_PALM_ANALYZING_FINISHED = 'SET_LEFT_PALM_ANALYZING_FINISHED';
export const SET_RIGHT_PALM_ANALYZING_FINISHED = 'SET_RIGHT_PALM_ANALYZING_FINISHED';

export const SET_WAS_PALM_ANALYZING_FINISHED = 'SET_WAS_PALM_ANALYZING_FINISHED';
export const SET_FACE_ANALYZING_FINISHED = 'SET_FACE_ANALYZING_FINISHED';

export const SET_APP_STATUS = 'SET_APP_STATUS';
export const SET_WAS_ON_CONTENT_PAGES = 'SET_WAS_ON_CONTENT_PAGES';
export const SET_COUNT_OF_RATE_US_POPUP = 'SET_COUNT_OF_RATE_US_POPUP';
export const SET_PAYWALL = 'SET_PAYWALL';
export const SET_VARIATOR_STATUS = 'SET_VARIATOR_STATUS';
export const SET_SETUP_DATE = 'SET_SETUP_DATE';

export const SET_TRIALS_COUNTER = '@ASTROLINE/SET_TRIALS_COUNTER';
export const SET_TRIALS_COUNTER_INIT_SESSION = '@ASTROLINE/SET_TRIALS_COUNTER_INIT_SESSION';
