import { Platform } from 'react-native';

import { BUNDLE_ID } from './general';

export const KEY = Platform.select({
  ios: 'dffda82599a341fe83a899d135928763',
  android: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoFQeK54N92RlBs/MtfFXXZd9XeM3gY36Pt0PzUSM8rzC0dtC8u5l1GFOBe0kcjoeYCVuplvMBgoCl5Cc/k3rcjBIz4Gm7xoDplgd7AVDkxnvqUGLMgqC9gg9P1zxUAlJ0q/aROHmy8YrafaPwA7NaacJpy9bhJervfS4QKoAZySTTZ6wliHOlCc7S2K6GwpncUvRlZ14Ff5qaYsxd8XY2nWXFHLkZFvvHnIuUaLAYUVo+PfXRvJcbYRHHoysEGqk2xA2Di9Q/W7/vJrA0J3u7kB6aZGvqREz4RpLJ6y//+lLqE9w/nNbDkWfuvp/N4FMbFDZZkYRQCfsJZldnKH63wIDAQAB',
});

export const SKU = BUNDLE_ID + '.premium';

export const WEEK = SKU + '.w';
export const MONTH = SKU + '.m';
export const YEAR = SKU + '.y';

export const WEEK_SHORT = Platform.OS === 'web' ? process.env.REACT_APP_BILLING_PLAN_ID : (WEEK + '.f');
export const WEEK_LONG = WEEK + '.e';
export const WEEK_M = WEEK + '.m';
export const WEEK_I = WEEK + '.i';

export const MONTH_DEFAULT = MONTH + '.a';
export const YEAR_DEFAULT = YEAR + '.a';

export const WEEK_SHORT_REACH_ANDROID = WEEK + '.a';
export const WEEK_LONG_REACH_ANDROID = WEEK + '.b';
export const WEEK_SHORT_ANDROID = WEEK + '.c';
export const WEEK_LONG_ANDROID = WEEK + '.d';

export const AD = 'AD';
