import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, Platform, View, ScrollView } from 'react-native';
import i18n from '../../../localization';
import Text from '../../../components/text';

import * as COLORS from '../../../constants/colors';
import * as ROUTES from '../../../constants/routes';
import { navigate } from '../../../store/navigation/actions';
import Billing from '../../../modules/billing';

class SubTerms extends PureComponent {
  static propTypes = {
    review: PropTypes.bool.isRequired,
    onPrivPress: PropTypes.func.isRequired,
    onTermsPress: PropTypes.func.isRequired,
    products: PropTypes.array,
    shortTerms: PropTypes.bool.isRequired,
    onRestore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    products: [],
  };

  render() {
    const {
      products,
      review,
      shortTerms,
      isAndroidTerms,
      onPrivPress,
      onTermsPress,
    } = this.props,
      generateSubText = this.generateSubText();

    if (Platform.OS === 'android' && !isAndroidTerms) {
      return <View style={styles.noAndroidTerms} />;
    }

    const linkStyle = [styles.text, styles.link];

    const rpLink = (
      <Text style={linkStyle} onPress={this.restorePayments}>
        {i18n.t('RP.TITLE_SHORT')}
      </Text>
    );

    const ppLink = (
      <Text style={linkStyle} onPress={onPrivPress}>
        {i18n.t('AUTH.PRIV')}
      </Text>
    );

    const tosLink = (
      <Text style={linkStyle} onPress={onTermsPress}>
        {i18n.t('AUTH.TERMS')}
      </Text>
    );

    let full = (
      <Text style={styles.text}>
        {i18n.t(`SUBSCRIPTION.SUBSCRIBE_TERMS${Platform.OS === 'android' ? '_ANDROID' : Platform.OS === 'web' ? '_WEB' : ''}`, { ...generateSubText, })}
      </Text>
    )

    let template;
    if (Platform.OS === 'web') {
      template = (
        <Text style={styles.text}>
          {i18n.t('AUTH.AGREEING')}
          {ppLink}
          {i18n.t('AUTH.AGREEING_&')}
          {tosLink}
        </Text>
      );
      if (shortTerms) {
        template = (
          <View style={[styles.terms, styles.shortTerms]}>
            {template}
          </View>);
      }
      else {
        template = (
          <Text style={[styles.terms, styles.webTerms]}>
            {template}
            <br />
            {!products.find(i => !i.haveTrialPeriod) &&
              <Text style={styles.text}>
                {i18n.t('SUBSCRIPTION.SUBSCRIBE_TERMS_FREE')}
              </Text>
            }
            {full}
          </Text>
        )
      }
    }
    else {
      if (shortTerms) {
        template = (
          <View style={[styles.terms, styles.shortTerms]}>
            <Text style={styles.marginLink}>
              {rpLink}
            </Text>
            <Text style={styles.marginLink}>
              {ppLink}
            </Text>
            <Text style={styles.marginLink}>
              {tosLink}
            </Text>
          </View>
        );
      }
      else {
        template = (
          <Text style={styles.terms}>
            {full}
            {' '}
            {ppLink}
            {' '}
            {tosLink}
          </Text>
        );
      }
    }

    return (
      <View style={styles.root} >
        {
          review ? (
            template
          ) : (
              <ScrollView style={styles.wrapper} >
                {template}
              </ScrollView>
            )}
      </View >
    );
  }

  restorePayments = () => {
    const { onRestore } = this.props;

    Billing.restorePurchases()
      .then(success => {
        if (success) {
          onRestore();
        }
      });
  };

  generateSubText = () => {
    let resultString = '',
      periodWithCost = '';

    const products = this.props.products.filter(product => product);

    products.forEach((product, index) => {

      if (index) {
        resultString += ', ';
        periodWithCost += ', ';
      }

      resultString += i18n.t(
        `SUBSCRIPTION.TERMS.${product.code}${product.haveTrialPeriod ? "_WITH" : ""}`,
        {
          price: product.priceText,
          days: product.trial,
        },
      );

      periodWithCost += i18n.t(
        `SUBSCRIPTION.TERMS.${product.code}`,
        {
          price: product.priceText,
        },
      );
    });

    return {
      products: resultString
        ? i18n.t(`SUBSCRIPTION.PRODUCTS_TYPES`, { productTypes: resultString })
        : '',
      periodWithCost: periodWithCost,
    };
  };
}

export default connect(
  state => ({
    review: true,
    isAndroidTerms: state.remoteConfig.remoteConfigParams.isAndroidTerms,
    shortTerms: state.remoteConfig.remoteConfigParams.shortTerms,
  }),
  dispatch => ({
    onRestore: () =>
      dispatch(navigate(ROUTES.MAIN)),
    onPrivPress: () =>
      dispatch(navigate(ROUTES.PRIVACY_POLICY)),
    onTermsPress: () =>
      dispatch(navigate(ROUTES.TERMS)),
  }),
)(SubTerms);

const styles = StyleSheet.create({
  root: {
    // marginTop: 10,
  },
  wrapper: {
    maxHeight: 55,
  },
  terms: {
    textAlign: 'justify',
    fontSize: 10,
    margin: 10,
  },
  shortTerms: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  webTerms: {
    maxHeight: 8,
    overflow: 'visible',
  },
  marginLink: {
    marginLeft: 6,
    marginRight: 6,
  },
  text: {
    color: COLORS.WHITE,
    fontSize: 10,
  },
  link: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    color: COLORS.WHITE,
  },
  noAndroidTerms: {
    height: 30,
  },
});
