import RemoteConfig from '../../modules/remote-config';

import { FETCH_REMOTE_CONFIG_FAIL, SET_REMOTE_CONFIG, } from './types';
import { initialParams } from './index';


const REMOTE_CONFIG_CACHE_TIME = 60;
const KEYS = Object.keys(initialParams);


export function initRemoteConfig() {
  return (dispatch, getState) => {
    return dispatch(fetchRemoteConfig()).catch(() => {
      return getState().remoteConfig.remoteConfigParams;
    });
  };
}

export function fetchRemoteConfig() {
  return (dispatch, getState) => {
    return RemoteConfig.fetch(KEYS, initialParams, REMOTE_CONFIG_CACHE_TIME)
      .then((remoteConfig) => {
        dispatch({
          type: SET_REMOTE_CONFIG,
          payload: {
            remoteConfig,
          }
        });
        return getState().remoteConfig.remoteConfigParams;
      })
      .catch((err) => {
        console.warn('fetchRemoteConfig err: ', err.message);
        dispatch({
          type: FETCH_REMOTE_CONFIG_FAIL
        });
        return getState().remoteConfig.remoteConfigParams;
      });
  }
}
