export const SET_DATA = 'LOCALIZATION/SET_DATA';
export const CLEAN_DATA = 'LOCALIZATION/CLEAN_DATA';

export const initialState = {
  translations: null,
  updated: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAN_DATA:
      return {
        ...initialState,
      };
  }
  return state;
}
