import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { parse } from 'query-string';

import { initCheckoutSession, getPlans, } from '../../api/purchases';

import Analytics from '../../modules/analytics';

import * as ROUTES from '../../constants/routes';

import { reset, } from '../../store/navigation/actions';
import { initPurchase } from '../../store/purchases/actions';


let store;
let plans;

const PATH_PURCHASE_SUCCESS = '/purchase-success';
const PATH_PURCHASE_CANCEL = '';
const PARAM_SESSION_ID = 'session';

const KEY = process.env.REACT_APP_BILLING_KEY;
const PRODUCT_ID = process.env.REACT_APP_BILLING_PLAN_ID;
const PRICE = +process.env.REACT_APP_BILLING_PLAN_PRICE;
const CURRENCY = process.env.REACT_APP_BILLING_PLAN_CURRENCY;
const TRIAL = +process.env.REACT_APP_BILLING_PLAN_TRIAL;
const PERIOD = process.env.REACT_APP_BILLING_PLAN_PERIOD;


export default {

  putStore(s) {
    store = s;
  },

  init() {
    return getPlans()
      .then(({ data }) => {
        plans = data;
      })
      .catch(err => {
        console.warn(err);
      })
      .then(() => {
        if (window.location.pathname === PATH_PURCHASE_SUCCESS) {
          store.dispatch(reset(ROUTES.PURCHASE_SUCCESS));
          const params = parse(window.location.search);
          if (params[PARAM_SESSION_ID]) {
            Analytics.trackPurchaseEvent({
              sessionId: params[PARAM_SESSION_ID],
              productId: PRODUCT_ID,
              revenue: PRICE,
              currency: CURRENCY,
            });
          }
          checkPurchaseStatus();
          setTimeout(onPurchase, 16000);
        }
      });
  },

  restorePurchases() {
    return Promise.resolve([]);
  },

  getProductDetails(productId) {
    let p = _.find(plans, { id: productId });
    if (p) {
      let price = p.amount / 100;
      let currency = p.currency.toUpperCase();
      return {
        productId,
        priceValue: price,
        currency,
        priceText: getPriceText(price, currency),
        trial: p.trial_period_days,
        haveTrialPeriod: !!p.trial_period_days,
        trialPeriod: p.trial_period_days ? `P${p.trial_period_days}D` : '',
        subscriptionPeriod: `P${p.interval_count}${p.interval.substr(0, 1).toUpperCase()}`,
      };
    }
    else {
      return {
        productId: PRODUCT_ID,
        priceValue: PRICE,
        currency: CURRENCY,
        priceText: getPriceText(PRICE, CURRENCY),
        trial: TRIAL,
        haveTrialPeriod: !TRIAL,
        trialPeriod: TRIAL ? `P${TRIAL}D` : '',
        subscriptionPeriod: PERIOD,
      };
    }
  },

  purchase() {
    return Promise.reject();
  },

  subscribe() {
    const successUrl = window.location.origin + PATH_PURCHASE_SUCCESS + '?' + PARAM_SESSION_ID + '={CHECKOUT_SESSION_ID}';
    const cancelUrl = window.location.origin + PATH_PURCHASE_CANCEL;
    const { auth: { id: profileId, }, } = store.getState();

    return Promise.all([
      initCheckoutSession({
        plan: PRODUCT_ID,
        successUrl,
        cancelUrl,
      })
        .catch(err => null),
      loadStripe(KEY),
    ])
      .then(([session, stripe]) => {
        return stripe.redirectToCheckout(
          session
            ? {
              sessionId: session?.id,
            }
            : {
              items: [
                {
                  plan: PRODUCT_ID,
                  quantity: 1,
                },
              ],
              successUrl,
              cancelUrl,
              clientReferenceId: String(profileId),
            }
        );
      });
  },

  verifyPurchases(purchases) {
    return Promise.resolve(purchases);
  },

};

function checkPurchaseStatus() {
  store.dispatch(initPurchase())
    .then(({ purchased } = {}) => {
      if (purchased) {
        onPurchase();
      }
      else {
        setTimeout(checkPurchaseStatus, 1000);
      }
    });
}

function onPurchase() {
  window.location.replace(window.location.origin);
}


const CURRENCY_SYMBOLS = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};


function getPriceText(price, currency) {
  return CURRENCY_SYMBOLS[currency] ? CURRENCY_SYMBOLS[currency] + price : (price + currency);
}