export const CATEGORIES = {
  LOCKED_SECTION: 'locked_section',
  FIRST_SESSION: 'first_session',
  TAB_BAR: 'tab_bar',
  DEEP_SESSION: 'deep_session',
  VARIATOR: 'variator',
};

export const ACTIONS = {
  CLICK: 'click',
  CLOSE: 'close',
  START: 'start'
};
