import { AppRegistry } from 'react-native';
import firebase from 'firebase/app';
import * as Sentry from '@sentry/browser';
import DeviceProps from '@magnus/react-native-device-props';

import App from '../../src';
import { version } from '../package.json';

import './style.css';
import * as serviceWorker from './serviceWorker';

window.__DEV__ = !!process.env.REACT_APP_DEV;

if (window.location.hostname !== 'localhost') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, });
}

DeviceProps.setAppVersion(version);

firebase.initializeApp({
  apiKey: 'AIzaSyAz5g2cZItG51r7gKL8lyboqmn5MYnFCO8',
  authDomain: 'astroline-a8722.firebaseapp.com',
  databaseURL: 'https://astroline-a8722.firebaseio.com',
  projectId: 'astroline-a8722',
  storageBucket: 'astroline-a8722.appspot.com',
  messagingSenderId: '783993132221',
  appId: '1:783993132221:web:94b3183370a590d6e813d4',
  measurementId: 'G-1S8414GL4X',
});

AppRegistry.registerComponent('astroline', () => App);

AppRegistry.runApplication('astroline', {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

// preload images
(new Image()).src = require('../../assets/images/splash-bg.jpg');
(new Image()).src = require('../../assets/images/bg.jpg');

serviceWorker.register();
