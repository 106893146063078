import moment from "moment";
import _ from "lodash";

export const generateDatesToString = (date, isLong) => {
  let res = '';

  for (let i = 0; i < date.length; i++) {
    if (date[i].start === date[i].end) {
      res += moment(date[i].start, "DD:MM").format(isLong ? "D MMMM" : "D MMM").split(" ").map(el => _.capitalize(el)).join(' ');
    } else {
      if (date[i].start) {
        res += moment(date[i].start, "DD:MM").format(isLong ? "D MMMM" : "D MMM").split(" ").map(el => _.capitalize(el)).join(' ');
      }

      if (date[i].end) {
        res += ' - ';
        res += moment(date[i].end, "DD:MM").format(isLong ? "D MMMM" : "D MMM").split(" ").map(el => _.capitalize(el)).join(' ');
      }
    }


    if (i !== (date.length - 1)) {
      res += ', ';
    }
  }

  return res;
};

export function isToday(date) {
  const today = new Date();
  return (
    today.toLocaleDateString('en-US') ===
    new Date(date).toLocaleDateString('en-US')
  );
}
