import React, { PureComponent } from 'react';
import { StyleSheet, View } from "react-native";
import Column from "./column";
import PropTypes from "prop-types";
import LinearGradient from "react-native-linear-gradient";
import * as COLORS from "../../constants/colors";
import i18n from "../../localization";
import { days, hours, minutes, years } from "../../constants/date-time-arrays";
import moment from "moment";


const dateMode = ['day', 'month', 'year'],
  timeMode = ['hour', 'minute'];

const gradientStart = {x: 0, y: 0};
const gradientEnd = {x: 1, y: 0};

export default class DateTimePicker extends PureComponent {

  static propTypes = {
    mode: PropTypes.oneOf(['date', 'time']),
    height: PropTypes.number,
    visibleValues: PropTypes.number,
    theme: PropTypes.oneOf(['default', 'chat']),
    setValue: PropTypes.func,
    setWasDrag: PropTypes.func,
  };

  static defaultProps = {
    mode: 'date',
    visibleValues: 3,
  };

  constructor(props) {
    const { selectDate, selectTime } = props;
    const selectDateArray = selectDate ? selectDate.split('-') : null;
    const selectTimeArray = selectTime ? selectTime.split(':') : null;
    
    super(props);
    const initIndex = 0;
    const initYearIndex = 71;
    const initMonthIndex = 5;
    const initDayIndex = 14;
    this.state = {
      yearIndex: selectDate ? years.indexOf(selectDateArray[0]) : initYearIndex,
      monthIndex: selectDate ? --selectDateArray[1] : initMonthIndex,
      dayIndex: selectDate ? days.indexOf(selectDateArray[2]) : initDayIndex,
      hourIndex: selectTime ? hours.indexOf(selectTimeArray[0]) : initIndex,
      minuteIndex: selectTime ? minutes.indexOf(selectTimeArray[1]) : initIndex,
      hourData: hours,
      minuteData: minutes,
      yearData: years,
      monthData: i18n.t("SHORT_MONTHS"),
      dayData: days,
      modeArray: props.mode === 'date' ? dateMode : timeMode,
    };
  }

  componentDidMount() {
    this.setValues();
  }

  changeIndex = (name, value) => {
    this.props.setWasDrag();
    this.setState({
      [`${name}Index`]: value
    }, this.setValues);
  };

  setValues = () => {
    const { mode, setValue } = this.props;
    const { monthIndex, dayData, dayIndex, yearData, yearIndex, hourData, hourIndex, minuteData, minuteIndex } = this.state;
    if (mode === 'date') {
      const date = moment(`${monthIndex + 1}-${dayData[dayIndex]}-${yearData[yearIndex]}`, "MM-DD-YYYY", 'en');
      setValue(date);
    } else if (mode === 'time') {
      const time = moment(`${hourData[hourIndex]}:${minuteData[minuteIndex]}`, "HH:mm", 'en');
      setValue(time);
    }
  };

  render() {
    const { mode, height, visibleValues, theme } = this.props;

    if (!mode || !height) return null;

    if (height) {
      const heightRow = height / visibleValues;
      const colorsGradient = theme === 'default' && [COLORS.TRANSPARENT, COLORS.TRANSPARENT] || COLORS.GRADIENT_COLORS_PICKERS;

      return <LinearGradient
        start={gradientStart}
        end={gradientEnd}
        colors={colorsGradient}
        style={styles.root}
      >
        <View style={[styles.currentRow, { top: heightRow, height: heightRow, }]} />
        <View style={styles.container}>
          {
            this.state.modeArray.map((el) => (
              <Column name={el}
                key={el}
                activeIndex={this.state[`${el}Index`]}
                changeIndex={this.changeIndex}
                data={this.state[`${el}Data`]}
                heightRow={heightRow}
                height={height}
                theme={theme}
                visibleValues={visibleValues}
              />
            ))
          }
        </View>
      </LinearGradient>
    }
  }
}

const styles = StyleSheet.create({
  root: {
  },
  container: {
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  currentRow: {
    backgroundColor: COLORS.LIGHTER_PURPLE,
    width: '100%',
    position: 'absolute',
  }
});
