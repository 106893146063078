import { createAction } from 'redux-actions';
import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/hi';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';

import {
  SET_IS_ONBOARDING_COMPLETED,
  SET_ONBOARDING_PROFILE,
  SET_ONBOARDING_STEP,
} from './types';

import { updateProfile } from '../profile/actions';
import { navigate, reset, authNavigate, } from '../navigation/actions';
import { showInterAfterNav } from '../ads/actions';
import { checkAndActivatePaywall } from '../app/actions';

import * as ROUTES from '../../constants/routes';
import { ONBOARDING_SCREENS } from '../../constants/onboarding-types';
import { ACTIONS } from '../../constants/triggers';

import Analytics from '../../modules/analytics';

export const setOnboardingProfile = createAction(SET_ONBOARDING_PROFILE);
export const setOnboardingStep = createAction(SET_ONBOARDING_STEP);
export const setOnboardingCompleted = createAction(SET_IS_ONBOARDING_COMPLETED);

const requiredFields = ['name', 'date', 'gender', 'relationship'];

export function checkOnboardingProfileByFull() {
  return (dispatch, getState) => {
    const { onboardingProfile } = getState().onboarding;
    return !requiredFields.some(el => !onboardingProfile[el]);
  };
}

export function addToOnboardingProfile(data) {
  return (dispatch, getState) => {
    const { onboarding: { onboardingProfile } } = getState();
    dispatch(setOnboardingProfile({ ...onboardingProfile, ...data }));

    const newOnboardingProfile = getState().onboarding.onboardingProfile;

    if (!requiredFields.some(el => !newOnboardingProfile[el])) {
      dispatch(updateProfile(newOnboardingProfile)).then(() => {
        dispatch(setOnboardingProfile({}));
      });
    }
  };
}

function whereNavigateAfterOnboarding() {
  return (dispatch, getState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { whereNavigateAfterOnboarding },
      },
    } = getState();

    if (!dispatch(authNavigate())) {
      dispatch(reset(ROUTES.MAIN));
      if (whereNavigateAfterOnboarding) {
        dispatch(navigate(whereNavigateAfterOnboarding));
      }
    }
  }
}

export function goToNextOnboardingStep() {
  return (dispatch, getState) => {
    const {
      onboarding: { onboardingStep, isOnboardingCompleted },
      remoteConfig: {
        remoteConfigParams: { onboardingFlow, isAdsAfterFinishOnboarding },
      },
    } = getState();
    const nextStep = onboardingStep + 1;

    if (isOnboardingCompleted) {
      return dispatch(whereNavigateAfterOnboarding());
    }

    if (nextStep >= onboardingFlow.length) {
      dispatch(onboardingCompleted());
      dispatch(checkAndActivatePaywall());

      if (isAdsAfterFinishOnboarding) {
        return dispatch(showInterAfterNav(() => {
          dispatch(whereNavigateAfterOnboarding());
        }));
      }
      return dispatch(whereNavigateAfterOnboarding());
    }

    dispatch(setOnboardingStep(nextStep));
    const nextOnboardingScreen = onboardingFlow[nextStep];
    Analytics.trackEvent(`onboarding_${onboardingFlow[onboardingStep].toLowerCase()}`, ACTIONS.CLOSE);
    return dispatch(reset(ONBOARDING_SCREENS[nextOnboardingScreen]));
  };
}

export function onboardingCompleted() {
  return (dispatch, getState) => {
    if (getState().onboarding.isOnboardingCompleted) {
      return;
    }

    Analytics.trackEvent('onboarding_flow', 'success');
    dispatch(setOnboardingCompleted(true));
  }
}
