import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Image } from 'react-native';

import CloseButton from '../../../components/close-button';
import { BACK_ICONS } from '../../../constants/icons';
import { connect } from 'react-redux';

class CloseButtonWithTimeout extends PureComponent {
  static propTypes = {
    closePress: PropTypes.func,
    closeButtonTimeout: PropTypes.number,
  };

  static defaultProps = {
    closeButtonTimeout: 0,
  };

  constructor(props) {
    super(props);
    if (_.isNumber(props.closeButtonTimeout) && props.closeButtonTimeout > 0) {
      this.state = {
        showCloseButton: false,
      };
      this.timeoutId = setTimeout(() => {
        this.setState(
          {
            showCloseButton: true,
          },
          () => {
            clearInterval(this.timeoutId);
          },
        );
      }, props.closeButtonTimeout * 1000);
    } else {
      this.state = {
        showCloseButton: true,
      };
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }

  render() {
    if (this.state.showCloseButton && !this.props.paywall) {
      return <CloseButton
        onPress={this.props.closePress}
        source={this.props.source || BACK_ICONS}
        style={this.props.style}
      />;
    }
    return null;
  }
}

export default connect(
  state => ({
    paywall: state.app.paywall,
  }),
)(CloseButtonWithTimeout);
