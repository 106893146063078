import { BEHAVIOR_FOR_PUSHES } from '../../constants/messaging';

import Analytics from '../../modules/analytics';
import Messaging from '../../modules/messaging';


export function initNotifications() {
  return function (dispatch, getState) {
    const { remoteConfig, purchases } = getState();
    const pushesBehavior = remoteConfig.remoteConfigParams.pushesBehavior;
    const { purchased } = purchases;

    Messaging.unscheduleAllNotifications();


    switch (pushesBehavior) {
      case BEHAVIOR_FOR_PUSHES.ALL:
        return scheduleNotifications();
      case BEHAVIOR_FOR_PUSHES.THREE_DAYS_AGO_PURCHASE:
        if (purchased) {
          return dispatch(scheduleTrialUsersNotifications());
        }
      default:
        if (!purchased) {
          return scheduleNotifications();
        }
    }
  };
}

function scheduleNotifications() {
  const sessionNumber = Analytics.getSessionNumber();
  if (!sessionNumber) {
    Messaging.scheduleFirstSessionNotifications();
  }
  Messaging.scheduleEveryEveningNotification();
  Messaging.scheduleEveryMorningNotification();
}

function scheduleTrialUsersNotifications() {
  return function (dispatch, getState) {
    if (dispatch(isFinished3PurchasedDays())) {
      const { purchaseDate } = getState().purchases;
      const finished3PurchasedDays = new Date(purchaseDate);
      finished3PurchasedDays.setDate(finished3PurchasedDays.getDate() + 3);

      Messaging.scheduleEveryEveningNotification(finished3PurchasedDays);
      Messaging.scheduleEveryMorningNotification(finished3PurchasedDays);
    } else {
      scheduleNotifications();
    }
  };
}

function isFinished3PurchasedDays() {
  return function (dispatch, getState) {
    const { purchaseDate } = getState().purchases;
    const finished3PurchasedDays = new Date(purchaseDate);
    finished3PurchasedDays.setDate(finished3PurchasedDays.getDate() + 3);

    return finished3PurchasedDays && Date.now() > finished3PurchasedDays;
  };
}

export function showNotification() {
  return function (dispatch, getState) {
    const { purchased } = getState().purchases;
    if (!purchased) {
      return true;
    }

    const pushesBehavior = getState().remoteConfig.remoteConfigParams.pushesBehavior;

    return BEHAVIOR_FOR_PUSHES.THREE_DAYS_AGO_PURCHASE === pushesBehavior
      ? dispatch(isFinished3PurchasedDays())
      : true;
  };
}
