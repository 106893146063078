import { Platform } from 'react-native';
import i18n from "../localization";

import * as NSUserActivityTypes from '../constants/ns-user-activity-types';

export const EVENT_NAME = 'SiriShortcutListener';

export default {

  supported: Platform.OS === 'ios' && +Platform.Version.split('.').shift() >= 12,

  getHoroscopeShortcut: () => {
    return {
      activityType: NSUserActivityTypes.HOROSCOPE, // Has to be set in `NSUserActivityTypes` in Info.plist first
      title: i18n.t('SIRI_SHORTCUTS.HOROSCOPE.TITLE'),
      persistentIdentifier: NSUserActivityTypes.HOROSCOPE,
      isEligibleForSearch: true,
      isEligibleForPrediction: true,
      suggestedInvocationPhrase: i18n.t('SIRI_SHORTCUTS.HOROSCOPE.PHRASE'),
    };
  },

};
