import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import i18n from '../localization';
import {checkInternetConnection} from "../store/app/actions";
import ErrorModal from '../components/error-modal';

class NoInternetModal extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  handlePress = () => {
    const {onPress} = this.props;
    this.setState({isLoading: true}, () => {
      onPress().finally(() => {
        this.setState({isLoading: false});
      });
    });
  };

  render() {
    return (
      <ErrorModal
        onPress={this.handlePress}
        isLoading={this.state.isLoading}
        buttonTitle={i18n.t('ERROR_MODAL.RETRY')}
        title={i18n.t('ERROR_MODAL.NOT_CONNECT')}
        subtitle={i18n.t('ERROR_MODAL.PLEASE_CHECK')}
      />
    );
  }
}

export default connect(
  null,
  dispatch => ({
    onPress: () => dispatch(checkInternetConnection()),
  })
)(NoInternetModal);
