import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Image, } from 'react-native';
import { createAnimatableComponent } from 'react-native-animatable';

import i18n from '../../../localization';
import Button from '../../../components/button';
import MyDatePicker from '../../../components/datetime-picker';
import { newHorizontalScale, newVerticalScale } from '../../../utils/scale';
import * as ICONS from '../../../constants/icons';

const AnimatedView = createAnimatableComponent(View);

export default class DateSelect extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    setValue: PropTypes.func,
    onPress: PropTypes.func,
    isValidDate: PropTypes.bool,
    theme: PropTypes.oneOf(['default', 'chat']),
    mode: PropTypes.string,
    isLoading: PropTypes.bool,
    isOnboarding: PropTypes.bool,
  };

  static defaultProps = {
    mode: 'date',
    theme: 'default',
  };

  state = {
    wasDrag: false,
    isHideIcon: false,
    isNotIcon: true,
  };

  showAnimatedIcon = () => {
    this.setState({
      isHideIcon: false,
      isNotIcon: false,
    });
  };

  hideIcon = () => {
    this.setState({ isNotIcon: true, });
  };

  hideAnimatedIcon = () => {
    this.setState({ isHideIcon: true, });
    setTimeout(this.showAnimatedIcon,4000);
  };

  setWasDrag = () => {
    if (!this.state.wasDrag) {
      this.setState({ wasDrag: true, });
    }
  };

  setVisibleHand = () => {
    this.setState({ isNotIcon: false, });
  };

  render() {
    const {
      height,
      onPress,
      setValue,
      isValidDate,
      mode,
      theme,
      isLoading,
      selectDate,
      isSettings,
      isOnboarding,
    } = this.props;

    const {
      wasDrag,
      isHideIcon,
      isNotIcon,
    } = this.state;

    return (
      <View style={styles.container}>
        <AnimatedView
          animation={'fadeInUpBig'}
          duration={1200}
          easing={'ease-out'}
          style={styles.wrapper}
          onAnimationEnd={this.setVisibleHand}
        >
          <MyDatePicker
            setWasDrag={this.setWasDrag}
            mode={mode}
            height={height}
            setValue={setValue}
            theme={theme}
            selectDate={selectDate}
          />
          <View style={isSettings
            ? [styles.startBtnWrap, styles.startBtnWrapSettings]
            : styles.startBtnWrap}
          >
            <Button
              vibration
              disabled={!isValidDate}
              isSpinner={isLoading}
              onPress={isValidDate && onPress || undefined}
              title={isSettings
                ? i18n.t('SETTINGS.SAVE')
                : i18n.t('ONBOARDING_CHAT.NEXT')}
            />
          </View>
        </AnimatedView>
        {
          (!isOnboarding || wasDrag || isNotIcon) ?
            null
            :
            <AnimatedView
              animation={isHideIcon ? 'fadeOut' : undefined}
              easing={'ease-out'}
              direction="alternate"
              useNativeDriver={true}
              style={styles.iconWrapper}
              onAnimationEnd={this.hideIcon}
            >
              <AnimatedView
                animation={'slideInDown'}
                iterationCount={5}
                direction="alternate"
                useNativeDriver={true}
                onAnimationEnd={this.hideAnimatedIcon}
              >
                <Image
                  source={ICONS.ONBOARDING_HAND}
                  pointerEvents={'none'}
                  style={styles.icon}
                  resizeMode={'contain'}
                />
              </AnimatedView>
            </AnimatedView>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    marginVertical: 20,
    position: 'relative',
  },
  wrapper: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  iconWrapper: {
    width: '100%',
    position: 'absolute',
    top: newVerticalScale(100),
    height: newVerticalScale(80),
    paddingLeft: '45%',
  },
  icon: {
    height: newVerticalScale(80),
    width: newHorizontalScale(120),
  },
  startBtnWrap: {
    marginHorizontal: 15,
    marginTop: 20,
  },
  startBtnWrapSettings: {
    marginTop: newVerticalScale(150),
  }
});
