import React, { PureComponent, } from 'react';
import { View, StyleSheet, } from 'react-native';
import PropTypes from 'prop-types';
import Interactable from 'react-native-interactable';
import _ from 'lodash';

import * as COLORS from '../../constants/colors';

const SNAP_POINTS_PREFIX = 'sp_';

export default class Swiper extends PureComponent {

  static propTypes = {
    children: PropTypes.array,
    onIndexChanged: PropTypes.func,
    autoplay: PropTypes.bool,
    vertical: PropTypes.bool,
    visibleValues: PropTypes.number,
  };

  static defaultProps = {
    visibleValues: 1,
  };

  state = {
    activeIndex: 0,
    snapPoints: [],
    slides: [],
    containerWidth: 0,
    containerHeight: 0,
    slidesWrapperStyle: {},
    paddingTop: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    const { children: oldChildren, } = prevProps,
      { children: newChildren, vertical, } = this.props,
      { containerWidth: oldCW, containerHeight: oldCH, } = prevState,
      { containerWidth: newCW, containerHeight: newCH, } = this.state;
    if (
      newCW !== oldCW ||
      newCH !== oldCH ||
      newChildren !== oldChildren
    ) {
      const orientation = vertical ? 'y' : 'x',
        containerSizeDimention = vertical ? 'height' : 'width',
        direction = vertical ? 'column' : 'row',
        containerSize = (vertical ? newCH : newCW) / this.props.visibleValues;
      this.setState({
        slides: newChildren.map((component, index) => {
          return (
            <View key={`${SNAP_POINTS_PREFIX}${index}`} style={{ [containerSizeDimention]: containerSize, }}>{component}</View>
          );
        }),
        snapPoints: newChildren.map((slide, index) => {
          return {
            id: `${SNAP_POINTS_PREFIX}${index}`,
            [orientation]: containerSize * index * -1,
          };
        }),
        paddingTop: containerSize,
        slidesWrapperStyle: {
          flex: !vertical ? 1 : 0,
          [containerSizeDimention]: (newChildren.length - 1 + this.props.visibleValues) * containerSize,
          flexDirection: direction,
          paddingVertical: containerSize,
        },
      });
    }
  }

  render() {
    const {
      style,
      vertical,
      initialPosition,
    } = this.props;
    const {
      snapPoints,
      slides,
      slidesWrapperStyle,
    } = this.state;

    return (
      <View
        style={[styles.container, style,]}
        onLayout={this.handleContainerLayout}
      >
        <Interactable.View
          horizontalOnly={!vertical}
          verticalOnly={vertical}
          style={slidesWrapperStyle}
          snapPoints={snapPoints}
          onDrag={this.handleSlidesDrag}
          initialPosition={{ y: initialPosition }}
        >
          {slides}
        </Interactable.View>
      </View>
    );
  }

  handleSlidesDrag = (e) => {
    const { targetSnapPointId, } = e = e.nativeEvent || e;
    if (targetSnapPointId) {
      const index = this.state.snapPoints.findIndex((sp) => {
        return sp.id === targetSnapPointId;
      });

      if (index !== undefined) {
        this.updateActiveIndex(index);
      }
    }
  };

  updateActiveIndex = (index) => {
    const { onIndexChanged, } = this.props;

    onIndexChanged && onIndexChanged(index);
    this.setState({
      activeIndex: index,
    });
  };

  handleContainerLayout = ({ nativeEvent: { layout: { width, height, }, }, }) => {
    this.setState({
      containerWidth: width,
      containerHeight: height,
    });
  };
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  pagination: {
    alignSelf: 'center',
    flexDirection: 'row',
    position: 'absolute',
  },
  paginationVertical: {
    flexDirection: 'column',
    right: 10,
  },
  dot: {
    backgroundColor: COLORS.WHITE,
    width: 10,
    height: 10,
    borderRadius: 5,
    margin: 2.5,
  },
  activeDot: {
    backgroundColor: COLORS.GREY,
  },
});
