import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Dimensions, Animated, Platform } from 'react-native';

import * as IMAGES from '../../../constants/images';

const { height, width } = Dimensions.get('window');

export default class AnimatedBG extends PureComponent {
  static propTypes = {
    source: PropTypes.any,
    children: PropTypes.node,
    visible: PropTypes.bool,
    duration: PropTypes.number,
  };

  static defaultProps = {
    source: IMAGES.SUBSCRIPTION_BG,
    duration: 80000,
    children: null,
    visible: false,
  };

  bgImage = React.createRef();
  playing = false;

  state = {
    left: new Animated.Value(0),
    visible: true,
  };

  componentDidMount() {
    this.onAnimated();
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible } = this.props;
    if (prevProps.visible !== visible) {
      this.setState({ visible }, () => {
        if (visible) {
          this.onAnimated();
        }
      });
    }
  }

  onAnimated = () => {
    const { duration } = this.props;
    const { left, visible } = this.state;

    if (!visible) {
      this.playing = false;
      return;
    }

    if (this.playing) {
      return;
    }

    this.playing = true;

    Animated.sequence([
      Animated.timing(left, {
        toValue: -height + width,
        duration: duration,
      }),
      Animated.timing(left, {
        toValue: 0,
        duration: duration,
      }),
    ]).start(this.finishedAnimation);
  };

  finishedAnimation = () => {
    this.playing = false;
    this.onAnimated();
  };

  render() {
    const { left, visible } = this.state;
    const { children, source } = this.props;

    return (
      <View style={styles.root}>
        {visible ?
          <View style={styles.bg}>
            <Animated.Image
              ref={this.bgImage}
              source={source}
              style={[styles.img, { left }]}
            />
          </View>
          : null}
        <View style={styles.content}>
          {children}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  img: {
    position: 'absolute',
    top: 0,
    height: height,
    width: height,
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: Platform.OS === 'web' ? 'auto' : 'scroll',
  },
});
