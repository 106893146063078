import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createReactNavigationReduxMiddleware } from 'react-navigation-redux-helpers';
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-community/async-storage';
import { persistReducer } from 'redux-persist';

import navigation from './navigation';
import profile from './profile';
import remoteConfig from './remote-config';
import app from './app';
import auth from './auth';
import horoscope from './horoscope';
import tarot from './tarot';
import physiognomy from './physiognomy';
import purchases from './purchases';
import palmReading from './palm-reading';
import compatibility from './compatibility';
import siriShortcuts from './siri-shorcuts';
import rating from './rate-us';
import ads from './ads';
import unlockContent from './unlock-content';
import onboarding from './onboarding';
import customAppIcon from './custom-app-icon';
import modals from './modals';
import badges from './badges';
import localization from './localization';

export default function configureStore() {
    const navigationMiddleware = createReactNavigationReduxMiddleware(
        'root',
        state => state.navigation,
    );

    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;

    const persistConfigs = {
        siriShortcuts: {
            key: 'siri-shortcuts',
            storage: AsyncStorage,
            blacklist: ['suggestionVisible', 'nsUserActivityType'],
        },
        rating: {
            key: 'rating',
            storage: AsyncStorage,
            blacklist: ['disabledForSession', 'visible'],
        },
        onboarding: {
        key: 'onboarding',
        storage: AsyncStorage,
        blacklist: [],
      },
      purchases: {
        key: 'purchases',
        storage: AsyncStorage,
        whitelist: ['purchaseDate'],
      },
      horoscope: {
        key: 'horoscope',
        storage: AsyncStorage,
        whitelist: ['zodiacAnimationCount'],
      },
      badges: {
        key: 'badges',
        storage: AsyncStorage,
        blacklist: [],
      },
      customAppIcon: {
        key: 'customAppIcon',
        storage: AsyncStorage,
        blacklist: [],
      },
    };

    const rootReducer = combineReducers({
        navigation,
        profile,
        remoteConfig,
        ads,
        app,
        tarot,
        palmReading,
        auth,
        physiognomy,
        compatibility,
        unlockContent,
        modals,
        localization,
        horoscope: persistReducer(persistConfigs.horoscope, horoscope),
        badges: persistReducer(persistConfigs.badges, badges),
        purchases: persistReducer(persistConfigs.purchases, purchases),
        onboarding: persistReducer(persistConfigs.onboarding, onboarding),
        siriShortcuts: persistReducer(persistConfigs.siriShortcuts, siriShortcuts),
        rating: persistReducer(persistConfigs.rating, rating),
        customAppIcon: persistReducer(persistConfigs.customAppIcon, customAppIcon),
    });

    const store = createStore(
        rootReducer,
        {},
        composeEnhancers(applyMiddleware(navigationMiddleware, thunk)),
    );

    return store;
}
