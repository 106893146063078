import {
  SET_REWARDED_LOADED,
  SET_REWARDED_ACCESSIBLE,
  SET_REWARDED_PENDING,
  SET_INTERSTITIAL_LOADED,
  SET_INTERSTITIAL_PENDING,
  SET_INTERSTITIAL_TIMEOUT,
  SET_LAST_TYPE_ADS,
  SET_IS_DASHBOARD_LOADED
} from "./types";

const initialState = {
  rewardedLoaded: false, // Boolean
  rewardedAccessible: true, // Boolean
  rewardedPending: false, // Boolean
  interstitialLoaded: false,
  interstitialPending: false,
  interstitialTimeoutUntil: 0,
  rewardedFirstLoaded: false,
  isDashboardLoaded: false,
  lastTypeAds: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_REWARDED_LOADED:
      state = {
        ...state,
        rewardedLoaded: action.status,
      };
      break;

    case SET_REWARDED_ACCESSIBLE:
      state = {
        ...state,
        rewardedAccessible: action.status,
      };
      break;

    case SET_REWARDED_PENDING:
      state = {
        ...state,
        rewardedPending: action.pending,
      };
      break;

    case SET_INTERSTITIAL_LOADED:
      state = {
        ...state,
        interstitialLoaded: action.status,
      };
      break;

    case SET_INTERSTITIAL_PENDING:
      state = {
        ...state,
        interstitialPending: action.pending,
      };
      break;

    case SET_INTERSTITIAL_TIMEOUT:
      state = {
        ...state,
        interstitialTimeoutUntil: action.interTimeout,
      };
      break;

    case SET_LAST_TYPE_ADS:
      state = {
        ...state,
        lastTypeAds: action.typeAds,
      };
      break;
  }

  return state;
}
