import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View } from 'react-native';
import * as ICONS from '../../../constants/icons';
import i18n from '../../../localization';
import Text from '../../../components/text';
import Touchable from '../../../components/touchable';
import { newHorizontalScale, newVerticalScale } from '../../../utils/scale';
import * as COLORS from '../../../constants/colors';
import { connect } from 'react-redux';

class FeaturesWithIcons extends PureComponent {
  static propTypes = {
    onTryLimitedPress: PropTypes.func,
    isTryLimited: PropTypes.bool,
    isExtra: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.advantages = [
      {
        key: 'horoscope',
        icon: ICONS.HOROSCOPE_ADVANTAGE_BLUE,
      },
      {
        key: 'palm',
        icon: ICONS.PALM_ADVANTAGE_BLUE,
      },
      {
        key: 'physiognomy',
        icon: ICONS.PHYSIOGNOMY_ADVANTAGE_BLUE,
      },
    ]
  }

  render() {
    const { onTryLimitedPress, isTryLimited, paywall } = this.props;

    return <View style={styles.list}>
      {
        this.advantages?.map((item, index) => {
          return (
            <View
              style={[styles.listItemContainer, index === (this.advantages.length - 1) && !isTryLimited && styles.listLastItemContainer]}
              key={item.key}
            >
              <Image
                style={styles.listItemIcon}
                source={item.icon}
                resizeMode={'contain'}
              />
              <View>
                <Text fontWeightImportant style={styles.listItemText}>
                  {i18n.t(`ONBOARDING.EXTRA_SUBSCRIPTION.${item.key.toUpperCase()}.FIRST_LINE`)}
                </Text>
                <Text fontWeightImportant style={styles.listItemText}>
                  {i18n.t(`ONBOARDING.EXTRA_SUBSCRIPTION.${item.key.toUpperCase()}.SECOND_LINE`)}
                </Text>
              </View>
            </View>
          );
        })
      }
      {
        isTryLimited && !paywall
          ?
          <View style={[styles.listItemContainer, styles.listLastItemContainer]}>
            <Text fontWeightImportant style={styles.listItemText}>
              {i18n.t('ONBOARDING.EXTRA_SUBSCRIPTION.LIMITED_VERSION')}
            </Text>
            <Touchable onPress={onTryLimitedPress}>
              <Text fontWeightImportant style={[styles.listItemText, styles.listItemTouchable]}>
                {i18n.t('ONBOARDING.EXTRA_SUBSCRIPTION.LIMITED_VERSION_UNDERLINE')}
              </Text>
            </Touchable>
          </View>
          : null
      }
    </View>
  }
}

export default connect(
  state => ({ paywall: state.app.paywall })
)(FeaturesWithIcons);

const styles = StyleSheet.create({
  list: {
    flex: 1,
    justifyContent: 'center',
    minHeight: 200,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: newVerticalScale(10),
  },
  listLastItemContainer: {
    marginBottom: 0,
  },
  listItemIcon: {
    width: newHorizontalScale(46),
    height: newVerticalScale(46),
    overflow: 'visible',
    marginRight: newHorizontalScale(16),
  },
  listItemText: {
    color: COLORS.WHITE,
    fontSize: newVerticalScale(17, null, 16),
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2, },
    textShadowRadius: 2,
  },
  listItemTouchable: {
    textDecorationLine: 'underline',
    marginLeft: newHorizontalScale(14),
  },
});
