import { createStackNavigator } from 'react-navigation';

import * as ROUTES from '../../constants/routes';
import * as COLORS from '../../constants/colors';

import {

  Dev,
  Auth,

  Loading,

  OnboardingDate,
  OnboardingLoader,
  OnboardingGender,
  OnboardingName,
  OnboardingRelationship,
  OnboardingSubscriptionFirst,
  OnboardingSubscriptionSecond,
  OnboardingTakeAPhoto,
  OnboardingTime,
  OnboardingExtraSubscription,
  OnboardingPersonalSubscription,
  OnboardingLandingSubscription,

  PalmPhoto,
  Dashboard,
  Horoscopes,
  MagicBall,
  Biorithm,
  PalmReadingScreen,
  ErrorPhotoScreen,
  Tarot,
  Physiognomy,
  Compatibility,
  CompatibilityResult,

  Advantages,
  HoroscopeView,
  Settings,
  ProfileSettings,
  NameSettings,
  GenderSettings,
  RelationshipSettings,
  DateSettings,
  TimeSettings,
  PrivacyPolicy,
  Terms,
  LanguageSettings,
  CancelSubscription,
  SubscriptionAd,
  SubscriptionThird,
  PurchaseSuccess,
  Divinations,

} from './index-screens';

import BottomTabBar from './bottom-tab-bar';


const AppNavigator = createStackNavigator(
  {
    [ROUTES.TAROT]: Tarot,
    [ROUTES.DIVINATIONS]: Divinations,
    [ROUTES.PHYSIOGNOMY]: Physiognomy,
    [ROUTES.LOADING]: Loading,
    [ROUTES.DASHBOARD]: Dashboard,
    [ROUTES.HOROSCOPES]: Horoscopes,
    [ROUTES.HOROSCOPE]: HoroscopeView,
    [ROUTES.BIORITHM]: Biorithm,
    [ROUTES.MAGIC_BALL]: MagicBall,
    [ROUTES.PALM_PHOTO]: PalmPhoto,
    [ROUTES.ONBOARDING_DATE]: OnboardingDate,
    [ROUTES.ONBOARDING_GENDER]: OnboardingGender,
    [ROUTES.ONBOARDING_NAME]: OnboardingName,
    [ROUTES.ONBOARDING_RELATIONSHIP]: OnboardingRelationship,
    [ROUTES.ONBOARDING_LOADER]: OnboardingLoader,
    [ROUTES.ONBOARDING_SUBSCRIPTION_FIRST]: OnboardingSubscriptionFirst,
    [ROUTES.ONBOARDING_SUBSCRIPTION_SECOND]: OnboardingSubscriptionSecond,
    [ROUTES.ONBOARDING_PERSONAL_SUB]: OnboardingPersonalSubscription,
    [ROUTES.ONBOARDING_SUB_LANDING]: OnboardingLandingSubscription,
    [ROUTES.SUBSCRIPTION_THREE_PRODUCTS]: SubscriptionThird,
    [ROUTES.PURCHASE_SUCCESS]: PurchaseSuccess,
    [ROUTES.ONBOARDING_TAKE_A_PHOTO]: OnboardingTakeAPhoto,
    [ROUTES.ONBOARDING_TIME]: OnboardingTime,
    [ROUTES.PALM_READING]: PalmReadingScreen,
    [ROUTES.ERROR_TAKE_PHOTO]: ErrorPhotoScreen,
    [ROUTES.ONBOARDING_EXTRA_SUB]: OnboardingExtraSubscription,
    [ROUTES.COMPATIBILITY]: Compatibility,
    [ROUTES.COMPATIBILITY_RESULT]: CompatibilityResult,
    [ROUTES.SETTINGS]: Settings,
    [ROUTES.PROFILE_SETTINGS]: ProfileSettings,
    [ROUTES.NAME_SETTINGS]: NameSettings,
    [ROUTES.GENDER_SETTINGS]: GenderSettings,
    [ROUTES.RELATIONSHIP_SETTINGS]: RelationshipSettings,
    [ROUTES.DATE_SETTINGS]: DateSettings,
    [ROUTES.TIME_SETTINGS]: TimeSettings,
    [ROUTES.PRIVACY_POLICY]: PrivacyPolicy,
    [ROUTES.TERMS]: Terms,
    [ROUTES.LANGUAGE_SETTINGS]: LanguageSettings,
    [ROUTES.WATCH_ADD_SCREEN]: SubscriptionAd,
    [ROUTES.ADVANTAGES]: Advantages,
    [ROUTES.DEV]: Dev,
    [ROUTES.AUTH]: Auth,
    [ROUTES.SETTINGS_CANCEL_SUBSCRIPTION]: CancelSubscription,
    [ROUTES.MAIN]: {
      screen: BottomTabBar,
      navigationOptions: {
        header: null,
      },
    },
  },
  {
    headerMode: 'none',
    defaultNavigationOptions: {
      header: null,
    },
    cardStyle: {
      backgroundColor: COLORS.BG,
    },
  }
);

export default AppNavigator;
