import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import i18n from "../../../../localization";
import { DIFFICULT, FREE, MARRIED, SOULMATE } from "../../../../constants/profile";
import Button from "./button";

const love = [FREE, MARRIED, SOULMATE, DIFFICULT];

export default class Index extends PureComponent {

  static propTypes = {
    onPress: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  render() {
    const { 
      onPress, 
      isLoading, 
      selectRelationship 
    } = this.props;

    return (
      <View style={styles.container}>
        {love.map(el => {
          const title = i18n.t(`LOVE.${el}`);
          return <View style={styles.wrapper} key={title}>
            <Button
              isLoading={isLoading}
              title={title}
              relationship={el}
              onPress={onPress}
              isUnselect={selectRelationship && selectRelationship !== el}
            />
          </View>;
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 13,
    paddingHorizontal: 5,
    position: 'relative',
  },
  wrapper: {
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 7,
    width: '50%',
  },
});
