import api from './index';
import i18n, { DEFAULT_CONTENT_LOCALES } from '../localization';
import { GENDERS_IDS, MARITAL_STATUS_IDS } from "../constants/profile";
import moment from "moment";

const PROFILE = '/profile';

export function update({ name, date, gender, relationship, lang, email, }) {
  return api.fetch(PROFILE, {
    method: 'PUT',
    data: {
      name,
      gender: GENDERS_IDS.indexOf(gender),
      birthdate: date,
      marital_status: MARITAL_STATUS_IDS.indexOf(relationship),
      utc_offset: parseInt(moment().format("Z"), 10),
      lang: !!lang
        ? lang
        : DEFAULT_CONTENT_LOCALES.includes(i18n.locale)
          ? i18n.locale
          : i18n.defaultLocale,
      email,
    },
  });
}

export function getProfile() {
  return api.fetch(PROFILE, {
    method: 'GET'
  });
}

export function removeProfile() {
  return api.fetch(PROFILE, {
    method: 'DELETE'
  });
}
