import AsyncStorage from '@react-native-community/async-storage';

const KEY_PREFIX = "@ASTROLINE_STORE:";

export function fetchItem(key) {
  return new Promise((resolve, reject) => {
    AsyncStorage.getItem(KEY_PREFIX + key)
      .then(value => {
        try {
          resolve(JSON.parse(value));
        } catch (ex) {
          reject(ex);
        }
      })
      .catch(reject);

    if (__DEV__) {
      setTimeout(() => {
        if (resolve) {
          resolve();
        }
      }, 1000);//todo change to 5000
    }
  });
}

export function pushItem(key, value) {
  return AsyncStorage.setItem(KEY_PREFIX + key, JSON.stringify(value));
}

export function removeItem(key) {
  return AsyncStorage.removeItem(KEY_PREFIX + key);
}

export default {
  fetchItem,
  pushItem,
  removeItem,
};
