import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import i18n from '../localization';
import ErrorModal from '../components/error-modal';
import Analytics from '../modules/analytics';

export default class DisconnectModal extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  componentDidMount() {
    Analytics.trackEvent('Disconnect_Retry', 'Open');
  }

  handlePress = () => {
    const {onPress} = this.props;
    this.setState({isLoading: true}, () => {
      onPress().finally(() => {
        this.setState({isLoading: false});
      });
    });
  };

  render() {
    return (
      <ErrorModal
        onPress={this.handlePress}
        isLoading={this.state.isLoading}
        buttonTitle={i18n.t('ERROR_MODAL.RETRY')}
        title={i18n.t('ERROR_MODAL.DISCONNECT')}
        subtitle={i18n.t('ERROR_MODAL.SOME_PROBLEMS')}
      />
    );
  }
}
