import { SET_BADGE_SHOWED, SET_LAST_VISITED_DAILY_DATE } from './types';

const initialState = {
  badgesShowed: [],
  lastVisitedDailyDate: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_BADGE_SHOWED:
      return {
        ...state,
        badgesShowed: [...state.badgesShowed, action.payload],
      };

    case SET_LAST_VISITED_DAILY_DATE:
      return {
        ...state,
        lastVisitedDailyDate: action.payload,
      };

    default:
      return state;
  }
}
