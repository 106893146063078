import { Platform } from 'react-native';

import { SKU } from '../constants/billing';
import api from './index';

const PURCHASE = '/purchase';
const REGISTER = '/register';
const CANCEL = '/cancel-subscription';
const SUBS = '/subscriptions';
const WEB_SUBS = '/subscriptions?type=stripe';
const SESSION = '/checkout-session';
const SETUP_INTENT = '/setup-intent';
const CREATE_CUSTOMER = '/create-customer';
const PLANS = '/plans';

export function getSubs() {
  return api.fetch(PURCHASE + (Platform.OS === 'web' ? WEB_SUBS : SUBS), {
    method: 'GET',
  })
    .then(data => {
      if (Platform.OS === 'web') {
        return data.filter(item => !!item.data && item.status === 'active')
          .map(el => ({ ...el, ...el.data[0], productId: SKU, }));
      }
      else {
        return data.filter(item => !!item.data)
          .map(el => ({ ...el, ...el.data[0], }));
      }
    });
}

export function registerSub(data) {
  return api.fetch(PURCHASE + REGISTER, {
    method: 'POST',
    data: {
      data
    }
  });
}

export function cancelSub(id) {
  return api.fetch(`${PURCHASE + CANCEL}?subscription_id=${id}`, {
    method: 'POST'
  });
}

export function initCheckoutSession(data) {
  return api.fetch(PURCHASE + SESSION, {
    method: 'POST',
    data,
  });
}

export function initSetupIntent() {
  return api.fetch(PURCHASE + SETUP_INTENT, {
    method: 'GET',
  });
}

export function createCustomer(data) {
  return api.fetch(PURCHASE + CREATE_CUSTOMER, {
    method: 'POST',
    data,
  });
}

export function getPlans() {
  return api.fetch(PURCHASE + PLANS, {
    method: 'GET',
  });
}
