import api from './index';

const HOROSCOPES = '/horoscope';
const ALL_SIGNS = '/all-signs';

export function getHoroscope(horoscope_type, sign) {
  return api.fetch(`${HOROSCOPES}?horoscope_type=${horoscope_type}&sign=${sign}`, {
      method: 'GET',
    });
}

export function getAllSigns() {
  return api.fetch(HOROSCOPES + ALL_SIGNS, {
    method: 'GET',
  },);
}
