import React, { PureComponent } from 'react';
import {
  StyleSheet,
  View,
  Image,
  StatusBar, Platform,
} from 'react-native';
import { connect } from 'react-redux';
import { ifIphoneX } from 'react-native-iphone-x-helper';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  verticalScale,
  horizontalScale,
  textSubscription,
} from '../../utils/scale';
import CloseButtonWithTimeout from './components/close-button-with-timeout';
import { CLOSE_ICON } from '../../constants/icons';
import * as IMAGES from '../../constants/images';
import * as COLORS from '../../constants/colors';
import i18n from '../../localization';
import Text from '../../components/text';
import { getButtonDefaultInfoFromProduct } from '../../modules/billing/conveter';
import SubBottomWrapper from './components/subscription-bottom-wrapper';
import Analytics from '../../modules/analytics';
import AnimatedBG from '../tarot/components/animated-bg';
import ZoomInWrapper from '../../components/zoom-in-wrapper';
import * as ROUTES from '../../constants/routes';

const selections = (colorSubscriptionButtons) => [
  {
    source: _.get(IMAGES, `HAND_${colorSubscriptionButtons.toUpperCase()}`),
    title: 'PALM.TITLE',
  },
  {
    source: _.get(IMAGES, `TAROT_${colorSubscriptionButtons.toUpperCase()}`),
    title: 'DASHBOARD.DIVINATIONS_TEXTS.TAROT',
  },
  {
    source: _.get(IMAGES, `FACE_${colorSubscriptionButtons.toUpperCase()}`),
    title: 'FACE.TITLE',
  },
  {
    source: _.get(IMAGES, `GENDER_${colorSubscriptionButtons.toUpperCase()}`),
    title: 'COMPATIBILITY.TITLE',
  },
];

const selectionsSize = selections('').length;

class SubscriptionSecond extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    remoteConfig: PropTypes.object,
    product: PropTypes.string,
    onClosePress: PropTypes.func.isRequired,
    navigation: PropTypes.object,
    analyticsType: PropTypes.string.isRequired,
    activeRoute: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const productDetails = getButtonDefaultInfoFromProduct(props.product);

    this.state = {
      productDetails,
      products: [productDetails],
      selectorCount: 0,
      activeRouteName: '',
    };
  }

  componentDidMount() {
    const { analyticsType, navigation } = this.props;

    Analytics.trackEvent(analyticsType, 'Open');
    this.setState({ activeRouteName: navigation.activeRouteName }, this.onTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeRouteName !== prevProps.navigation.activeRouteName && prevState.activeRouteName === this.props.navigation.activeRouteName) {
      this.onTimer();
    }
  }

  onTimer = () => {
    setTimeout(() => {
      if (this.props.navigation.activeRouteName === this.state.activeRouteName) {
        this.setState({ selectorCount: (this.state.selectorCount + 1) % selectionsSize }, this.onTimer);
      }
    }, 2000);
  };

  render() {
    const {
      isOnboardingSubscriptionSecondTryLimited,
      onboardingSubscriptionSecondButtonText,
      pricePosition,
      onboardingSubscriptionSecondCloseButtonTimeout,
      colorSubscriptionButtons,
    } = this.props.remoteConfig,
      pricePlace = pricePosition,
      { productDetails, products, selectorCount } = this.state;
    const backScreen = _.get(this.props, 'navigation.state.params.backScreen', false);

    return (
      <View style={styles.root}>
        <AnimatedBG visible={this.props.activeRoute}>
          <View style={styles.container}>
            <StatusBar barStyle='light-content' />
            <CloseButtonWithTimeout
              closePress={this.onCloseButtonPress}
              source={CLOSE_ICON}
              closeButtonTimeout={onboardingSubscriptionSecondCloseButtonTimeout}
            />
            <View style={styles.titleWrapper}>
              <Text fontWeightImportant fontWeight={'bold'} style={styles.title}>
                {i18n.t('ONBOARDING.SUBSCRIPTION_FIRST.TITLE')}
              </Text>
            </View>
            {
              selections(colorSubscriptionButtons).map((el, index) => {
                if (index === selectorCount) {
                  return (
                    <View key={el.title} style={styles.selection}>
                      <ZoomInWrapper>
                        <Image
                          source={el.source}
                          style={styles.selectionImage}
                          resizeMode={'contain'}
                          style={{ width: 200, height: 200, }}
                        />
                      </ZoomInWrapper>

                      <ZoomInWrapper>
                        <Text fontWeightImportant fontWeight={'semibold'} style={styles.selectionText}>{i18n.t(el.title)}</Text>
                      </ZoomInWrapper>
                    </View>
                  );
                }
                return null;
              },
              )
            }
            <SubBottomWrapper
              isExtraSubscription
              isRelative
              isTryLimited={isOnboardingSubscriptionSecondTryLimited}
              onTryLimitedPress={this.onTryLimitedPress}
              productDetails={productDetails}
              products={products}
              product={this.props.product}
              title={onboardingSubscriptionSecondButtonText || i18n.t("ONBOARDING.SUBSCRIPTION_SECOND.BUTTON_TEXT")}
              pricePlace={pricePlace}
              analyticsType={this.props.analyticsType}
              contentType={backScreen}
            />
          </View>
        </AnimatedBG>
      </View>
    );
  }

  onCloseButtonPress = () => {
    const { analyticsType } = this.props;

    Analytics.trackEvent(analyticsType, 'Close');

    this.onClosePress();
  };

  onTryLimitedPress = () => {
    const { analyticsType, } = this.props;

    Analytics.trackEvent(analyticsType, 'TryLimited');

    this.onClosePress();
  };

  onClosePress = () => {
    const {
      onClosePress,
    } = this.props;
    onClosePress();
  };
}

export default connect(
  state => ({
    remoteConfig: state.remoteConfig.remoteConfigParams,
    product: state.remoteConfig.remoteConfigParams.onboardingSubscriptionSecondProduct,
    navigation: state.navigation,
    activeRoute: state.navigation.activeRouteName === ROUTES.ONBOARDING_SUBSCRIPTION_SECOND,
  }),
)(SubscriptionSecond);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
  },
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  titleWrapper: {
    ...(Platform.OS === 'ios'
      ? {
        ...ifIphoneX({
          marginTop: verticalScale(60),
        }, {
          marginTop: verticalScale(45),
        })
      }
      : { paddingTop: verticalScale(25), }
    ),
  },
  selection: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: 240,
  },
  selectionImage: {
    height: verticalScale(150),
  },
  selectionText: {
    fontSize: textSubscription(36),
    letterSpacing: textSubscription(-1.16),
    lineHeight: textSubscription(42),
    color: COLORS.WHITE,
  },
  title: {
    width: horizontalScale(240),
    color: COLORS.WHITE,
    textAlign: 'center',
    fontSize: horizontalScale(24),
    lineHeight: verticalScale(30),
    letterSpacing: horizontalScale(-0.77),
  },
  subTitle: {
    width: horizontalScale(240),
    marginTop: verticalScale(5),
    color: COLORS.WHITE,
    textAlign: 'center',
    fontSize: horizontalScale(14),
  },
  tryLimitedRow: {
    marginTop: 5,
    flexDirection: 'row',
  },
  tryLimitedText: {
    fontSize: horizontalScale(14),
    lineHeight: verticalScale(18),
    color: COLORS.WHITE,
  },
  tryLimitedTouchable: {
    textDecorationLine: 'underline',
  },
});
