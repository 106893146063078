import { Platform } from 'react-native';

import { FETCH_REMOTE_CONFIG_FAIL, SET_REMOTE_CONFIG } from './types';
import { INSIDE, NONE, OVER } from '../../constants/button-price-place';
import {
  MONTH_DEFAULT,
  WEEK_SHORT,
  WEEK_M,
  WEEK_I,
  YEAR_DEFAULT,
} from '../../constants/billing';
import { INTER, REWARDED } from '../../constants/ads';
import {
  BIORITHM,
  CHINESE,
  COMPATIBILITY,
  DRUID,
  INDIAN_MOON,
  INDIAN_SUN,
  MAGIC_BALL,
  MAYAN,
  PALMISTRY,
  PHYSIOGNOMY,
  TAROT,
  ZODIAC,
  HOROSCOPES,
} from '../../constants/sections';
import { ONBOARDING_IDS } from '../../constants/onboarding-types';
import { BEHAVIOR_FOR_PUSHES } from '../../constants/messaging';
import * as TAB_BAR_BADGES from '../../constants/tab-bar-badges';

const DEFAULT_BTN_TEXT = Platform.select({
  ios: 'SUBSCRIBE',
  android: 'START FREE',
  web: 'START FREE',
});

export const initialParams = {
  isOnboarding: true,

  isOnboardingSubscriptionFirstTryLimited: false,
  onboardingSubscriptionFirstProduct: WEEK_SHORT,

  isOnboardingSubscriptionSecondTryLimited: false,
  onboardingSubscriptionSecondProduct: WEEK_SHORT,

  onboardingExtraSubscriptionTitle: 'ARE YOU SURE?',
  onboardingExtraSubscriptionSubtitle: 'TRY IT NOW',
  isOnboardingExtraSubscriptionTryLimited: false,

  onboardingSubscriptionFirstButtonText: DEFAULT_BTN_TEXT,
  onboardingSubscriptionSecondButtonText: DEFAULT_BTN_TEXT,

  onboadringLoadingDuration: 7000,

  subFontSizes: Platform.OS === 'web'
    ? {
      "btnTitle": 22,
      "priceText": 14,
    }
    : {
      "btnTitle": 16,
      "priceText": 16,
    },

  onboardingFlow: Platform.select({
    ios: [
      ONBOARDING_IDS.PROFILE,
      ONBOARDING_IDS.SUB_FIRST,
      ONBOARDING_IDS.SUB_EXTRA,
      ONBOARDING_IDS.HAND,
      ONBOARDING_IDS.SUB_SECOND,
    ],
    android: [
      ONBOARDING_IDS.HAND,
      ONBOARDING_IDS.SUB_FIRST,
      ONBOARDING_IDS.SUB_EXTRA,
      ONBOARDING_IDS.HAND,
      ONBOARDING_IDS.SUB_SECOND,
      ONBOARDING_IDS.PROFILE,
    ],
    web: [
      ONBOARDING_IDS.SUB_FIRST,
      ONBOARDING_IDS.PROFILE,
      ONBOARDING_IDS.HAND,
      ONBOARDING_IDS.SUB_SECOND,
    ],
  }),

  whereNavigateAfterOnboarding: '',

  renewalText: Platform.select({
    ios: OVER,
    android: NONE,
    web: NONE,
  }),

  customRateUsIOS: false,

  landingSubscriptionProduct: WEEK_SHORT,

  subscriptionThreeTimeout: Platform.select({
    ios: 0,
    android: 5,
    web: 5,
  }),
  subscriptionThreeBottomButtonTitle: ``,
  subscriptionThreeProducts: Platform.select({
    ios: WEEK_SHORT,
    android: `${WEEK_M};${MONTH_DEFAULT};${YEAR_DEFAULT}`,
    web: WEEK_SHORT,
  }),

  popularProductFromThree: 1,
  subscriptionThreeTitles: 'Try Week;Try Month;Try Year',
  isSubscriptionThreeTryLimited: false,
  isSubscriptionThreeDefault: false,
  isExtraSubscription: true,
  onboardingExtraSubscriptionButtonText: '',

  leaveDashboardRateUsRateInSession: 1,
  contentRateUsRepeatRateInSessions: 2,
  onDashboardRateUsInSession: 1,

  // siri
  voiceCommandSuggestionMaxSeenTimes: 100,
  voiceCommandSuggestionDisplayTimeout: 4800000,

  ignoreObjectDetection: true,

  //ads
  isAds: Platform.OS !== 'web',
  typesOfAds: `${REWARDED} ${INTER}`,
  interTimeout: 60,
  adUnlockContent: `${REWARDED} ${INTER}`,

  colorSubscriptionButtons: 'green',
  cameraProgressModalDurationPalm: 10,
  cameraProgressModalDurationFace: 10,
  isPalmFaceBallOnDashboard: true,
  isAndroidTerms: false,
  shortTerms: false,

  freeSelections: {
    [`${HOROSCOPES}`]: {
      [`${INDIAN_MOON}`]: true,
      [`${INDIAN_SUN}`]: true,
      [`${DRUID}`]: true,
      [`${MAYAN}`]: true,
      [`${CHINESE}`]: true,
      [`${ZODIAC}`]: true,
    },
    [`${TAROT}`]: false,
    [`${PALMISTRY}`]: false,
    [`${PHYSIOGNOMY}`]: false,
    [`${MAGIC_BALL}`]: true,
    [`${BIORITHM}`]: true,
    [`${COMPATIBILITY}`]: true,
  },

  onboardingExtraSubscriptionProduct: Platform.select({
    ios: WEEK_SHORT,
    android: WEEK_I,
    web: WEEK_SHORT,
  }),

  onboardingSubscriptionFirstCloseButtonTimeout: 5,
  onboardingSubscriptionSecondCloseButtonTimeout: Platform.select({
    ios: 0,
    android: 100,
    web: 0,
  }),
  onboardingExtraSubscriptionCloseButtonTimeout: 0,
  onboardingLandingSubscriptionCloseButtonTimeout: 0,

  onboardingPersSubscriptionProduct: WEEK_SHORT,
  onboardingPersSubscriptionCloseButtonTimeout: 0,
  onboardingPersSubscriptionButtonText: '',
  isOnboardingPersSubscriptionTryLimited: false,

  isSubscriptionOnAds: true,
  isAdsAfterFinishOnboarding: false,
  isSubscriptionThreeButtonSubtitle: false,

  //pushes
  pushesBehavior: BEHAVIOR_FOR_PUSHES.ONLY_FREE_USER,

  cameraBiometricDataText: true,

  paywall: false,
  paywallSession: 3,
  paywallScreen: 'sub_three_products',

  pricePosition: Platform.OS === 'web' ? NONE : INSIDE,

  appSubsVariator: false,

  tabBarBadges: {
    daily: TAB_BAR_BADGES.POINT,
    horoscopes: TAB_BAR_BADGES.NONE,
    divinations: TAB_BAR_BADGES.NONE,
    compatibility: TAB_BAR_BADGES.NONE,
  },

  tryPremiumBannerEverySession: 0,
  unlimitedBannerEverySession: 0,

  trialBadge: false,

  premiumIcon: false,
  treatmentPalmModal: false,

  webCheckoutMode: '', // empty string || ONLY_PRB || ONLY_CARD
  webCheckoutPRBMode: '', // empty string for brand button || CUSTOM_WITH_MARK || CUSTOM_WO_MARK
  webCheckoutShowPRBDetails: true,
  webCheckoutShowCardSecurIcons: true,

  appIsNotAvailable: false,

  webTermsCompany: 'acina', // acina || ds

  __use_magnus_remote_config__: Platform.OS === 'web',
};

const initialState = {
  isRemoteConfig: false,
  remoteConfigParams: initialParams,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REMOTE_CONFIG:
      return {
        ...state,
        isRemoteConfig: true,
        remoteConfigParams: {
          ...state.remoteConfigParams,
          ...action.payload.remoteConfig,
        },
      };

    case FETCH_REMOTE_CONFIG_FAIL:
      return {
        ...state,
        isRemoteConfig: true,
      };

    default:
      return state;
  }
}
