export const FEMALE = 'FEMALE';
export const MALE = 'MALE';

export const FREE = "FREE";
export const MARRIED = "MARRIED";
export const SOULMATE = "SOULMATE";
export const DIFFICULT = "DIFFICULT";

export const GENDERS_IDS = [FEMALE, MALE];

export const MARITAL_STATUS_IDS = [FREE, MARRIED, SOULMATE, DIFFICULT];
