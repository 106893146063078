import i18n from '../../localization';

import Billing from './index';

const periods = {
  D: { days: 1, code: 'DAYS', },
  W: { days: 7, code: 'WEEK', },
  M: { days: 30, code: 'MONTH', },
  Y: { days: 365, code: 'YEAR', },
};

function getPeriodCode(string) {
  return string?.match(/\w$/)[0];
}

function getPeriodNumber(string) {
  return +string.match(/(\d+)/)[0];
}

export function getButtonDefaultInfoFromProduct(product) {
  const productDetails = Billing.getProductDetails(product);
  if (!productDetails) {
    return {};
  }

  const { haveTrialPeriod, priceText, trialPeriod, subscriptionPeriod } = productDetails;

  let trialPeriodCode, trialDays, trialPeriodNumber = '';


  if (haveTrialPeriod) {
    trialPeriodCode = getPeriodCode(trialPeriod);
    trialPeriodNumber = getPeriodNumber(trialPeriod);
    trialDays = trialPeriodNumber * periods[`${trialPeriodCode}`].days;
  }

  const productIdStrings = product.split('.');

  const subscriptionPeriodCode = subscriptionPeriod
    ? getPeriodCode(subscriptionPeriod)
    : productIdStrings[(productIdStrings.length - 2)].toUpperCase(),
    code = periods[`${subscriptionPeriodCode}`].code,
    periodCode = `${haveTrialPeriod ? 'THEN_' : ''}${code}`;

  const title = i18n.t(`SUBSCRIPTION.BTN_${code}`, { days: trialDays });

  const subTitle = i18n.t(`SUBSCRIPTION.BILLING.PRICE_${periodCode}`, {
    trial: trialDays,
    price: priceText,
  });

  return {
    subTitle,
    title,
    code,
    feature: subTitle,
    haveTrialPeriod,
    priceText,
    trial: trialDays,
  };
}
