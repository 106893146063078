import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { navigate } from '../../../store/navigation/actions';
import { addToOnboardingProfile } from "../../../store/onboarding/actions";
import OnboardingScreenLayout from './screen-layout';
import GenderSelect from '../components/gender-select';
import * as IMAGES from '../../../constants/images';
import * as ROUTES from '../../../constants/routes';
import i18n from '../../../localization';
import { verticalScale } from '../../../utils/scale';
import Analytics from '../../../modules/analytics';

class OnboardingGender extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    addToOnboardingProfile: PropTypes.func,
    navigate: PropTypes.func,
  };

  static navigationOptions = {
    header: null,
  };

  componentDidMount() {
    Analytics.trackEvent('Onboarding_Gender', 'Open');
  }

  render() {
    return (
      <OnboardingScreenLayout
        bgImage={IMAGES.BG_ONBOARDING_GENDER}
        sectionNumber={2}
        title={i18n.t('ONBOARDING.GENDER.TITLE', { name: this.props.name })}
        text={i18n.t('ONBOARDING.GENDER.TEXT')}
        interactiveElement={this.renderGenderSelect()}
        specialPadding
      />
    );
  }

  onButtonPress = (genderUpdate) => {
    const { addToOnboardingProfile, navigate } = this.props;
    Analytics.trackEvent('Onboarding_Gender', 'Click', { 'GenderType': genderUpdate });
    addToOnboardingProfile({ gender: genderUpdate });
    navigate(ROUTES.ONBOARDING_RELATIONSHIP);
  };

  renderGenderSelect = () => {
    return (
      <View style={styles.genderSelectWrapper}>
        <GenderSelect onPress={this.onButtonPress} />
      </View>
    );
  };

}

export default connect(
  state => ({
    name: state.onboarding.onboardingProfile.name,
  }), {
  navigate,
  addToOnboardingProfile,
}
)(OnboardingGender);

const styles = StyleSheet.create({
  genderSelectWrapper: {
    marginBottom: verticalScale(45),
  }
});
