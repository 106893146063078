import firebase from 'firebase/app';
import 'firebase/analytics';
import queryString from 'query-string';
import EvTruck from '@magnus/react-native-evtruck';
import Session from '@wowmaking/react-native-analytics/src/helpers/session';

export default {

  init() {
    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN,
    });
    return Session.init(this)
      .then(() => {
        let p = queryString.parse(window.location.search);
        this.setUserProperty('testmode', String(p.testmode !== undefined));
      });
  },

  getAdjustAttribution() {
  },

  setUserProperty(name, value) {
    firebase.analytics().setUserProperties({ [name]: value, });
    // window.fbInitPromise.then(() => FB.AppEvents.updateUserProperties({ [name]: value, }));
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params, options) {
    let e = category + '_' + action;
    firebase.analytics().logEvent(e, params);
    window.fbInitPromise.then(() => FB.AppEvents.logEvent(e, null, params));
    EvTruck.trackEvent(e, params);
  },

  trackViewProductEvent() {
  },

  trackPurchaseEvent(transaction) {
    firebase.analytics().logEvent('ecommerce_purchase', {
      item_id: transaction.productId,
      item_name: transaction.productId,
      value: transaction.revenue,
      price: transaction.revenue,
      currency: transaction.currency,
      transaction_id: transaction.id,
    });
    window.fbInitPromise.then(() =>
      FB.AppEvents.logPurchase(
        transaction.revenue,
        transaction.currency,
        { ...transaction, }
      )
    );
    window.fbq('track', 'Purchase', {
      currency: transaction.currency,
      value: transaction.revenue,
    });
    EvTruck.trackEvent('ecommerce_purchase', {
      ...transaction,
      item_id: transaction.productId,
      value: transaction.revenue,
      price: transaction.revenue,
      currency: transaction.currency,
    });
  },

  setAdjustAttributionUserProperties() {
  },

  getSessionNumber() {
    return Session.getNumber();
  },

};
