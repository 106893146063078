import React, { PureComponent } from 'react';
import { StyleSheet, View } from "react-native";
import PropTypes from "prop-types";
import Text from "../text";
import * as COLORS from "../../constants/colors";
import Swiper from "./picker-swiper";

export default class Column extends PureComponent {

  static propTypes = {
    data: PropTypes.array,
    name: PropTypes.string,
    changeIndex: PropTypes.func,
    heightRow: PropTypes.number,
    visibleValues: PropTypes.number,
    activeIndex: PropTypes.number,
    height: PropTypes.number,
    theme: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    name: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      maxY: props.heightRow * (props.data.length - Math.floor(props.visibleValues / 2)),
      isMomentumScrollBegin: false,
    }
  };

  onChangeIndex = (value) => this.props.changeIndex(this.props.name, value);

  render() {
    const {
      data,
      name,
      heightRow,
      height,
      theme,
      visibleValues,
      activeIndex
    } = this.props;

    if (data.length === 0 || !name) return null;

    const fontSizeText = theme === 'chat' ? 22 : 28;
    
    return (
      <Swiper
        style={{ height: height }}
        vertical={true}
        onIndexChanged={this.onChangeIndex}
        visibleValues={visibleValues}
        activeIndex={activeIndex}
        initialPosition={activeIndex * heightRow * -1}
      >
        {
          data.map((el, i) =>
            <View
              key={el + "_" + i}
              style={[styles.row, { height: heightRow }]}
            >
              <Text
                key={el}
                fontWeight={'semibold'}
                style={[styles.colorRow, { fontSize: fontSizeText }]}
              >
                {el}
              </Text>
            </View>
          )
        }
      </Swiper>
    )
  }

}

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorRow: {
    color: COLORS.WHITE,
    paddingHorizontal: 15,
  },
});
