import {
  SET_UNLOCK,
} from './types';

import {
  CHINESE,
  DRUID,
  INDIAN_MOON,
  INDIAN_SUN,
  MAYAN,
  ZODIAC,
  PALMISTRY,
  PHYSIOGNOMY,
  MAGIC_BALL,
  BIORITHM,
  COMPATIBILITY,
  TAROT,
} from "../../constants/sections";

const initialState = {
  //24hours
  [`${INDIAN_MOON}`]: {},
  [`${INDIAN_SUN}`]: {},
  [`${ZODIAC}`]: {},

  [`${DRUID}`]: 0,
  [`${MAYAN}`]: 0,
  [`${CHINESE}`]: 0,
  [`${TAROT}`]: 0,

  //forever
  [`${PALMISTRY}`]: false,
  [`${PHYSIOGNOMY}`]: false,
  [`${MAGIC_BALL}`]: false,
  [`${BIORITHM}`]: false,
  [`${COMPATIBILITY}`]: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_UNLOCK:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
