import _find from 'lodash/find';
import moment from 'moment';
import { createAction } from 'redux-actions';

import Billing from '../../modules/billing';
import Analytics from '../../modules/analytics';

import * as BILLING from '../../constants/billing';

import { SET_DATA, SET_PENDING, SET_PURCHASED, SET_PURCHASE_DATE } from './types';
import { getSubs, registerSub } from '../../api/purchases';

import { initNotifications } from '../notification/actions';
import { SKU } from '../../constants/billing';
import { setPaywall } from '../app/actions';
import { openCustomAppIconModal } from '../modals/actions';

export const setPurchased = createAction(SET_PURCHASED);
export const setData = createAction(SET_DATA);
export const setPending = createAction(SET_PENDING);
export const purchaseDate = createAction(SET_PURCHASE_DATE);

export function initPurchase() {
  return function (dispatch, getState) {
    dispatch(setPending(true));

    return getSubs()
      .then(data => {
        return Billing.verifyPurchases(data || [])
          .then(data => {
            dispatch(setData(data));
            if (getState().purchases.purchaseDate && !data?.length) {
              dispatch(purchaseDate(0));
              dispatch(initNotifications());
            }
            dispatch(setupPurchasedState());
            updateUserType(getState);
          })
          .catch(console.warn)
          .then(() => {
            dispatch(setPending(false));
            return getState().purchases;
          });
      });
  };
}

export function add(transaction, isFake) {
  return function (dispatch, getState) {
    const state = getState(),
      data = (state.purchases.data || []).concat([transaction]);
    const { premiumIcon } = state.remoteConfig.remoteConfigParams;
    const { supported, changed } = state.customAppIcon;
    const isOpenModalChangedPremiumIcon = premiumIcon && supported && !changed;

    if (isFake) {
      dispatch(setData(data));
      dispatch(setupPurchasedState());
      if (isOpenModalChangedPremiumIcon) {
        dispatch(openCustomAppIconModal());
      }
    } else {
      dispatch(setPending(true));
      return registerSub(data).then(
        () => {
          dispatch(setData(data));
          dispatch(setupPurchasedState());
          dispatch(setPaywall(false));

          const now = Date.now();
          dispatch(purchaseDate(now));
          updateUserType(getState);
          dispatch(setPending(false));
          if (isOpenModalChangedPremiumIcon) {
            dispatch(openCustomAppIconModal());
          }
        },
        err => {
          console.warn(err);
          dispatch(setPending(false));
        },
      )
        .finally(() => {
          dispatch(initNotifications());
        });
    }
  };
}

export const hasActiveSubscription = () => {
  return (dispatch, getState) => {
    const { purchases: { data } } = getState();
    return !!_find(data, item => item && item.productId && item.productId.indexOf(SKU) === 0);
  }
};

const setupPurchasedState = () => {
  return dispatch => {
    const purchased = dispatch(hasActiveSubscription());
    dispatch(setPurchased(purchased));
  };
};
function updateUserType(getState) {
  const {
    purchases: { purchased },
  } = getState(),
    userType = purchased ? 'subscriber' : 'free';
  Analytics.setUserProperty('user_type', userType);
}

export function addFakePurchase() {
  return function (dispatch) {
    dispatch(add({ productId: BILLING.WEEK_SHORT }, true));
  };
}

export function checkTrialState() {
  return function (dispatch, getState) {
    const { purchases: { purchaseDate, data, purchased } } = getState();

    if (purchased) {
      const diffTime = Date.now() - purchaseDate;
      const daysPassed = moment.duration(diffTime).days();
      const trialDays = data[0]?.product?.trial || 0; // for fake purchase
      return daysPassed <= trialDays;
    }
    return false;
  }
}
