import React, { PureComponent } from 'react';
import {
  StyleSheet,
  View,
  StatusBar,
} from 'react-native';
import { connect, } from 'react-redux';
import PropTypes from 'prop-types';
import { ifIphoneX } from 'react-native-iphone-x-helper';

import * as COLORS from '../../constants/colors';
import * as ICONS from '../../constants/icons';
import CloseButtonWithTimeout from './components/close-button-with-timeout';
import { newVerticalScale, newHorizontalScale, textSubscription } from '../../utils/scale';
import i18n from '../../localization';
import SubBottomWrapper from './components/subscription-bottom-wrapper';
import Text from '../../components/text';
import { getButtonDefaultInfoFromProduct } from '../../modules/billing/conveter';
import Analytics from '../../modules/analytics';
import FeaturesWithIcons from './components/features-with-icons';
import AnimatedBG from '../tarot/components/animated-bg';
import * as ROUTES from '../../constants/routes';

class SubscriptionFirstAnimated extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    remoteConfig: PropTypes.object,
    product: PropTypes.string,
    onClosePress: PropTypes.func.isRequired,
    analyticsType: PropTypes.string.isRequired,
    activeRoute: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const productDetails = getButtonDefaultInfoFromProduct(props.product);

    this.state = {
      productDetails,
      products: [productDetails],
    };
  }

  componentDidMount() {
    Analytics.trackEvent(this.props.analyticsType, 'Open');
  }

  render() {
    const {
      isOnboardingSubscriptionFirstTryLimited,
      onboardingSubscriptionFirstButtonText,
      pricePosition,
      onboardingSubscriptionFirstCloseButtonTimeout,
    } = this.props.remoteConfig,
      { productDetails, products } = this.state;

    return (
      <View style={styles.root}>
        <AnimatedBG visible={this.props.activeRoute}>
          <View style={styles.container}>
            <StatusBar barStyle='light-content' />
            <CloseButtonWithTimeout
              closePress={this.onCloseButtonPress}
              source={ICONS.CLOSE_ICON}
              closeButtonTimeout={onboardingSubscriptionFirstCloseButtonTimeout}
            />
            <View style={styles.titleContainer}>
              <Text fontWeightImportant fontWeight={'bold'} style={styles.title}>
                {i18n.t('ONBOARDING.SUBSCRIPTION_FIRST.TITLE')}
              </Text>
            </View>
            <FeaturesWithIcons
              isTryLimited={isOnboardingSubscriptionFirstTryLimited}
              onTryLimitedPress={this.onTryLimitedPress}
            />
            <SubBottomWrapper
              isRelative
              isTryLimited={isOnboardingSubscriptionFirstTryLimited}
              onTryLimitedPress={this.onTryLimitedPress}
              productDetails={productDetails}
              products={products}
              product={this.props.product}
              title={onboardingSubscriptionFirstButtonText || i18n.t("ONBOARDING.SUBSCRIPTION_FIRST.BUTTON_TEXT")}
              pricePlace={pricePosition}
              analyticsType={this.props.analyticsType}
            />
          </View>
        </AnimatedBG>
      </View>
    );
  }

  onCloseButtonPress = () => {
    const {
      analyticsType,
    } = this.props;

    Analytics.trackEvent(analyticsType, 'Close');

    this.onClosePress();
  };

  onTryLimitedPress = () => {
    const {
      analyticsType,
    } = this.props;

    Analytics.trackEvent(analyticsType, 'TryLimited');

    this.onClosePress();
  };

  onClosePress = () => {
    this.props.onClosePress();
  };
}

export default connect(
  state => ({
    remoteConfig: state.remoteConfig.remoteConfigParams,
    product: state.remoteConfig.remoteConfigParams.onboardingSubscriptionFirstProduct,
    activeRoute: state.navigation.activeRouteName === ROUTES.ONBOARDING_SUBSCRIPTION_FIRST,
  }),
)(SubscriptionFirstAnimated);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
  },
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  titleContainer: {
    ...ifIphoneX({
      marginTop: newVerticalScale(65, null, 60),
    }, {
      marginTop: newVerticalScale(25),
    }),
    width: newHorizontalScale(375),
    paddingVertical: newVerticalScale(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: COLORS.WHITE,
    fontSize: textSubscription(36),
    letterSpacing: textSubscription(-1.16),
    lineHeight: textSubscription(42),
    textAlign: 'center',
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  list: {
    flex: 1,
    justifyContent: 'center',
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: newVerticalScale(10),
  },
  listLastItemContainer: {
    marginBottom: 0,
  },
  listItemIcon: {
    width: newHorizontalScale(35),
    height: newVerticalScale(35),
    overflow: 'visible',
    marginRight: newHorizontalScale(16),
  },
  listItemText: {
    color: COLORS.WHITE,
    fontSize: newVerticalScale(18, null, 16),
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  listItemTouchable: {
    textDecorationLine: 'underline',
    marginLeft: newHorizontalScale(14),
  },
});
