import React, { PureComponent } from 'react';
import { Animated, Easing, ImageBackground, StyleSheet, View, } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LottieView from 'lottie-react-native';
import SplashScreen from 'react-native-splash-screen';
import _ from 'lodash';

import * as ROUTES from '../../constants/routes';
import { BLURPLE, VERY_LIGHT_PURPLE, WHITE } from '../../constants/colors';
import { SPLASH_BG } from '../../constants/images';

const MOON = require('../../../assets/animations/moon');
const SPLASH_STARS = require('../../../assets/animations/splash_stars');

import { initRemoteConfig } from '../../store/remote-config/actions';
import {
  reset,
  onboardingStepNavigate,
  authNavigate,
  onNotificationOpen,
  navigate,
} from '../../store/navigation/actions';
import {
  checkInternetConnection,
  initAppState,
  setIsLoaded,
  checkHasBeenSetPhoto,
  checkAndActivatePaywall,
  saveSetupDate,
} from '../../store/app/actions';
import { initAuth } from '../../store/auth/actions';
import { initAllSigns } from '../../store/horoscope/actions';
import { initPalmReading } from '../../store/palm-reading/actions';
import { handleNsUserActivityType } from '../../store/siri-shorcuts/actions';
import { initPurchase } from '../../store/purchases/actions';
import { initAds } from '../../store/ads/actions';
import { initUnlockContent } from '../../store/unlock-content/actions';
import { initNotifications } from '../../store/notification/actions';
import { initCustomAppIcons } from '../../store/custom-app-icon/actions';
import { fetchLocalization } from '../../store/localization/actions';

import Analytics from '../../modules/analytics';
import Attribution from '../../modules/attribution';
import Billing from '../../modules/billing';
import Ads from '../../modules/ads';
import Messaging from '../../modules/messaging';
import Auth from '../../modules/auth';

import Text from '../../components/text';

import DisconnectModal from '../../reusable/disconnect-modal';

class LoadingScreen extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    onMount: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    isFirstLoadingDid: false,
  };

  animatedText = new Animated.Value(0);

  componentDidMount() {
    const { isLoaded } = _.get(this.props.navigation, 'state.params', false);

    if (!isLoaded) {
      // workaround issue of react-navigation with double mounting of reseted screen
      this.props.navigation.setParams({ isLoaded: true });
      SplashScreen.hide();
      this.handleLoadedApp();
    }

    Animated.timing(this.animatedText, {
      toValue: 1,
      duration: 2000,
      easing: Easing.bezier(0.165, 0.84, 0.44, 1),
    }).start();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.isFirstLoadingDid &&
      prevProps.isNotConnected &&
      !this.props.isNotConnected
    ) {
      this.setState({ isFirstLoadingDid: true }, this.handleLoadedApp);
    }
  }

  handleLoadedApp = () => {
    const { onMount } = this.props;
    onMount()
      .then(() => {
        this.setState({ isFirstLoadingDid: true });
      })
      .catch(error => {
        console.warn(error);
        this.setState({ error });
      });
  };

  handleErrorModal = () => {
    const { onMount } = this.props;

    Analytics.trackEvent('Disconnect_Retry', 'Click');

    return onMount()
      .then(() => {
        this.setState({ error: false });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;
    return error ? (
      <View style={styles.problems}>
        <DisconnectModal onPress={this.handleErrorModal} />
      </View>
    ) : (
        <View style={styles.root}>
          <ImageBackground style={styles.bg} source={SPLASH_BG}>
            <View style={styles.stars}>
              <LottieView
                ref={this.bindAnimation}
                source={SPLASH_STARS}
                loop={false}
              />
            </View>
            <View style={styles.moon}>
              <LottieView
                ref={this.bindAnimation}
                source={MOON}
              />
            </View>
            <Animated.View
              style={[
                styles.titleCt,
                {
                  marginBottom: this.animatedText.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-100, 40],
                  }),
                },
              ]}
            >
              <Text style={styles.title}>Astroline</Text>
              <Text style={styles.desc}>Your personal predictions</Text>
              <Text fontWeight={'semibold'}> </Text><Text fontWeight={'bold'}> </Text>{/** to precache fonts */}
            </Animated.View>
          </ImageBackground>
        </View>
      );
  }

  bindAnimation = animationRef => {
    if (animationRef) {
      this.animation = animationRef;
      this.animation.play();
    }
  };
}

export default connect(
  state => ({
    defaultProfileData: state.profile.profileData,
    isNotConnected: state.app.isNotConnected,
    isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
  }),
  {
    initAuth,
    reset,
    onboardingStepNavigate,
    authNavigate,
    initAppState,
    initAllSigns,
    initPalmReading,
    checkInternetConnection,
    handleNsUserActivityType,
    setIsLoaded,
    initPurchase,
    initUnlockContent,
    initAds,
    initRemoteConfig,
    checkHasBeenSetPhoto,
    checkAndActivatePaywall,
    initNotifications,
    saveSetupDate,
    initCustomAppIcons,
    fetchLocalization,
    navigate,
    onNotificationOpen,
  }, (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onMount: () => {
      return dispatchProps.checkInternetConnection().then(
        (isConnected) => {
          if (!isConnected) {
            throw new Error('is not Internet');
          }
          return Analytics.init()
            .then(() => Attribution.init())
            .then(() => dispatchProps.initRemoteConfig())
            .then(remoteConfig => {
              return Promise.all([
                dispatchProps.initAppState(),
                dispatchProps.initAuth(),
                dispatchProps.initUnlockContent(),
              ])
                .then(() => Auth.init())
                .then(() => Billing.init())
                .then(() => dispatchProps.initPurchase())
                .then(() => {
                  const { isOnboarding } = remoteConfig;
                  const sessionNumber = Analytics.getSessionNumber();
                  if (!sessionNumber) {
                    dispatchProps.initNotifications();
                  } else {
                    Messaging.removeAllDeliveredNotifications();
                  }

                  let wasNavigation = false;

                  Messaging.onOpenNotificationHandler(n => {
                    wasNavigation = dispatchProps.onNotificationOpen(n);
                  });

                  return Promise.all([
                    dispatchProps.initAllSigns(),
                    dispatchProps.initPalmReading(),
                  ])
                    .then(() => {
                      if (!sessionNumber) {
                        const date = Date.now();
                        dispatchProps.saveSetupDate(date);
                      }
                      dispatchProps.checkHasBeenSetPhoto();
                      if (isOnboarding && !stateProps.isOnboardingCompleted) {
                        dispatchProps.onboardingStepNavigate();
                      }
                      else if (!dispatchProps.authNavigate()) {
                        const isPaywall = dispatchProps.checkAndActivatePaywall();
                        if (!isPaywall && !wasNavigation) {
                          return dispatchProps.reset(ROUTES.MAIN);
                        }
                      }
                    })
                    .then(() => {
                      Ads.init();
                      dispatchProps.initAds();
                      dispatchProps.setIsLoaded();
                      dispatchProps.initCustomAppIcons();
                      return dispatchProps.handleNsUserActivityType();
                    });
                });
            });
        });
    },
  }),
)(LoadingScreen);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
  bg: {
    flex: 1,
    position: 'relative',
    justifyContent: 'space-between',
  },
  moon: {
    position: 'absolute',
    top: 37,
    right: 22,
    width: 108,
    height: 108,
  },
  stars: {
    marginTop: 15,
    width: '100%',
    aspectRatio: 1,
  },
  problems: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  titleCt: {
    alignItems: 'center',
  },
  title: {
    textShadowColor: BLURPLE,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 1,
    elevation: 1,

    color: WHITE,
    fontSize: 35,
  },
  desc: {
    color: VERY_LIGHT_PURPLE,
    fontSize: 18,
  },
});
