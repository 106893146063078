import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as ROUTES from '../../../constants/routes';
import { navigate, replace } from '../../../store/navigation/actions';
import { goToNextOnboardingStep } from '../../../store/onboarding/actions';
import { showInterAfterNav } from "../../../store/ads/actions";
import SubscriptionThird from "../../subcsription/subscription-third";
import SubscriptionSecond from "../../subcsription/subscription-second";

class onboardingSubscriptionSecond extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    remoteConfig: PropTypes.object,
    navigate: PropTypes.func,
    replace: PropTypes.func,
    showInterAfterNav: PropTypes.func,
    isOnboardingCompleted: PropTypes.bool,
    isExtraSubscription: PropTypes.bool.isRequired,
    paywall: PropTypes.bool.isRequired,
    purchased: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.purchased) {
      this.props.goToNextOnboardingStep();
    }
  }

  render() {
    const { isOnboardingCompleted, paywall, analyticsType, isSubscriptionThreeDefault } = this.props;
    let Component;
    if (isOnboardingCompleted) {
      if (paywall) {
        Component = SubscriptionSecond;
      } else {
        Component = isSubscriptionThreeDefault ? SubscriptionThird : SubscriptionSecond;
      }
    } else {
      Component = SubscriptionSecond;
    }

    return <Component analyticsType={analyticsType} onClosePress={this.onClosePress} />;
  }


  onClosePress = () => {
    const {
      navigation,
      isOnboardingCompleted,
      navigate,
      isExtraSubscription,
      replace,
      showInterAfterNav,
      goToNextOnboardingStep,
    } = this.props,
      { params } = navigation.state;

    if (params?.onClosePress) {
      params.onClosePress()
    }
    else if (isOnboardingCompleted && isExtraSubscription) {
      replace(ROUTES.ONBOARDING_EXTRA_SUB, params);
    }
    else if (params?.backScreen) {
      if (isOnboardingCompleted) {
        showInterAfterNav(() => {
          navigate(params.backScreen, params.paramsToBackScreen);
        });
      } else {
        navigate(params.backScreen, params.paramsToBackScreen)
      }
    }
    else {
      goToNextOnboardingStep();
    }
  };
}

export default connect(
  state => {
    const { purchased } = state.purchases;
    const { paywall } = state.app;
    const { isOnboardingCompleted } = state.onboarding;
    const {
      isSubscriptionThreeDefault,
      isExtraSubscription,
    } = state.remoteConfig.remoteConfigParams;
    let analyticsType;

    if (isOnboardingCompleted) {
      if (paywall) {
        analyticsType = 'paywall_palm';
      } else {
        analyticsType = 'App_Subscription';
      }
    } else {
      analyticsType = 'Onboarding_SubscriptionPalm';
    }

    return {
      isSubscriptionThreeDefault,
      isExtraSubscription,
      paywall,
      purchased,
      analyticsType,
      isOnboardingCompleted,
    }
  }, {
  navigate,
  replace,
  goToNextOnboardingStep,
  showInterAfterNav,
}
)(onboardingSubscriptionSecond);

