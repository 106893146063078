import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as ICONS from '../../../../constants/icons';
import i18n from '../../../../localization';
import Text from '../../../../components/text';
import { newHorizontalScale, newVerticalScale } from '../../../../utils/scale';
import * as COLORS from '../../../../constants/colors';
import { CHINESE, ZODIAC } from '../../../../constants/horoscopes-names';
import { getLuckNumber } from '../../../../utils/numberology';


class FeaturesWithIcons extends PureComponent {
  static propTypes = {
    zodiac: PropTypes.string.isRequired,
    animal: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const { zodiac, animal, number } = props;

    this.advantages = [
      {
        key: 'ZODIAC',
        icon: ICONS[`ICON_${zodiac}_FEATURES`],
        valueKey: `SINGS.ZODIAC.${zodiac}`,
      },
      {
        key: 'NUMBER',
        icon: ICONS.ICON_LUCKY_FEATURES,
        value: number,
      },
      {
        key: 'CHINESE',
        icon: ICONS[`ICON_${animal}_FEATURES`],
        valueKey: `SINGS.CHINESE.${animal}`,
      },
    ];
    this.advantagesSize = this.advantages.length;
  }

  //return title subtitle, where title with less charts then subtitle+value,
  // it function better then title and subtitle translation cos we sure to right translation. Minimaze risk to wrong sense and better to UI experience
  getTitleAndSubtitle = (words, value = '') => {
    const symbolsSize = words.reduce((acc, el) => acc + el.length, value.toString().length); //calc all charts

    for (let i = 0, diffSymbolsSize = symbolsSize; i < words.length; i++) {
      diffSymbolsSize -= words[i].length;

      if (Math.floor(symbolsSize / 2) > diffSymbolsSize) { //check condition where title with less charts then subtitle+value

        const lastWordIndexInTitle = Math.max(i - 1, 0); // we should have just one word in title

        const [wordsTitle, wordsSubtitle] = words.reduce((acc, el, index) => {
          acc[index <= lastWordIndexInTitle ? 0 : 1].push(el);
          return acc;
        }, [[], []]);

        return [
          wordsTitle.join(' '),
          wordsSubtitle.join(' '),
        ];
      }
    }

    return [words.join(' '), ''];
  };

  render() {
    return <View style={styles.list}>
      {
        this.advantages?.map((item, index) => {

          const words = i18n.t(`ONBOARDING.PERS_SUBSCRIPTION.${item.key}`).split(' ');
          const value = item.value || i18n.t(item.valueKey);
          const [title, subtitle] = this.getTitleAndSubtitle(words, value);

          return (
            <View
              style={[styles.listItemContainer, index === (this.advantagesSize - 1) && styles.listLastItemContainer]}
              key={item.key}
            >
              <Image
                style={[styles.listItemIcon, index === 1 ? styles.secondItemIcon : null]}
                source={item.icon}
                resizeMode={'contain'}
              />
              <View>
                <Text fontWeightImportant style={styles.listItemText}>
                  {title}
                </Text>
                <Text>
                  {subtitle ?
                    <Text fontWeightImportant style={styles.listItemText}>
                      {subtitle + ' '}
                    </Text>
                    : null}
                  <Text fontWeightImportant fontWeight={'bold'} style={styles.listItemText}>
                    {value}
                  </Text>
                </Text>
              </View>
            </View>
          );
        })
      }
    </View>;
  }
}

export default connect(
  state => {
    let zodiac, animal;
    const name = state.onboarding.onboardingProfile.name || state.profile.profileData.name;
    const date = state.onboarding.onboardingProfile.date || state.profile.profileData.date || '';
    if (state.profile.profileData.signs.length) {
      zodiac = _.find(state.profile.profileData.signs, { 'type': ZODIAC.toLowerCase() })?.sign?.toUpperCase();
      animal = _.find(state.profile.profileData.signs, { 'type': CHINESE.toLowerCase() })?.sign?.toUpperCase();
    } else {
      const [year, month, day] = date.split('-').map(Number);
      const { allSigns } = state.horoscope;
      animal = allSigns?.chinese?.find(el => el.date.includes(year))?.name.toUpperCase();
      zodiac = allSigns?.zodiac?.find(el => {
        const { start, end } = el?.date?.[0];
        const [startDay, startMonth] = start.split(':').map(Number);
        const [endDay, endMonth] = end.split(':').map(Number);

        if (month === endMonth) {
          return endDay >= day;
        } else if (month === startMonth) {
          return startDay <= day;
        }

        return false;
      })?.name.toUpperCase();
    }

    return {
      zodiac,
      animal,
      number: getLuckNumber(date, name),
    };
  },
)(FeaturesWithIcons);

const styles = StyleSheet.create({
  list: {
    flex: 1,
    justifyContent: 'center',
    minHeight: 200,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: newVerticalScale(5),
  },
  listLastItemContainer: {
    marginBottom: 0,
  },
  listItemIcon: {
    width: newVerticalScale(60),
    height: newVerticalScale(60),
    overflow: 'visible',
    marginRight: newHorizontalScale(16),
    marginTop: newVerticalScale(10),
  },
  secondItemIcon: {
    marginTop: newVerticalScale(5),
    paddingRight: newHorizontalScale(5),
    height: newVerticalScale(50),
  },
  listItemText: {
    color: COLORS.WHITE,
    fontSize: newVerticalScale(25, null, 20),
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
});
