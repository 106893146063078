import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { View } from 'react-native-animatable';

import { navigate, reset } from '../../../store/navigation/actions';
import {
  addToOnboardingProfile,
  goToNextOnboardingStep,
  onboardingCompleted,
  setOnboardingProfile,
} from '../../../store/onboarding/actions';
import DateSelect from '../components/date-select';
import OnboardingScreenLayout from './screen-layout';
import * as IMAGES from '../../../constants/images';
import i18n from '../../../localization';
import { verticalScale } from '../../../utils/scale';
import PropTypes from "prop-types";
import Analytics from '../../../modules/analytics';
import { updateProfile } from "../../../store/profile/actions";

class OnboardingDate extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    remoteConfig: PropTypes.object,
    navigate: PropTypes.func,
    updateProfile: PropTypes.func,
    addToOnboardingProfile: PropTypes.func,
    setOnboardingProfile: PropTypes.func,
    profile: PropTypes.object.isRequired,
    isNotHand: PropTypes.bool,
    reset: PropTypes.func,
    onboardingCompleted: PropTypes.func,
    isOnboardingCompleted: PropTypes.bool,
  };

  state = {
    isValidDate: true,
  };

  componentDidMount() {
    Analytics.trackEvent('Onboarding_Birth', 'Open');
  }

  render() {
    return (
      <OnboardingScreenLayout
        bgImage={IMAGES.BG_ONBOARDING_DATE}
        sectionNumber={4}
        title={i18n.t('ONBOARDING.DATE.TITLE', { name: this.props.profile.name })}
        text={i18n.t('ONBOARDING.DATE.TEXT')}
        interactiveElement={this.renderDateSelect()}
        specialPadding
      />
    );
  }

  renderDateSelect = () => {
    return (
      <View style={styles.dateSelectWrapper}>
        <DateSelect
          isOnboarding
          mode={'date'}
          height={160}
          setValue={this.setDate}
          isValidDate={this.state.isValidDate}
          onPress={this.onButtonPress} />
      </View>
    );
  };

  setDate = (date) => {
    this.setState({
      date,
      isValidDate: date.isValid()
    });
  };

  onButtonPress = () => {
    const { updateProfile, addToOnboardingProfile, isOnboardingCompleted, profile, setOnboardingProfile } = this.props,
      result = { date: this.state.date.format('YYYY-MM-DD') };
    Analytics.trackEvent('Onboarding_Birth', 'Click');
    if (isOnboardingCompleted) {
      updateProfile({ ...result, ...profile }).then(() => {
        this.successCallback();
        setOnboardingProfile({});
      });
    } else {
      addToOnboardingProfile(result);
      this.successCallback();
    }
  };

  successCallback = () => {
    this.props.goToNextOnboardingStep();
  };
}

export default connect(
  state => ({
    profile: state.onboarding.onboardingProfile,
    remoteConfig: state.remoteConfig.remoteConfigParams,
    isLoading: state.profile.isLoading,
    isNotHand: !state.palmReading.data,
    isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
  }), {
  reset,
  navigate,
  updateProfile,
  addToOnboardingProfile,
  onboardingCompleted,
  goToNextOnboardingStep,
  setOnboardingProfile,
}
)(OnboardingDate);

const styles = StyleSheet.create({
  dateSelectWrapper: {
    marginBottom: verticalScale(35),
  }
});
