import * as ROUTES from './routes';

export const ONBOARDING_IDS = {
  PROFILE: 'PROFILE',
  HAND: 'HAND',
  SUB_FIRST: 'SUB_FIRST',
  SUB_SECOND: 'SUB_SECOND',
  SUB_EXTRA: 'SUB_EXTRA',
  SUB_THREE_PRODUCTS: 'SUB_THREE_PRODUCTS',
  PROFILE_PROCESSING: 'PROFILE_PROCESSING',
  SUB_PROFILE: 'SUB_PROFILE',
  SUB_LANDING: 'SUB_LANDING',
};

export const ONBOARDING_SCREENS = {
  [ONBOARDING_IDS.PROFILE]: ROUTES.ONBOARDING_NAME,
  [ONBOARDING_IDS.HAND]: ROUTES.ONBOARDING_TAKE_A_PHOTO,
  [ONBOARDING_IDS.SUB_FIRST]: ROUTES.ONBOARDING_SUBSCRIPTION_FIRST,
  [ONBOARDING_IDS.SUB_SECOND]: ROUTES.ONBOARDING_SUBSCRIPTION_SECOND,
  [ONBOARDING_IDS.SUB_EXTRA]: ROUTES.ONBOARDING_EXTRA_SUB,
  [ONBOARDING_IDS.SUB_THREE_PRODUCTS]: ROUTES.SUBSCRIPTION_THREE_PRODUCTS,
  [ONBOARDING_IDS.SUB_PROFILE]: ROUTES.ONBOARDING_PERSONAL_SUB,
  [ONBOARDING_IDS.PROFILE_PROCESSING]: ROUTES.ONBOARDING_LOADER,
  [ONBOARDING_IDS.SUB_LANDING]: ROUTES.ONBOARDING_SUB_LANDING,
};
