export const REQUIRED_KEYWORDS = [
  {description: "hand", score: 0.8},
  {description: "finger", score: 0.8},
  {description: "thumb", score: 0.6},
];

export const REQUIRED_KEYWORDS_FACE = [
  {description: "head", score: 0.8},
  {description: "nose", score: 0.8},
  {description: "forehead", score: 0.8},
  {description: "face", score: 0.8},
];

export const BAD_KEYWORDS = [{description: "nail", score: 0.9}];

export const [PALM, FACE] = ['PALM', 'FACE'];

export const SECTIONS =  {
  OVERVIEW: 'OVERVIEW',
  LIFE: 'LIFE',
  HEAD: 'HEAD',
  HEART: 'HEART',
  LOVE: 'LOVE',
  HEALTH: 'HEALTH',
  WEALTH: 'WEALTH',
};

export const SECTIONS_FACE = {
  OVERVIEW: "OVERVIEW",
  FOREHEAD: "FOREHEAD",
  EYEBROWS: "EYEBROWS",
  EYES: "EYES",
  NOSE: "NOSE",
  MOUTH: "MOUTH",
  JAW: "JAW"
};

export const CIRCLE_CHARTS_SECTIONS = {
  RESPONSIBILITY: 'RESPONSIBILITY',
  TRUST: 'TRUST',
  OPTIMISM: 'OPTIMISM',
  RESILIENCE: 'RESILIENCE',
};
