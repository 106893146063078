import React, { PureComponent } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { ifIphoneX } from 'react-native-iphone-x-helper/index';
import { connect } from 'react-redux';

import * as COLORS from '../../../constants/colors';
import * as ROUTES from '../../../constants/routes';
import { INSIDE, NONE, UNDER, OVER } from '../../../constants/button-price-place';

import { horizontalScale, newHorizontalScale, newTextScale, verticalScale } from '../../../utils/scale';
import { navigateAfterPurchase, navigate, } from '../../../store/navigation/actions';

import Text from '../../../components/text';
import PulseAnimationButtonWrapper from '../../../components/pulse-animation-button-wrapper';
import Touchable from '../../../components/touchable';

import Analytics from '../../../modules/analytics';
import i18n from '../../../localization';

import SubTerms from './sub-terms';
import SubButton from './sub-button';
import { AboveText } from './over-price-place';
import BadgeWithCounter from './badge-with-counter';


const showAuthButton = Platform.OS === 'web';


class SubBottomWrapper extends PureComponent {
  static propTypes = {
    productDetails: PropTypes.object.isRequired,
    btnTitleFontSize: PropTypes.number.isRequired,
    priceFontSize: PropTypes.number.isRequired,
    title: PropTypes.string,
    pricePlace: PropTypes.string,
    renewalText: PropTypes.string,
    products: PropTypes.array,
    navigateAfterPurchase: PropTypes.func,
    product: PropTypes.string,
    isExtraSubscription: PropTypes.bool,
    colorSubscriptionButtons: PropTypes.string,
    isRelative: PropTypes.bool,
    isTryLimited: PropTypes.bool,
    onTryLimitedPress: PropTypes.func,
    isAds: PropTypes.bool,
    pending: PropTypes.bool,
    trialBadge: PropTypes.bool,
  };

  static defaultProps = {
    pricePlace: NONE,
    trialBadge: false,
  };

  state = {
    subPending: false,
    isMount: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { title: titleState, productDetails: productDetailsState, isMount } = state;
    const { title, productDetails } = props;
    if (!isMount || titleState !== title || productDetailsState !== productDetails) {
      const letterSpacingTitle = newHorizontalScale(-0.64);
      const titleText = title || productDetails.title;
      return {
        title,
        titleText,
        productDetails,
        isMount: true,
        titleStyle: {
          fontSize: newTextScale(24, 18, titleText.length, letterSpacingTitle),
          letterSpacing: letterSpacingTitle,
        },
      };
    }
    return null;
  }

  handleBillingStart = () => {
    const { analyticsType, contentType } = this.props;

    this.setState({ subPending: true });

    Analytics.trackEvent(analyticsType, 'Click', { 'ContentType': contentType || '' });
  }

  handleBillingSuccess = () => {
    const { product, navigateAfterPurchase, analyticsType, contentType } = this.props;

    this.setState({ subPending: false });

    Analytics.trackEvent(analyticsType, 'Success', { 'ProductType': product, 'ContentType': contentType || '' });

    navigateAfterPurchase();
  }

  handleBillingFail = (err) => {
    const { analyticsType, contentType } = this.props;

    this.setState({ subPending: false });

    console.log(err);

    if (err && err.cancelPurchase) {
      Analytics.trackEvent(analyticsType, 'Cancel', { 'ContentType': contentType || '' });
    }
  }

  render() {
    const {
      product,
      productDetails,
      pricePlace,
      renewalText,
      products,
      isExtraSubscription,
      colorSubscriptionButtons,
      isRelative,
      isTryLimited,
      onTryLimitedPress,
      onAuthPress,
      isAds,
      pending,
      paywall,
      trialBadge,
      priceFontSize,
      btnTitleFontSize,
    } = this.props;

    let { titleText, titleStyle } = this.state;
    if (btnTitleFontSize) {
      titleStyle = {
        ...titleStyle,
        fontSize: btnTitleFontSize
      };
    }

    const isAboveText = pricePlace.includes(OVER);
    const isBadge = productDetails?.haveTrialPeriod && trialBadge;
    const isPulse = isBadge && Platform.OS !== 'web';

    const ButtonContainer = isPulse ? PulseAnimationButtonWrapper : View;
    const buttonContainerStyle = isPulse ? styles.pulseAnimationButtonWrapper : styles.buttonWrapper;

    return (
      <View style={isRelative ? styles.bottomSectionsRelative : styles.bottomSections}>
        {
          isAboveText ? <AboveText {...productDetails} /> : null
        }
        {
          !isAboveText && renewalText === OVER ?
            <Text fontWeightImportant style={styles.underButtonText}>
              {i18n.t('SUBSCRIPTION.AUTO_RENEWAL')}
            </Text>
            : null
        }
        <ButtonContainer style={buttonContainerStyle}>
          <SubButton
            product={product}
            fontWeightImportant
            title={titleText}
            gradient={colorSubscriptionButtons === 'classic' ? COLORS.GRADIENT_COLORS_BUTTON : COLORS.GRADIENT_COLORS_BUTTON_ALT}
            isSpinner={this.state.subPending || pending}
            subTitle={!pricePlace.includes(NONE) && pricePlace.includes(INSIDE) && productDetails.subTitle || undefined}
            subTitleStyle={priceFontSize ? { fontSize: priceFontSize } : null}
            isExtraSubscription={isExtraSubscription}
            titleStyle={titleStyle}
            vibration
            onBillingStart={this.handleBillingStart}
            onBillingSuccess={this.handleBillingSuccess}
            onBillingFail={this.handleBillingFail}
          />
          {isBadge ?
            <View style={styles.badgeWrapper}>
              <BadgeWithCounter />
            </View>
            : null}
        </ButtonContainer>
        {
          !pricePlace.includes(NONE) && pricePlace.includes(UNDER) && !isAds
            ? <Text style={styles.underButtonText}>
              {productDetails.subTitle}
            </Text>
            : null
        }
        {
          renewalText === UNDER ?
            <Text fontWeightImportant style={styles.underButtonText}>
              {i18n.t('SUBSCRIPTION.AUTO_RENEWAL')}
            </Text>
            : null
        }
        {
          isTryLimited && onTryLimitedPress && !paywall
            ? <Touchable onPress={onTryLimitedPress}>
              <Text fontWeightImportant style={styles.underButtonText}>
                {i18n.t('ONBOARDING.TRY_LIMITED_VERSION')}
              </Text>
            </Touchable>
            : null
        }
        {
          showAuthButton
            ? <Touchable onPress={onAuthPress}>
              <Text fontWeightImportant style={styles.underButtonText}>
                {i18n.t('ONBOARDING.ALREADY_HAVE_ACCOUNT')}
              </Text>
            </Touchable>
            : null
        }
        <SubTerms products={products} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  bottomSections: {
    width: '100%',
    position: 'absolute',
    marginBottom: 0,
    ...ifIphoneX({
      bottom: 30,
    }, {
      bottom: 0,
    }),
  },
  bottomSectionsRelative: {
    width: '100%',
    ...ifIphoneX({
      marginBottom: 30,
    }, {
      marginBottom: 0,
    }),
  },
  pulseAnimationButtonWrapper: {
    marginTop: verticalScale(10),
    paddingHorizontal: horizontalScale(25),
  },
  buttonWrapper: {
    marginTop: verticalScale(10) + 14,
    paddingHorizontal: horizontalScale(25),
  },
  underButtonText: {
    marginTop: verticalScale(7.5),
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(10),
  },
  aboveTextTitle: {
    marginTop: verticalScale(7.5),
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(17),
    lineHeight: horizontalScale(23),
  },
  aboveTextSubtitle: {
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(19),
    lineHeight: horizontalScale(14),
  },
  badgeWrapper: {
    minWidth: 100,
    top: -12,
    right: horizontalScale(5),
    position: 'absolute',
  },
});

export default connect(
  (state, ownProps) => {
    const {
      colorSubscriptionButtons, renewalText, trialBadge: isTrialBadge,
      subFontSizes: { btnTitle: btnTitleFontSize, priceText: priceFontSize }
    } = state.remoteConfig.remoteConfigParams;
    const { paywall, initSessionTrialsCounter = 0 } = state.app;

    const sessionNumber = Analytics.getSessionNumber();
    const trialBadge = typeof (ownProps.trialBadge) === 'boolean'
      ? ownProps.trialBadge
      : isTrialBadge && ((sessionNumber - initSessionTrialsCounter) < 3);

    return {
      colorSubscriptionButtons,
      renewalText,
      paywall,
      trialBadge,
      btnTitleFontSize,
      priceFontSize,
    };
  },
  dispatch => ({
    navigateAfterPurchase: () => dispatch(navigateAfterPurchase()),
    onAuthPress: () => dispatch(navigate(ROUTES.AUTH)),
  }),
)(SubBottomWrapper);
