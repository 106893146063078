import {
  donateShortcut as pkgDonateShortcut,
  presentShortcut,
} from 'react-native-siri-shortcut';
import { createAction } from 'redux-actions';

import { VOICE_COMMAND_ADDED, SHOW_SUGGESTION, HIDE_SUGGESTION, RECEIVE_NS_USER_ACTIVITY_TYPE } from './types';

import SiriShortcuts from '../../utils/siri-shortcuts';
import { fillInProfile, navigate } from '../navigation/actions';
import * as ROUTES from '../../constants/routes';
import * as NS_USER_ACTIVITY_TYPES from '../../constants/ns-user-activity-types';
import { ZODIAC } from '../../constants/horoscopes-names';

const voiceCommandAddedAction = createAction(VOICE_COMMAND_ADDED);
const showSuggestionAction = createAction(SHOW_SUGGESTION);
const hideSuggestionAction = createAction(HIDE_SUGGESTION);

export function addHoroscopeVoiceCommand() {
  return function (dispatch) {
    dispatch(addVoiceCommand(SiriShortcuts.getHoroscopeShortcut()));
  };
}

export function addVoiceCommand(shortcut) {
  return function (dispatch) {
    if (shortcut) {
      presentShortcut(shortcut, ({ status }) => {
        if (status !== 'cancelled') {
          dispatch(voiceCommandAddedAction());
        }
      });
    }
  };
}

export function showSuggestion() {
  return function (dispatch, getState) {
    const state = getState();

    const {
      suggestionVisible,
      voiceCommandAdded,
      seenSuggestionTimes,
    } = state.siriShortcuts;
    const { voiceCommandSuggestionMaxSeenTimes } = state.remoteConfig.remoteConfigParams;

    if (
      !SiriShortcuts.supported ||
      suggestionVisible ||
      voiceCommandAdded ||
      seenSuggestionTimes >= voiceCommandSuggestionMaxSeenTimes
    ) {
      return;
    }

    dispatch(showSuggestionAction());

    setTimeout(
      () => dispatch(hideSuggestionAction()),
      state.remoteConfig.remoteConfigParams.voiceCommandSuggestionDisplayTimeout,
    );
  };
}

export function donateHoroscopeShortcut() {
  return function (dispatch) {
    dispatch(donateShortcut(SiriShortcuts.getHoroscopeShortcut()));
  };
}

export function donateShortcut(shortcut) {
  return function () {
    if (!SiriShortcuts.supported) {
      supportWarning();
      return;
    }
    pkgDonateShortcut(shortcut);
  };
}

function supportWarning() {
  console.warn('Siri Shortcuts only supported on iOS >= 12');
}

export const receiveNsUserActivityTypeAction = createAction(RECEIVE_NS_USER_ACTIVITY_TYPE);

export function receiveNsUserActivityType(activityType) {
  return function (dispatch, getState) {
    dispatch(receiveNsUserActivityTypeAction(activityType));

    const { isLoaded } = getState().app;
    if (isLoaded) {
      dispatch(handleNsUserActivityType(activityType));
    }
  }
}

export function handleNsUserActivityType() {
  return function (dispatch, getState) {
    const state = getState(),
      { isFullProfile } = state.app;
    if (state.siriShortcuts.nsUserActivityType === NS_USER_ACTIVITY_TYPES.HOROSCOPE) {
      dispatch(navigate(ROUTES.MAIN));
      if (isFullProfile) {
        dispatch(navigate(ROUTES.HOROSCOPES));
        dispatch(navigate(ROUTES.HOROSCOPE, { horoscopeName: ZODIAC, narrationEnabled: true }));
      } else {
        dispatch(fillInProfile());
      }
    }
  };
}
