import {
  SET_APP_STATE,
  INIT_APP_STATE_FAIL,
  SET_IS_NOT_CONNECTED,
  SET_IS_LOADED,
  SET_IS_FULL_PROFILE,
  SET_PROGRESS_END_TIME_FACE,
  SET_PROGRESS_END_TIME_PALM,
  SET_LEFT_PALM_ANALYZING_FINISHED,
  SET_RIGHT_PALM_ANALYZING_FINISHED,
  SET_WAS_PALM_ANALYZING_FINISHED,
  SET_FACE_ANALYZING_FINISHED,
  SET_APP_STATUS,
  SET_WAS_ON_CONTENT_PAGES,
  SET_COUNT_OF_RATE_US_POPUP,
  SET_DEEP_SESSION_NUMBER,
  SET_PAYWALL,
  SET_VARIATOR_STATUS,
  SET_TRIALS_COUNTER,
  SET_TRIALS_COUNTER_INIT_SESSION,
  SET_SETUP_DATE,
} from './types';
import { LEFT } from '../../constants/periods';
import { CLOSED } from '../../constants/variator-statuses';

const initialState = {
  isNotConnected: false,
  isFullProfile: false,
  setupDate: 0,
  paywallSessionNumber: 0,
  paywall: false,
  isLoaded: false,
  cameraModalEndTimeFace: null,
  cameraModalEndTimePalm: null,
  analyzingActiveHand: LEFT,

  isLeftPalmAnalyzingFinished: false,
  isRightPalmAnalyzingFinished: false,
  isFaceAnalyzingFinished: false,
  isPalmAnalyzingFinished: false,
  appStatus: '',
  wasOnContentPages: false,
  countOfRateUsPopup: 0,
  variatorStatus: CLOSED,
  trialsCounter: 0,
  initSessionTrialsCounter: 0,
};

export const BLACK_LIST = ['isNotConnected', 'isLoaded', 'variatorStatus']; // don't fetch params after init

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_APP_STATE:
      return {
        ...state,
        ...action.payload.appState,
      };

    case SET_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case SET_WAS_ON_CONTENT_PAGES:
      return {
        ...state,
        wasOnContentPages: true,
      };

    case SET_IS_FULL_PROFILE:
      return {
        ...state,
        isFullProfile: true,
      };

    case INIT_APP_STATE_FAIL:
      return {
        ...state,
      };

    case SET_IS_NOT_CONNECTED:
      return {
        ...state,
        isNotConnected: action.payload,
      };

    case SET_DEEP_SESSION_NUMBER:
      return {
        ...state,
        paywallSessionNumber: action.payload,
      };

    case SET_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };

    case SET_PROGRESS_END_TIME_FACE:
      return {
        ...state,
        cameraModalEndTimeFace: action.payload.time,
        isFaceAnalyzingFinished: !!state.cameraModalEndTimeFace,
      };

    case SET_PROGRESS_END_TIME_PALM:
      return {
        ...state,
        cameraModalEndTimePalm: action.payload.time,
        analyzingActiveHand: action.payload.hand,
      };

    case SET_LEFT_PALM_ANALYZING_FINISHED:
      return {
        ...state,
        isLeftPalmAnalyzingFinished: true,
      };

    case SET_RIGHT_PALM_ANALYZING_FINISHED:
      return {
        ...state,
        isRightPalmAnalyzingFinished: true,
      };

    case SET_FACE_ANALYZING_FINISHED:
      return {
        ...state,
        isFaceAnalyzingFinished: true,
      };

    case SET_WAS_PALM_ANALYZING_FINISHED:
      return {
        ...state,
        isPalmAnalyzingFinished: true,
      };

    case SET_COUNT_OF_RATE_US_POPUP:
      return {
        ...state,
        countOfRateUsPopup: action.payload,
      };

    case SET_PAYWALL:
      return {
        ...state,
        paywall: action.payload,
      };

    case SET_VARIATOR_STATUS:
      return {
        ...state,
        variatorStatus: action.payload,
      };

    case SET_SETUP_DATE:
      return {
        ...state,
        setupDate: action.payload,
      };

    case SET_TRIALS_COUNTER_INIT_SESSION:
      return {
        ...state,
        initSessionTrialsCounter: action.payload,
      };

    case SET_TRIALS_COUNTER:
      return {
        ...state,
        trialsCounter: action.trialsCounter,
      };

    default:
      return state;
  }
}
