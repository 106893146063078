import { SET_DATA, SET_PENDING, SET_PURCHASED, SET_PURCHASE_DATE } from './types';

const initialState = {
  data: null, // ?Array
  pending: false, // Boolean
  purchased: false, // Boolean
  purchaseDate: 0, //Number
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      state = {
        ...state,
        data: action.payload,
      };
      break;

    case SET_PURCHASE_DATE:
      state = {
        ...state,
        purchaseDate: action.payload,
      };
      break;

    case SET_PENDING:
      state = {
        ...state,
        pending: action.payload,
      };
      break;

    case SET_PURCHASED:
      state = {
        ...state,
        purchased: action.payload,
      };
      break;
  }

  return state;
}
