import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { reduxifyNavigator } from 'react-navigation-redux-helpers';

import * as ROUTES from '../constants/routes';

import { showInterAfterNav } from '../store/ads/actions';
import { onMountApp, onWillUnmountApp, setWasOnContentPages } from '../store/app/actions';

import PureNavigator from './navigator';
import NoInternetModal from '../reusable/no-internet-modal';
import NotAvaialableModal from '../reusable/not-available-modal';
import RateUsModal from '../reusable/rate-us-modal';
import ChangeIconPremiumModal from '../reusable/change-icon-premium-modal';
import VariatorView from '../reusable/variator-view';

const trigger = [
  ROUTES.PALM_READING,
  ROUTES.PHYSIOGNOMY,
  ROUTES.MAGIC_BALL,
  ROUTES.COMPATIBILITY_RESULT,
  ROUTES.COMPATIBILITY,
  ROUTES.BIORITHM,
  ROUTES.TAROT,
  ROUTES.HOROSCOPE,
];

const activeTrigger = [
  ROUTES.PALM_READING,
  ROUTES.PHYSIOGNOMY,
  ROUTES.MAGIC_BALL,
  ROUTES.COMPATIBILITY_RESULT,
  ROUTES.COMPATIBILITY,
  ROUTES.BIORITHM,
  ROUTES.TAROT,
  ROUTES.HOROSCOPE,
  ROUTES.HOROSCOPES,
];

const noTrigger = [
  ROUTES.ONBOARDING_SUBSCRIPTION_SECOND,
  ROUTES.ONBOARDING_EXTRA_SUB,
  ROUTES.PALM_PHOTO,
  ROUTES.SETTINGS,
  ROUTES.WATCH_ADD_SCREEN,
  ROUTES.ONBOARDING_SUBSCRIPTION_FIRST,
];

let AppNavigator;

class App extends PureComponent {
  static propTypes = {
    navigation: PropTypes.shape({
      index: PropTypes.number.isRequired,
    }),
    dispatch: PropTypes.func.isRequired,
    onMountApp: PropTypes.func.isRequired,
    onWillUnmountApp: PropTypes.func.isRequired,
    isNotConnected: PropTypes.bool,
    setWasOnContentPages: PropTypes.func.isRequired,
    wasOnContentPages: PropTypes.bool,
    showInterAfterNav: PropTypes.func,
    onVariatorClose: PropTypes.func,
    onVariatorOpen: PropTypes.func,
  };

  constructor(props) {
    super(props);
    AppNavigator = reduxifyNavigator(PureNavigator, 'root');
  }

  componentDidMount() {
    this.props.onMountApp();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      navigation,
      showInterAfterNav,
      wasOnContentPages,
      setWasOnContentPages,
      isOnboardingCompleted,
    } = this.props;

    if (navigation.activeRouteName !== prevProps.navigation.activeRouteName) {
      if (!wasOnContentPages && activeTrigger.includes(prevProps.navigation.activeRouteName)) {
        setWasOnContentPages();
      }
      if (
        isOnboardingCompleted &&
        (
          trigger.includes(prevProps.navigation.activeRouteName)
          || trigger.includes(navigation.activeRouteName)
        ) &&
        !(
          noTrigger.includes(prevProps.navigation.activeRouteName)
          || noTrigger.includes(navigation.activeRouteName)
        )) {
        showInterAfterNav(() => { });
      }
    }
  }

  componentWillUnmount() {
    this.props.onWillUnmountApp();
  }

  render() {
    const { navigation, dispatch, isNotConnected } = this.props;

    return (
      <View style={styles.container}>
        <AppNavigator state={navigation} dispatch={dispatch} />
        {isNotConnected ? <NoInternetModal /> : null}
        <RateUsModal />
        <ChangeIconPremiumModal />
        <VariatorView />
        <NotAvaialableModal />
      </View>
    );
  }
}

export default connect(
  state => ({
    navigation: state.navigation,
    isNotConnected: state.app.isNotConnected,
    wasOnContentPages: state.app.wasOnContentPages,
    isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
  }),
  dispatch => ({
    dispatch,
    onWillUnmountApp: () => dispatch(onWillUnmountApp()),
    onMountApp: () => dispatch(onMountApp()),
    setWasOnContentPages: () => dispatch(setWasOnContentPages()),
    showInterAfterNav: (cb) => dispatch(showInterAfterNav(cb)),
  }),
)(App);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
