import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, View, Image} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import Button from "../../../components/button";
import * as COLORS from "../../../constants/colors";
import { newHorizontalScale, newTextScale, newVerticalScale, textSubscription } from '../../../utils/scale';
import * as ICONS from '../../../constants/icons';
import i18n from '../../../localization';
import Text from '../../../components/text';

const gradientStart = {x: 0, y: 0};
const gradientEnd = {x: 1, y: 0};
const gradient = [COLORS.PURPLE_PINK, COLORS.SALMON];

export default class CheckButton extends PureComponent {

  static propTypes = {
    onPress: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isActive: PropTypes.bool,
    colorSubscriptionButtons: PropTypes.string,
    id: PropTypes.string,
    isMostPopular: PropTypes.bool,
    isSubtitle: PropTypes.bool,
  };

  state = {
    isMount: false,
  };

  handlePress = () => {
    const {onPress, id} = this.props;
    onPress(id);
  };

  static getDerivedStateFromProps(props, state) {
    const {
      title: titleState,
      isActive: isActiveState,
      isSubtitle: isSubtitleState,
      subTitle: subTitleState,
      isMount} = state;
    const {title, isActive, isSubtitle, subTitle,} = props;
    if (
      !isMount ||
      titleState !== title ||
      isActive !== isActiveState ||
      isSubtitle !== isSubtitleState ||
      subTitleState !== subTitle
    ) {
      const letterSpacingTitle = newHorizontalScale(-0.64);
      const titleFontSize = newTextScale(20, 14, title?.length || 0, letterSpacingTitle);
      const subtitleFontSize = newTextScale(20, 14, isSubtitle && subTitle?.length || 0, letterSpacingTitle);
      const fontSize = Math.min(titleFontSize, subtitleFontSize);

      return {
        title,
        isActive,
        isMount: true,
        titleStyle: [
          isActive
            ? null
            : [styles.textStyle,
          {
            fontSize: fontSize,
            letterSpacing: letterSpacingTitle,
          }]
        ],
        subTitleStyle: [
          isActive
            ? null
            : [styles.subTitleStyle,
              {
                fontSize: fontSize - 4,
                letterSpacing: letterSpacingTitle,
              }]
        ],
      };
    }
    return null;
  }

  render() {
    const {title, isActive, subTitle, colorSubscriptionButtons, isMostPopular, isSubtitle} = this.props;
    const {titleStyle, subTitleStyle} = this.state;
    return <View style={[styles.container, isMostPopular && styles.popularContainer]}>
      <Button
        fontWeightImportant={isActive}
        vibration
        isTransparent
        fontFamily={'SFProDisplay'}
        onPress={this.handlePress}
        title={title}
        subTitle={isSubtitle ? subTitle : undefined}
        fontWeight={isActive ? 'bold': 'semibold'}
        style={[styles.button, isActive ? styles.active : styles.passive, isActive && {
          borderColor: colorSubscriptionButtons === 'green' ? COLORS.AQUA_MARINE : COLORS.CARNATION_PINK
        }]}
        subTitleStyle={subTitleStyle}
        titleStyle={titleStyle}
      />
      {isMostPopular
        ? <LinearGradient
          start={gradientStart}
          end={gradientEnd}
          colors={gradient}
          style={styles.gradientBadge}
        >
        <Image
          source={ICONS.FIRE}
          resizeMode={'contain'}
        />
        <Text fontWeightImportant fontWeight={'semibold'} style={styles.badgeText}>
          {' '+i18n.t('ONBOARDING.SUBSCRIPTION_THREE.MOST_POPULAR')}
        </Text>
        </LinearGradient>
        : null}
    </View>;
  }
}

const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
    },
    button: {
      borderRadius: 15,
      borderWidth: 1.5,
      borderColor: COLORS.PALE_VIOLET_TWO,
      padding: 9,
      color: COLORS.PALE_VIOLET_TWO,
    },
    active: {
      padding: 14,
      backgroundColor: COLORS.GRAPE_PURPLE,
      color: COLORS.WHITE,
    },
    passive: {
      padding: textSubscription(10),
    },
    textStyle: {
      lineHeight: newVerticalScale(21),
      color: COLORS.PALE_VIOLET_TWO,
    },
    textNoActiveStyle: {
      fontSize: newTextScale(16, 14,)
    },
    gradientBadge: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 17,
      borderRadius: 10.5,
      position: 'absolute',
      height: newVerticalScale(21),
      top: newVerticalScale(21/2) * -1,
    },
    badgeText: {
      color: COLORS.WHITE,
      fontSize: textSubscription(14),
    },
    popularContainer: {
      marginTop: newVerticalScale(21/2),
    },
    subTitleStyle: {
      color: COLORS.PALE_VIOLET_TWO,
    },
  }
);
