import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

import Text from '../../../components/text';
import i18n from '../../../localization';
import { horizontalScale, verticalScale } from '../../../utils/scale';
import * as COLORS from '../../../constants/colors';


const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  title: {
    marginTop: verticalScale(7.5),
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(17),
    lineHeight: horizontalScale(23),
  },
  subtitle: {
    color: COLORS.UNDER_BUTTON_TEXT,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: horizontalScale(14),
    lineHeight: horizontalScale(19),
  },
});


export class AboveText extends PureComponent {
  static propTypes = {
    haveTrialPeriod: PropTypes.bool.isRequired,
    trial: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    priceText: PropTypes.string.isRequired,
  };

  static defaultProps = {
    haveTrialPeriod: false,
    trial: 0,
    code: '',
    priceText: '',
  };

  render() {
    const { haveTrialPeriod, trial, code, priceText } = this.props;

    if (!code || !priceText) {
      return null;
    }

    const trialStatus = haveTrialPeriod ? 'WITH_TRIAL' : 'WITHOUT_TRAIL';
    const params = {
      trial,
      priceText,
      period: i18n.t(`SUBSCRIPTION.PERIODS.${code}`),
    };

    return <View style={styles.root}>
      <Text fontWeightImportant fontWeight={'semibold'} style={styles.title}>{i18n.t(`SUBSCRIPTION.ABOVE_TEXTS.${trialStatus}.TITLE`, params)}</Text>
      <Text fontWeightImportant style={styles.subtitle}>{i18n.t(`SUBSCRIPTION.ABOVE_TEXTS.${trialStatus}.SUBTITLE`, params)}</Text>
    </View>;
  }
}
