import { isSupported } from '@hawkrives/react-native-alternate-icons';
import { createAction } from 'redux-actions';

import * as TYPES from './types';

const setSupportedStatus = createAction(TYPES.SET_SUPPORTED_STATUS);
export const changedIcon = createAction(TYPES.CHANGED_ICON);

export function initCustomAppIcons() {
  return function(dispatch) {
    return isSupported().then(() => {
      dispatch(setSupportedStatus(true));
    }).catch(() => {
      dispatch(setSupportedStatus(false));
    });
  };
}
