import {SET_DATA, SET_OPENED_TABS,} from "./types";
import i18n from "../../localization";

const initialState = {
  data: null,
  openedTabs: [],
  lang: i18n.locale,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload || null,
        lang: i18n.locale,
      };

    case SET_OPENED_TABS:
      return {
        ...state,
        openedTabs: action.payload,
      };

    default:
      return state;
  }
};
