import { Platform } from 'react-native';

export const [REWARDED, INTER] = ["rewarded", "inter"];

export const ADMOB_APP_ID = Platform.select({
  ios: "ca-app-pub-5460328494919894~7118836069",
  android: "ca-app-pub-5460328494919894~1962804198",
});

export const ADMOB_REWARDED_AD_UNIT = Platform.select({
  ios: "ca-app-pub-5460328494919894/1702857859",
  android: "ca-app-pub-5460328494919894/3439537394",
});

export const ADMOB_INTERSTITIAL_AD_UNIT = Platform.select({
  ios: "ca-app-pub-5460328494919894/9772919566",
  android: "ca-app-pub-5460328494919894/7570354095",
});

export const TEST_IDS = [
  `cd95d629-3b60-4181-aa0a-10ca1ad1abe1`,
  `41456012-159f-45a2-9ad1-b90e5154c59d`,
  `c0ec46e1-2178-4e67-b9f3-4fa35b893ce7`,
  `0b364ae0-da12-4cb6-8e30-8ee15b69ade5`,
  `f3c6c5cc-c27e-4933-b0ad-010f8bff606c`,
  `753d28d9-baa9-4caf-b888-21dc88b5c927`,
  `c0585f5a-f1ac-4eb3-b6cd-a828bfe8cc42`,
  `0b364ae0-da12-4cb6-8e30-8ee15b69ade5`,
  `828e9fbe-71c7-449d-a73d-3706beda5566`,
  `00951260-d7c0-48d6-9ede-daa65ad0ff54`,
  `46e15040-c2a8-47a5-9965-fbea1b297221`,
];
