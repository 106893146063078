import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/auth';
import queryString from 'query-string';

import { updateProfile, } from '../../store/profile/actions';
import { onboardingCompleted, } from '../../store/onboarding/actions';

import Analytics from '../../modules/analytics';

const PATH_LINK_EMAIL = '/link-email';
const PATH_VERIFY_EMAIL = '/verify-email';

let store;
let promise;

export default {

  putStore(s) {
    store = s;
  },

  init() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      return new Promise(() => {
        const { email, oobCode, } = queryString.parse(window.location.search);
        const linking = window.location.pathname === PATH_LINK_EMAIL;

        try {
          return Promise.resolve()
            .then(() => firebase.auth().signOut())
            .then(() => firebase.auth().checkActionCode(oobCode))
            .then(() => firebase.auth().signInWithEmailAndPassword(email, email))
            .then(() => store.dispatch(updateProfile({ email, })))
            .then(() => store.dispatch(onboardingCompleted()))
            .then(() => {
              Analytics.trackEvent('Auth_Email', linking ? 'LinkingSuccess' : 'VerifySuccess');
            })
            .catch((err) => {
              console.warn(err);
              Analytics.trackEvent('Auth_Email', linking ? 'LinkingError' : 'VerifyError', { ..._.pick(err, 'message', 'code') });
            })
            .then(() => {
              setTimeout(() => {
                window.location.replace(window.location.origin);
              }, 100);
            });
        }
        catch (ex) {
          console.warn(ex);
          Analytics.trackEvent('Auth_Email', linking ? 'LinkingError' : 'VerifyError', { ..._.pick(ex, 'message', 'code') });
          setTimeout(() => {
            window.location.replace(window.location.origin);
          }, 100);
        }
      });
    }
    else {
      return Promise.resolve();
    }
  },

  getCreds() {
    return promiseUser()
      .then(user => user ? { user } : firebase.auth().signInAnonymously())
      .then(creds => ({ firebase_uid: creds.user.uid }));
  },

  sendSignInLinkToEmail(email, linking) {
    return (
      linking
        ? firebase.auth().currentUser.linkWithCredential(new firebase.auth.EmailAuthProvider.credential(email, email))
        : Promise.resolve()
    )
      .then(() =>
        firebase.auth().sendSignInLinkToEmail(
          email,
          {
            url: window.location.origin
              + (linking ? PATH_LINK_EMAIL : PATH_VERIFY_EMAIL)
              + '?' + queryString.stringify({ email, }),
            handleCodeInApp: true,
          }
        )
      );
  },

  fetchSignInMethodsForEmail(email) {
    return firebase.auth()
      .fetchSignInMethodsForEmail(email);
  },

};


function promiseUser() {
  return promise || (promise = new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(function (user) {
      console.log('user', user?.uid);
      resolve(user);
    });
  }));
}