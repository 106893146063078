import {
  SET_HOROSCOPE_INFO,
  SET_HOROSCOPE,
  SET_ERROR,
  SET_ALL_SIGNS,
  SET_PENDING,
  SET_ZODIAC_ANIMATION_COUNT,
} from "./types";

const initialState = {
  horoscopes: [],
  allSigns: {},
  facts: {},
  isLoading: false,
  isError: false,
  horoscopeInfo: {},
  zodiacAnimationCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_HOROSCOPE:
      state = {
        ...state,
        horoscopes: action.payload.horoscope,
        facts: action.payload.facts || {},
        isLoading: false,
        isError: false,
      };
      break;
    case SET_PENDING: {
      state = {
        ...state,
        isLoading: true,
        isError: false,
      };
      break;
    }
    case SET_HOROSCOPE_INFO: {
      state = {
        ...state,
        horoscopeInfo: {
          ...action.payload,
        },
      };
      break;
    }
    case SET_ERROR:
      state = {
        ...state,
        horoscopes: [],
        isLoading: false,
        isError: true,
      };
      break;
    case SET_ALL_SIGNS:
      state = {
        ...state,
        allSigns: action.payload,
      };
      break;
    case SET_ZODIAC_ANIMATION_COUNT:
      state = {
        ...state,
        zodiacAnimationCount: action.payload,
      };
      break;
  }

  return state;
}
