import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Image } from 'react-native';
import i18n from "../../../../localization";
import AnimationWrapForButton from "../../../../components/animation-wrap-for-button";
import * as IMAGES from "../../../../constants/images";
import Touchable from "../../../../components/touchable";
import Text from "../../../../components/text";
import * as COLORS from "../../../../constants/colors";
import { createAnimatableComponent } from "react-native-animatable";
import ButtonSpinner from "../../../../components/button-spinner";

const AnimatedView = createAnimatableComponent(View);

export default class GenderButton extends PureComponent {

  static propTypes = {
    isLoading: PropTypes.bool,
    onPress: PropTypes.func.isRequired,
    gender: PropTypes.string.isRequired,
  };

  handlePress = () => {

    this.props.onPress(this.props.gender);
  };

  render() {
    const { gender, isLoading, isUnselect } = this.props;
    return (
      <Touchable
        onPress={isLoading ? undefined : this.handlePress}
        style={styles.wrapper}
        vibration
      >
        <AnimationWrapForButton>
          <Image
            source={
              isLoading
                ? IMAGES[`GENDER_${gender}_DISABLED`]
                : isUnselect
                  ? IMAGES[`GENDER_${gender}_UNSELECT`]
                  : IMAGES[`GENDER_${gender}_ACTIVE`]
            }
            style={{ height: 110, width: 110, }}
          />
          {
            isLoading
              ? <ButtonSpinner />
              : null
          }
        </AnimationWrapForButton>
        <AnimatedView
          animation={'fadeInUpBig'}
          duration={1200}
          easing={'ease-out'}
        >
          <Text
            fontWeight={'semibold'}
            style={styles.text}
          >
            {i18n.t(`GENDER.${gender}`)}
          </Text>
        </AnimatedView>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 18,
    color: COLORS.WHITE,
  }
});
