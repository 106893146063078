export default {

  addListener(eventName, listener) {

    if (eventName === 'keyboardDidShow' || eventName === 'keyboardWillShow') {
      eventName = 'focus';
    }
    else if (eventName === 'keyboardDidHide' || eventName === 'keyboardWillHide') {
      eventName = 'blur';
    }
    else {
      return {
        remove() { },
      };
    }

    let inputs = document.querySelectorAll('input');

    for (let i of inputs) {
      i.addEventListener(eventName, listener);
    }

    if (eventName === 'focus' && document.querySelector('input:focus')) {
      listener();
    }

    return {
      remove() {
        for (let i of inputs) {
          i.removeEventListener(eventName, listener);
        }
      },
    };
  }

}
