import {SET_DATA, SET_LOADING} from "./types";
import i18n from "../../localization";

const initialState = {
  data: null,
  loading: false,
  lang: i18n.locale,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload || null,
        lang: i18n.locale,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
