export default {

  init() {
  },

  onOpenNotificationHandler() {
  },

  clearNotifications() {
  },

  requestPermissions() {
  },

  schedulePlannedNotificationInternal() {
  },

  scheduleRepeatedNotificationInternal() {
  },

  scheduleDailyNotification() {
  },

  unscheduleNotification() {
  },

  removeAllDeliveredNotifications() {
  },

  stringToDate() {
  },

  scheduleEveryEveningNotification() {
  },

  scheduleEveryMorningNotification() {
  },

  scheduleRollUpProfileNotification() {
  },

  scheduleRollUpOnboardingSubscriptionNotification() {
  },

  scheduleRollUpDashboardNotification() {
  },

  scheduleFinishedAnalizedFaceNotification() {
  },

  scheduleFinishedAnalizedHandNotification() {
  },

  scheduleFirstSessionFirstNotification() {
  },

  scheduleFirstSessionSecondNotification() {
  },

  scheduleFirstSessionThirdNotification() {
  },

  unscheduleAllNotifications() {
  },

  scheduleFirstSessionNotifications() {
  },

};
