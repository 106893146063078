import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as ROUTES from '../../../constants/routes';
import { navigate, replace, reset } from '../../../store/navigation/actions';
import { goToNextOnboardingStep } from '../../../store/onboarding/actions';
import SubscriptionFirst from '../../subcsription/subscription-first';
import SubscriptionThird from '../../subcsription/subscription-third';
import { showInterAfterNav } from '../../../store/ads/actions';

class OnboardingSubscriptionFirst extends PureComponent {
  static navigationOptions = {
    header: null,
  };

  static propTypes = {
    navigate: PropTypes.func,
    reset: PropTypes.func,
    goToNextOnboardingStep: PropTypes.func,
    showInterAfterNav: PropTypes.func,
    isOnboardingCompleted: PropTypes.bool,
    replace: PropTypes.func,
    paywall: PropTypes.bool.isRequired,
    purchased: PropTypes.bool.isRequired,
    isSubscriptionThreeDefault: PropTypes.bool.isRequired,
    isExtraSubscription: PropTypes.bool.isRequired,
    analyticsType: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.props.purchased) {
      this.props.goToNextOnboardingStep();
    }
  }

  render() {
    const { isOnboardingCompleted, paywall, isSubscriptionThreeDefault, analyticsType } = this.props;
    let Component;

    if (isOnboardingCompleted) {
      if (paywall) {
        Component = SubscriptionFirst;
      } else {
        Component = isSubscriptionThreeDefault ? SubscriptionThird : SubscriptionFirst;
      }
    } else {
      Component = SubscriptionFirst;
    }

    return <Component analyticsType={analyticsType} onClosePress={this.onClosePress} />;
  }

  onClosePress = () => {
    const {
      navigation,
      navigate,
      reset,
      isOnboardingCompleted,
      goToNextOnboardingStep,
      showInterAfterNav,
      isExtraSubscription,
      replace,
    } = this.props,
      backScreen = navigation.getParam(
        'backScreen',
        null,
      ),
      isNavigate = navigation.getParam(
        'isNavigate',
        false,
      ),
      onClosePress = navigation.getParam(
        'onClosePress',
        false,
      ),
      paramsToBackScreen = navigation.getParam(
        'paramsToBackScreen',
        undefined,
      );

    if (onClosePress) {
      onClosePress();
    } else if (isOnboardingCompleted && isExtraSubscription) {
      replace(ROUTES.ONBOARDING_EXTRA_SUB, { backScreen });
    } else if (backScreen) {
      if (isNavigate) {
        showInterAfterNav(() => {
          navigate(backScreen, paramsToBackScreen);
        });
      } else {
        reset(backScreen);
      }
    } else {
      goToNextOnboardingStep();
    }
  };
}

export default connect(
  state => {
    const { paywall } = state.app;
    const { purchased } = state.purchases;
    const { isOnboardingCompleted } = state.onboarding;
    const {
      isSubscriptionThreeDefault,
      isExtraSubscription,
    } = state.remoteConfig.remoteConfigParams;
    let analyticsType;

    if (isOnboardingCompleted) {
      if (paywall) {
        analyticsType = 'paywall_horo';
      } else {
        analyticsType = 'App_Subscription';
      }
    } else {
      analyticsType = 'Onboarding_SubscriptionHoro';
    }

    return {
      isSubscriptionThreeDefault,
      isExtraSubscription,
      paywall,
      purchased,
      analyticsType,
      isOnboardingCompleted,
    }
  }, {
  navigate,
  reset,
  replace,
  showInterAfterNav,
  goToNextOnboardingStep,
},
)(OnboardingSubscriptionFirst);
