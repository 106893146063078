import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Dimensions } from 'react-native';
import { FEMALE, MALE } from '../../../../constants/profile';
import * as COLORS from '../../../../constants/colors';
import GenderButton from './gender-button';

const { width } = Dimensions.get('window');

const genders = [FEMALE, MALE];

const widthImage = 100,
  widthWithoutImage = width - (widthImage * 2);

export default class Index extends PureComponent {

  static propTypes = {
    isLoading: PropTypes.bool,
    onPress: PropTypes.func,
  };

  render() {
    const { onPress, isLoading, selectGender } = this.props;
    return (
      <View style={[
        styles.container,
        { paddingHorizontal: widthWithoutImage / 5 }
      ]}>
        {
          genders.map(el =>
            <GenderButton
              isLoading={isLoading}
              key={el}
              onPress={onPress}
              gender={el}
              isUnselect={selectGender && el !== selectGender}
            />
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: 20,
  },
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 18,
    color: COLORS.WHITE,
  }
});
