import {
  SET_PROFILE,
  INIT_PROFILE_FAIL,
  SET_PENDING,
  SET_CUSTOMER,
} from './types';

const initialState = {
  isProfile: false,
  profileData: {
    name: '',
    date: '',
    time: '',
    gender: '',
    relationship: null,
    error: null,
    palmImage: null,
    signs: [],
    id: -1,//todo change to null
  },
  customer: null,
  isLoading: false,
  isError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        isProfile: true,
        isLoading: false,
        isError: false,
        profileData: {
          ...action.payload
        },
      };

    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
      };

    case SET_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case INIT_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
